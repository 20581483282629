import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getPosts } from './api';

export function* loadPostsWorker({ payload, meta }) {
    try {
        const posts = yield call(getPosts, payload);
        yield put(actions.loadPostsSuccess({ ...posts.data, meta }));
    } catch (e) {
        yield put(actions.loadPostsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadPostsRequest, loadPostsWorker);
}
