import { METRIC } from '@constants/index';
import metricUnits from '@utils/units/metricUnits';

const UNITS = { ...metricUnits };
export const DISTANCE = 'distance';
export const DURATION = 'duration';
export const MIN_THE_FASTEST = 'MIN_THE_FASTEST';
const METERS_IN_KM = 1000;

const convertUnits = (val, to, from, system) => {
    if (!system || system === METRIC || to === from) return val;
    return UNITS[from][to](val);
};

const prepareUserDistance = value => {
    const preparedValue = value.typeof === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
    return preparedValue || 0;
};

export const getDistanceUnits = value => {
    const userDistance = prepareUserDistance(value);
    const showMeters = userDistance < METERS_IN_KM;
    return showMeters ? 'workoutDistance_meter' : 'workoutDistance';
};

export const getDistanceValue = value => {
    const userDistance = prepareUserDistance(value);
    const showMeters = userDistance < METERS_IN_KM;
    return showMeters ? userDistance : userDistance / METERS_IN_KM;
};

export const unitsOptions = {
    speed: {
        label: 'AVERAGE_SPEED',
        metric: 'unit_short_name_kilometers_per_hour',
        imperial: 'unit_short_name_miles_per_hour',
        metricUnit: 'km/h',
        imperialUnit: 'mph',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system).toFixed(1);
        },
    },
    power: {
        label: 'AVERAGE_POWER',
        metric: 'unit_short_name_power',
        value: val => val.toFixed(1),
    },
    distance: {
        label: 'dashboard_distance_title',
        metric: 'unit_short_name_kilometer',
        imperial: 'unit_short_name_mile',
        metricUnit: 'km',
        imperialUnit: 'mi',
        value(val, key, system) {
            return convertUnits(parseInt(val, 10), this.imperialUnit, this.metricUnit, system).toFixed(1);
        },
    },
    ascent: {
        label: 'dashboard_ascent_title',
        metric: 'unit_short_name_meter',
        imperial: 'unit_short_name_foot',
        metricUnit: 'm',
        imperialUnit: 'ft',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system).toFixed(0);
        },
    },
    descent: {
        label: 'DESCENT',
        metric: 'unit_short_name_meter',
        imperial: 'unit_short_name_foot',
        metricUnit: 'm',
        imperialUnit: 'ft',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system).toFixed(0);
        },
    },
    energy: {
        label: 'energy_value_met_hour_name',
        unit: 'energy_value_met_hour_name',
        metric: 'energy_value_met_hour_name',
        imperial: 'energy_value_met_hour_name',
        value: val => val.toFixed(1),
    },
    normenergy: {
        label: 'energy_value_met_hour_name',
        unit: 'energy_value_met_hour_name',
        metric: 'energy_value_met_hour_name',
        imperial: 'energy_value_met_hour_name',
        value: val => val.toFixed(1),
    },
    heartRate: {
        helpKey: 'samples',
        label: 'AVERAGE_HEARTRATE',
        metric: 'unit_short_name_heart_rate',
        imperial: 'unit_short_name_heart_rate',
        value: val => val.toFixed(),
    },
    acquisition: {
        label: 'TRACKING_SOURCE',
        value: val => val,
    },
    weight: {
        metric: 'unit_short_name_kilogram',
        imperial: 'unit_short_name_pound',
        metricUnit: 'kg',
        imperialUnit: 'lb',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system);
        },
    },
    workoutDistance: {
        metric: 'unit_short_name_kilometer',
        imperial: 'unit_short_name_mile',
        metricUnit: 'km',
        imperialUnit: 'mi',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system);
        },
    },
    workoutDistance_meter: {
        metric: 'unit_short_name_meter',
        imperial: 'unit_short_name_foot',
        metricUnit: 'm',
        imperialUnit: 'ft',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system);
        },
    },
    steps: {
        metric: 'dashboard_steps_title',
        imperial: 'dashboard_steps_title',
        metricUnit: 'steps',
        imperialUnit: 'steps',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system);
        },
    },
    duration: {
        label: 'DURATION',
        metric: 'unit_short_name_hour',
        imperial: 'unit_short_name_hour',
        metricUnit: 'unit_short_name_hour',
        imperialUnit: 'unit_short_name_hour',
        value(val, key, system) {
            return convertUnits(val, this.imperialUnit, this.metricUnit, system);
        },
    },
};
