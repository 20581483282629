import styled from 'styled-components';
import { Icon, IconButton, Typography } from '@components/ui';

export const CloseButton = styled(IconButton)`
    width: auto;
    height: auto;
    position: absolute;
    right: 10%;
    top: 15px;
    background: initial;
`;

export const CloseIcon = styled(Icon)(
    ({ theme }) => `
    width: 20px;
    height: 20px;
    path {
        fill: ${theme.color.textPrimary};
    }
`,
);

export const MessageBox = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
`;

export const MessageText = styled(Typography)`
    padding: 0 5px;
`;

export const InfoIcon = styled(Icon)(({ theme, variant }) => {
    const mapFillColorWithType = {
        success: theme.color.validation,
        error: theme.color.error,
        default: theme.color.textPrimary,
    };
    const iconFillColor = mapFillColorWithType[variant] || mapFillColorWithType.default;

    return `
            width: ${theme.iconSize.sm}px;
            height: ${theme.iconSize.sm}px;
            path {
                &:first-child {
                    fill: ${iconFillColor};
                }
            }
        `;
});
