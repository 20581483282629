import { getItem, getItems } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';
import { LINK_NAME_SHOP, LINK_NAME_WELLBEING } from '@constants/apiParams';

export const getUri = key => getItem(routes.uris, key);
export const getSupportUris = params => getItems(routes.getSupportUri, params);

export const getShopUri = () => getUri(LINK_NAME_SHOP).then(data => data?.data?.uri);
export const getWellbeingUri = () => getUri(LINK_NAME_WELLBEING).then(data => data?.data?.uri);
export const getSignedUri = key => getUri(key).then(data => data?.data?.uri);
