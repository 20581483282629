import { call, put, takeLatest } from '@redux-saga/core/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from '@services/search/actions';
import { searchItems } from '@services/search/api';
import { getUsers } from '@services/user/api';

export function* searchItemsWorker({ payload, meta }) {
    try {
        const items = yield call(searchItems, payload);
        yield put(actions.loadSearchItemsSuccess({ ...items.data, meta }));
    } catch (e) {
        yield put(actions.loadSearchItemsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* checkEmailWorker(action) {
    try {
        const { data } = yield call(getUsers, action.payload);
        yield put(actions.loadCheckEmailSuccess(data));
    } catch (e) {
        yield put(actions.loadCheckEmailFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadSearchItemsRequest, searchItemsWorker);
    yield takeLatest(actions.loadCheckEmailRequest, checkEmailWorker);
}
