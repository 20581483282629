import styled from 'styled-components';
import Typography from '@components/ui/Typography';

const Title = styled(Typography)(
    ({ size }) => `
    text-align: center;
    font-size: ${size}px;
    margin-top: 0;
    margin-bottom: 20px;
`,
);

export default Title;
