import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = { items: [] };

export default handleActions(
    {
        [types.LOAD_SUPPORT_URIS_SUCCESS]: (state, action) => ({
            ...state,
            supportUri: action.payload,
        }),
    },
    initialState,
);
