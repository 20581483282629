import { put, select, takeLatest } from 'redux-saga/effects';
import { closeModalRequest } from '@services/modal/actions';
import * as userActions from '@services/user/actions';

export function* closeCurrentModalWorker() {
    const state = yield select();
    const { meta } = state.modal;
    if (!meta) return;

    const activeModal = meta.name;
    yield put(closeModalRequest({ name: activeModal }));
}
export default function* Saga() {
    yield takeLatest([userActions.deleteUserSuccess, userActions.updateUserSuccess], closeCurrentModalWorker);
}
