import styled from 'styled-components';
import { Icon, Link, Typography, Button } from '@components/ui';
import { Modal } from '@components';
import { TextButton } from '@components/ui/Form/Button';
import { modalTitleIndent } from '@components/ui/Modal/styled';

const FOOTER_SIDE_GAP_FULL = 85;
const FOOTER_SIDE_GAP = 24;

export const FooterBox = styled.footer(
    ({ theme, $footerSettings, $cookieBannerIsShown }) => `
    background: ${theme.color.cardBackground};
    overflow: auto;
    display: flex;
    flex-direction: column;
    ${$cookieBannerIsShown &&
        `
		margin-bottom: ${$footerSettings.cookieBanner.height.lg}px;
 		${theme.media.md`
			margin-bottom: ${$footerSettings.cookieBanner.height.md}px;
		`};
		${theme.media.sm`
			margin-bottom: ${$footerSettings.cookieBanner.height.sm}px;
		`};
		${theme.media.xs`
			margin-bottom: ${$footerSettings.cookieBanner.height.xs}px;
		`};
		${theme.media.xxs`
			margin-bottom: ${$footerSettings.cookieBanner.height.xxs}px;
		`};
	`}
`,
);

export const Support = styled.div`
    margin: 0 !important;
`;

export const LinksContainer = styled.div(
    ({ theme }) => `
	width: 70%;
	margin: 0;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	${theme.media.md`
		grid-gap: 0;
		width: 75%;
	`};
	${theme.media.sm`
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0;
		width: 60%;
		margin: 10px auto 0;
	`};
	${theme.media.xs`
		grid-template-columns: repeat(1, 1fr);
		width: 100%;
	`};
`,
);

export const SupportLink = styled(Link)(
    ({ theme }) => `
	margin: 0;
	width: 100%;
	a {
        font-size: 14px;
		padding: 0 20px 20px 0;
    }
	${theme.media.sm`
		grid-template-columns: repeat(1, 1fr);
	`};
	${theme.media.xs`
		margin: 0 auto;
		a {
			font-size: 14px;
			padding: 0 0 20px 0;
		}
	`};
`,
);

export const Column = styled.div(
    ({ theme }) => `
    text-align: left;
	display: flex;
	flex-direction: column;
	padding-top: 15px;
	${theme.media.sm`
		padding-top: 0;
	`};
`,
);

export const Copyright = styled(Typography)`
    border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
`;

export const FooterLogo = styled(Icon)`
    width: 220px;
    height: 65px;
    stroke: none;
`;

export const PrimaryContainer = styled.div(
    ({ theme }) => `
    text-align: center;
    padding: 18px 5px 5px 5px;
    height: 100%;
	${theme.media.lg`
		padding: 0 16px;
	`};
	${theme.media.md`
		padding: 0 24px;
	`};
`,
);

export const SecondaryContainer = styled.div(
    ({ $footerSettings, theme, $cookieBannerIsShown }) => `
    display: flex;
    align-items: center;
	background: ${theme.color.cardBackground};
    height: 100%;
	padding: 30px 0;
    ${theme.media.lg`
     	padding: 0 ${FOOTER_SIDE_GAP}px;
	`};
	${theme.media.xxs`
    	padding-right: ${theme.spacing.sm}px;
    	padding-left: ${theme.spacing.sm}px;
	`};
	${$cookieBannerIsShown &&
        `
		margin-bottom: ${$footerSettings.cookieBanner.height.lg}px;
 		${theme.media.md`
			margin-bottom: ${$footerSettings.cookieBanner.height.md}px;
		`};
		${theme.media.sm`
			margin-bottom: ${$footerSettings.cookieBanner.height.sm}px;
		`};
		${theme.media.xs`
			margin-bottom: ${$footerSettings.cookieBanner.height.xs}px;
		`};
		${theme.media.xxs`
			margin-bottom: ${$footerSettings.cookieBanner.height.xxs}px;
		`};
	`}
`,
);

export const RowBox = styled.div(
    ({ theme }) => `
    padding: 19px 0 20px 0;
    text-align: justify;
	${theme.media.lg`
		padding: 0;
	`};
`,
);

export const SecondaryRow = styled.div(
    ({ theme }) => `
	display: flex;
	padding: 0 80px;
	text-align:justify;
    border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
	${theme.media.lg`
		padding: 19px 0;
	`};
	${theme.media.md`
	`};
	${theme.media.sm`
	`};
	${theme.media.xs`
		justify-content: center;
		flex-wrap: wrap;
	`};
	${theme.media.xxs`
		text-align: center;
	`};
`,
);

export const LogoBox = styled.div(
    ({ theme }) => `
	padding: 0 30px 0 80px !important;
	text-align: left;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	${theme.media.lg`
		padding: 0 16px 0 0 !important;
	`};
	${theme.media.xs`
		margin: 20px auto 10px;
		flex-direction: initial;
	`};
	${theme.media.xxs`
		flex-direction: initial;
	`};
`,
);

export const Logo = styled(Icon)(
    ({ theme }) => `
    display: flex;
    align-self: center;
    stroke: none;
    min-width: 160px;
    height: auto;
    margin-right: 40px;
	${theme.media.md`
		margin: 0;
	`};
	${theme.media.xs`
		margin: 0 0 20px 15px;
	`};
    ${theme.media.xxs`
		margin: 0 0 20px 15px;
	`};
`,
);

export const FooterLink = styled(Link)`
    display: inline-block;
    a {
        font-size: 14px;
    }
`;

export const FooterText = styled(Typography).attrs(() => ({ variant: 'caption1' }))`
    display: inline;
`;

export const CookieMessage = styled(Typography).attrs(() => ({ variant: 'caption3' }))`
    margin: 0;
    line-height: 20px;
`;

export const CookieBannerContainer = styled.div(
    ({ $footerSettings, $attachBottom, theme }) => `
    padding: ${theme.spacing.sm}px ${FOOTER_SIDE_GAP_FULL}px;
    background: ${theme.color.pageBackground};
	display: flex;
	align-items: center;
	justify-content: space-between;
	${!$attachBottom &&
        `
	position: fixed;
	bottom: 0;
	z-index: 10;
	`};
	opacity: 0.95;
	width: 100%;
    ${theme.media.lg`
     	padding: 16px 24px;
	`};
	${theme.media.xxs`
	    padding-right: ${theme.spacing.sm}px;
	    padding-left: ${theme.spacing.sm}px;

		${CookieMessage} {
			font-size: 13px;
		}
		a {
			font-size: 13px;
		}
		height: ${$footerSettings.cookieBanner.height.xxs}px;
	`};
`,
);

export const CookieButton = styled(Button)(
    ({ theme }) => `
	margin-left: 80px;
	${theme.media.lg`
		margin-left: 30px;
	`};
	${theme.media.xxs`
		margin-left: 10px;
	`};

`,
);

export const EmailModalBox = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const CompanyLogo = styled.div(
    ({ theme, url }) => `
    position: relative;
    min-height: 140px;
	min-width: 140px;
	max-height: 160px;
	margin-bottom: ${theme.spacing.md}px;
    background-size: cover, 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
	background-image: url(${url})`,
);

export const ModalWrapper = styled(Modal)`
    padding: 40px 24px;
    ${({ theme }) => theme.media.sm`
		padding: 40px 8px;
	`}
`;

export const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const ModalButton = styled(TextButton)(
    ({ theme }) => `
	margin: 0 0 20px 0;
	font-size: 14px;
    svg {
        margin: 0;
    }
	${theme.media.xs`
		margin: 0 auto 20px;
	`};
	${theme.media.xxs`
		margin: 0 auto 20px;
	`};
`,
);

export const AboutTitle = styled(Typography)(
    ({ theme }) => `
		${modalTitleIndent(theme)};
`,
);

export const TextModalBox = styled(Typography)`
    margin-bottom: 31px;
`;

export const ModalTextBox = styled(Typography)`
    text-align: center;
    margin-bottom: 31px;
`;

export const ModalBoldTextBox = styled(ModalTextBox)`
    font-weight: bold;
`;

export const ModalLink = styled(Link)(
    ({ theme }) => `
	a {
		margin-bottom: 31px;
		margin-left: 4px;
		font-family: ${theme.font.regular};
	}
`,
);

export const WhiteSpaceTextBox = styled(Typography)`
    text-align: center;
    margin-bottom: 31px;
    white-space: pre;
`;

export const PoweredLogoBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const BottomModalLogo = styled(Icon)`
    margin-left: 10px;
`;

export const BoldText = styled.span`
    font-weight: bold;
`;

export const ModalLinkInText = styled(Link)(
    ({ theme }) => `
	a {
		font-family: ${theme.font.regular};
	}
`,
);

export const CloseButton = styled(Typography).attrs(() => ({ variant: 'bodySecondary' }))(
    ({ theme }) => `
	color: ${theme.color.cardBackground};
`,
);

export const ExitIcon = styled(Icon).attrs(() => ({ id: 'ic_exit_m' }))`
    width: 20px;
    height: 20px;
    margin-right: 5px;
    path {
        fill: ${({ theme }) => theme.color.primary};
    }
`;
