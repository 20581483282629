import { components } from 'react-select';
import styled from 'styled-components';
import {
    disabledInputStyles,
    disabledLabelStyles,
    errorInputStyles,
    getDefaultStyles,
    INPUT_BOTTOM_SPACE,
    INPUT_MAX_HEIGHT,
    InputBlock,
    validInputStyles,
    validLabelStyles,
} from '@components/ui/Form/styled';
import Typography from '@components/ui/Typography';
import Icon from '@components/ui/Icon';

const INPUT_SPACE_ERROR = 2;

export const SelectContainerElement = styled(InputBlock)(
    ({ theme, $value, $valid, $error, $disabled }) => `
    display: flex;
    align-items: flex-end;
    ${getDefaultStyles($value, theme)};
   	height: auto;
   	max-height: ${INPUT_MAX_HEIGHT}px;
    & ~ label {
    	${validLabelStyles(theme, $value)};
    	${$disabled && disabledLabelStyles(theme, $value)};
    }
    ${$valid && validInputStyles(theme)};
    ${$error && errorInputStyles(theme)};
    ${$disabled && disabledInputStyles(theme)};

`,
);

export const ControlElement = styled.div`
    display: flex;
    margin-bottom: 0;
    width: 100%;
`;

export const ValueContainerElement = styled.div`
    width: 100%;
    position: relative;
`;

export const OptionElement = styled.div(
    ({ theme }) => `
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid ${theme.color.lightGrey};
    &:hover {
        background: ${theme.color.pageBackground};
    }
`,
);

export const OptionText = styled(Typography)`
    width: 100%;
`;

export const MenuListElement = styled.div`
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;
    padding: 0;
`;

export const SelectIcon = styled(Icon)(
    ({ theme, $disabled }) => `
	path {
		stroke: ${$disabled ? theme.color.textDisabled : theme.color.primary};
	}
`,
);

export const IndicatorContainerElement = styled.div`
    display: flex;
    align-items: center;
    height: ${INPUT_MAX_HEIGHT}px;
`;

export const SingleValueElement = styled.div(
    ({ theme, $disabled }) => `
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0;
    color: ${theme.color.textPrimary};
    font-size: ${theme.fontSize.input};
    font-family: ${theme.font.bold};
    bottom: -${INPUT_SPACE_ERROR}px;
    position: absolute;
    ${$disabled && disabledInputStyles(theme)};

`,
);

export const InputNode = styled(components.Input)`
    padding: 0;
    position: absolute;
    margin: 0;
    width: 100%;
    bottom: -${INPUT_SPACE_ERROR}px;
    left: 0;
`;

export const Container = styled.div`
    margin-bottom: ${INPUT_BOTTOM_SPACE}px;
`;
