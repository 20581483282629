export const MAX_SNACKBAR = 3;

export const headersGap = {
    lg: 66,
    md: 66,
    sm: 58,
    xs: 66,
};

export const POSITION = {
    vertical: 'top',
    horizontal: 'center',
};
