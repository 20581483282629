import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as actions from '@services/user/actions';
import * as authActions from '@services/auth/actions';
import { addSuccessNotification, addErrorNotification } from '@services/notifications/actions';
import {
    loadAchievementsRequest,
    loadUpcomingUserAchievementsRequest,
    loadUserAchievementsRequest,
} from '@services/achievements/actions';
import {
    getUser,
    updateUser,
    signUp,
    deleteUser,
    updateUserAvatar,
    updateUserTerms,
    updateUserSubscription,
    updatePhone,
    confirmPhoneNumber,
} from '@services/user/api';
import { getItem, setItem } from '@utils/storageUtils';
import { transformUser } from '@utils/user';
import { clientRoutes, REACTIVATION_MODAL, USER_ID, AGREEMENTS } from '@constants';
import { configSelector } from '@services/auth/selectors';
import { userSelector } from '@services/user/selectors';
import { loadGoalsRequest, loadOldGoalsRequest } from '@services/goals/actions';
import { clearPosts, loadPostsRequest } from '@services/posts/actions';
import {
    achievementsOptions,
    goalsOptions,
    postsOptions,
    upcomingUserAchievementsOptions,
} from '@constants/requestsOptions';
import { openModalRequest } from '@services/modal/actions';

export function* signUpWorker(action) {
    try {
        const { data } = yield call(signUp, action.payload);
        yield setItem([USER_ID], [data.id]);
        const config = yield select(configSelector);
        if (config?.features?.phone?.enableClient2FA) {
            yield setItem([AGREEMENTS], [action.payload.agreements]);
        } else {
            yield put(actions.loadUserRequest(data.id));
        }
        yield put(actions.signUpSuccess(data));
        yield put(authActions.loginSuccess(data));
        if (config?.features?.phone?.needsConfirmation) {
            yield put(actions.updateUserPhoneNumberSuccess());
        }
    } catch (e) {
        yield put(actions.signUpFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* onboardingWorker(action) {
    try {
        const { data } = yield call(updateUser, transformUser(action.payload));
        yield put(actions.onboardingSuccess(data));
        yield put(actions.loadUserRequest(action.payload.id));
    } catch (e) {
        yield put(actions.onboardingFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserWorker(action) {
    try {
        const { data } = yield call(updateUser, transformUser(action.payload));
        yield put(actions.updateUserSuccess(data));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updateUserFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserLanguageWorker({ payload, meta }) {
    try {
        const { data } = yield call(updateUser, payload);
        yield put(actions.updateUserSuccess(data));
        yield put(clearPosts());
        yield put(loadAchievementsRequest(achievementsOptions));
        yield put(loadUserAchievementsRequest({ userId: meta.userId }));
        yield put(loadUpcomingUserAchievementsRequest({ userId: meta.userId, ...upcomingUserAchievementsOptions }));
        yield put(loadGoalsRequest({ userId: meta.userId, ...goalsOptions }));
        yield put(loadOldGoalsRequest({ userId: meta.userId }));
        yield put(loadPostsRequest(postsOptions));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updateUserFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* getUserWorker(action) {
    try {
        const { data } = yield call(getUser, action.payload);
        yield put(actions.loadUserSuccess(data));
    } catch (e) {
        yield put(actions.loadUserFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserProfileWorker(action) {
    try {
        const { data } = yield call(updateUserAvatar, action.payload);
        yield put(actions.updateUserProfileSuccess(data));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updateUserProfileFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* deleteUserWorker({ payload, meta }) {
    try {
        yield call(deleteUser, payload);
        yield put(actions.deleteUserSuccess());
        yield put(authActions.logOut({ meta, showReactivationModal: true }));
        yield put(addSuccessNotification({ message: meta.message }));
        yield put(openModalRequest({ name: REACTIVATION_MODAL, meta }));
    } catch (e) {
        yield put(actions.deleteUserFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* removeAvatarWorker(action) {
    const id = getItem([USER_ID]);
    try {
        const { data } = yield call(updateUserAvatar, action.payload);
        yield put(actions.deleteUserAvatarSuccess(data));
        yield put(actions.loadUserRequest(id));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.deleteUserAvatarFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserTermsWorker(action) {
    try {
        const { data } = yield call(updateUserTerms, transformUser(action.payload));
        yield put(actions.updateUserTermsSuccess(data));
        if (!action.meta?.hideNotification) {
            yield put(addSuccessNotification());
        }
    } catch (e) {
        yield put(actions.updateUserTermsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserSubscriptionWorker(action) {
    try {
        const { data } = yield call(updateUserSubscription, action.payload);
        yield put(actions.updateUserSubscriptionSuccess(data));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updateUserSubscriptionFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserPhoneNumberWorker(action) {
    try {
        const { countryCallingCode, phoneNumber } = action.payload;
        yield call(updatePhone, { countryCallingCode, phoneNumber });
        yield put(actions.updateUserPhoneNumberSuccess());
    } catch (e) {
        yield put(actions.updateUserPhoneNumberFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserPhoneValidationWorker(action) {
    try {
        const { code } = action.payload;
        const { id } = yield select(userSelector);
        yield call(confirmPhoneNumber, { userId: id, code });
        yield put(actions.loadUserRequest(id));
        yield put(actions.updateUserPhoneValidationSuccess());
    } catch (e) {
        yield put(actions.updateUserPhoneValidationFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadUserEmailConfirmationWorker(action) {
    try {
        const { id } = yield select(userSelector);
        const { data } = yield call(getUser, id);
        if (data.emailConfirmed) {
            yield put(actions.loadUserSuccess(data));
        } else {
            yield put(addErrorNotification({ message: action.payload.errorMessage }));
        }
        yield put(actions.loadUserEmailConfirmationSuccess());
    } catch (e) {
        yield put(actions.loadUserEmailConfirmationFailure(e.message));
    }
}

export default function* Saga() {
    yield takeLatest(actions.signUpRequest, signUpWorker);
    yield takeLatest(actions.onboardingRequest, onboardingWorker);
    yield takeLatest(actions.updateUserLanguageRequest, updateUserLanguageWorker);
    yield takeLatest(actions.updateUserRequest, updateUserWorker);
    yield takeLatest(actions.updateUserTermsRequest, updateUserTermsWorker);
    yield takeLatest(actions.updateUserProfileRequest, updateUserProfileWorker);
    yield takeLatest(actions.updateUserSubscriptionRequest, updateUserSubscriptionWorker);
    yield takeLatest(actions.loadUserRequest, getUserWorker);
    yield takeLatest(actions.fetchUserRequest, getUserWorker);
    yield takeLatest(actions.deleteUserRequest, deleteUserWorker);
    yield takeLatest(actions.deleteUserAvatarRequest, removeAvatarWorker);
    yield takeLatest(actions.updateUserPhoneNumberRequest, updateUserPhoneNumberWorker);
    yield takeLatest(actions.updateUserPhoneValidationRequest, updateUserPhoneValidationWorker);
    yield takeLatest(actions.loadUserEmailConfirmationRequest, loadUserEmailConfirmationWorker);
}
