import { createAction } from 'redux-actions';
import * as types from './actionTypes';

export const loadDashboardCellsRequest = createAction(types.LOAD_DASHBOARD_CELLS_REQUEST);
export const loadDashboardCellsSuccess = createAction(types.LOAD_DASHBOARD_CELLS_SUCCESS);
export const loadDashboardCellsFailure = createAction(types.LOAD_DASHBOARD_CELLS_FAILURE);

export const updateDashboardCellsRequest = createAction(types.UPDATE_DASHBOARD_CELLS_REQUEST);
export const updateDashboardCellsSuccess = createAction(types.UPDATE_DASHBOARD_CELLS_SUCCESS);
export const updateDashboardCellsFailure = createAction(types.UPDATE_DASHBOARD_CELLS_FAILURE);

export const deleteDashboardCellsRequest = createAction(types.DELETE_DASHBOARD_CELLS_REQUEST);
export const deleteDashboardCellsSuccess = createAction(types.DELETE_DASHBOARD_CELLS_SUCCESS);
export const deleteDashboardCellsFailure = createAction(types.DELETE_DASHBOARD_CELLS_FAILURE);

export const addDashboardCellsRequest = createAction(types.ADD_DASHBOARD_CELLS_REQUEST);
export const addDashboardCellsSuccess = createAction(types.ADD_DASHBOARD_CELLS_SUCCESS);
export const addDashboardCellsFailure = createAction(types.ADD_DASHBOARD_CELLS_FAILURE);
