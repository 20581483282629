import React from 'react';
import styled from 'styled-components';
import { Loader } from '@components/ui/index';
import PropTypes from 'prop-types';
import { is } from 'ramda';

const buttonHeight = 35;

const isNumber = is(Number);

const getButton = ButtonElement => ({ children, loadComponent, loading, inverted, ...props }) => (
    <ButtonElement {...props}>
        {loading ? loadComponent || <Loader size={20} inverted={inverted} /> : children}
    </ButtonElement>
);

const defaultTypes = {
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    onClick: PropTypes.func,
};

const ButtonElement = styled.button.attrs(props => ({
    tabIndex: isNumber(props.tabIndex) ? props.tabIndex : 0,
    type: props.type || 'button',
}))(
    ({ theme, contained }) => `
	padding: 0 20px;
	color: ${contained ? theme.color.textContrast : theme.color.primary};
	background: ${contained ? theme.color[contained] : 'none'};
	border-radius: 100px;
	border-width: ${contained ? '0' : '3px'};
	border-color: ${theme.color.primary};
	border-style: solid;
	font-size: 16px;
	font-family: ${theme.font.bold};
	cursor: pointer;
	transition: all ease-in-out 200ms;
	&:hover {
		border-color: ${theme.color.primarySelect};
		color: ${contained ? theme.color.textContrast : theme.color.primarySelect};
	}
	${contained &&
        `&:hover {
			background: ${theme.color.primarySelect};
		};
		`};

	&:disabled {
		background: ${contained ? theme.color.disabled : 'none'};
		border-color: ${theme.color.disabled};
		color: ${contained ? theme.color.textContrast : theme.color.disabled};
	}

	min-height: ${buttonHeight}px;
`,
);

export const TextButtonElement = styled(ButtonElement)(
    ({ theme }) => `
	border: none;
	padding: 0;
	border-radius: 0;
	text-decoration: underline;
    display: inline-flex;
    align-items: center;
    display: flex:
    align-items: center;
	&:disabled {
		color: ${theme.color.disabled};
	}
	height: auto;
	line-height: 24px;
`,
);

export const BlockButtonElement = styled(TextButtonElement)`
    width: 100%;
    &:hover {
        background: ${({ theme }) => theme.color.lightGrey};
    }
`;

export const ButtonBox = styled.div(
    ({ theme }) => `
	display: flex;
	margin: 0 -${theme.spacing.sm}px;
    justify-content: space-around;
    border-top: 1px solid ${theme.color.lightGrey};
    ${TextButtonElement} {
    	border-right: 1px solid ${theme.color.lightGrey};
    }
    ${TextButtonElement}:last-of-type {
    	border: none;
    }
`,
);

const TextButtonEndIcon = styled('div')`
    display: inline-flex;
    svg {
        margin-left: 5px;
    }
`;

const TextButtonStartIcon = styled('div')`
    display: inline-flex;
    svg {
        margin-right: 5px;
    }
`;

export const BlockButton = getButton(BlockButtonElement);
export const TextButton = ({ children, startIcon: StartIcon, endIcon: EndIcon, ...props }) => (
    <TextButtonElement {...props}>
        {StartIcon && (
            <TextButtonStartIcon>
                <StartIcon disabled={props.disabled} />
            </TextButtonStartIcon>
        )}
        {children}
        {EndIcon && (
            <TextButtonEndIcon>
                <EndIcon disabled={props.disabled} />
            </TextButtonEndIcon>
        )}
    </TextButtonElement>
);
const Button = getButton(ButtonElement);

BlockButton.propTypes = defaultTypes;
Button.propTypes = defaultTypes;
TextButton.propTypes = {
    ...defaultTypes,
    startIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    endIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default Button;
