export const clientRoutes = {
    activities: () => '/activities',
    activityPage: (id = ':id') => `/activities/${id}`,
    additionalConsent: () => '/additionalConsent',
    articlePage: (id = ':id') => `/discover/${id}`,
    biometric: () => '/biometric',
    callback: () => '/callback',
    coach: () => `/coach`,
    confirmEmail: () => '/confirmEmail',
    confirmPrivacy: () => '/confirmPrivacy',
    discover: () => `/discover`,
    login: () => '/login',
    me: () => '/me',
    moves: () => '/social',
    onboarding: () => '/onboarding',
    partnerProgram: (id = ':id') => `/partnerProgram/${id}`,
    phone: () => '/phone',
    profile: tab => `/profile${tab ? `?tab=${tab}` : ''}`,
    program: (id = ':id') => `/program/${id}`,
    programsession: (id = ':id') => `/programsession/${id}`,
    root: () => '/',
    settings: () => '/settings',
    signup: () => '/signup',
    social: tab => `/social${tab ? `?tab=${tab}` : ''}`,
    subscription: () => '/subscription',
    terms: (id = ':id') => `/terms/${id}`,
    challenge: (id = ':id') => `/social/challenge/${id}`,
    challenges: (id = ':id') => `/social/challenge/${id}`,
    event: (id = ':id') => `/program/${id}`,
    eventsession: (id = ':id') => `/eventsession/${id}`,
    partnercontent: (id = ':id') => `/partnerProgram/${id}`,
    twoFactorAuth: () => '/twoFactorAuth',
    surveys: (surveyKey = ':surveyKey') => `/surveys/${surveyKey}`,
    desktopSurvey: (surveyKey = ':surveyKey') => `/desktopSurvey/${surveyKey}`,
    redirect: () => `/redirect`,
};

export const unAuthRoutes = [
    clientRoutes.callback(),
    clientRoutes.login(),
    clientRoutes.root(),
    clientRoutes.signup(),
    clientRoutes.redirect(),
];

export const publicRoutes = [
    clientRoutes.activityPage(),
    clientRoutes.articlePage(),
    clientRoutes.terms(),
    clientRoutes.surveys(),
];

export const webViewRoutes = [clientRoutes.surveys('')];
