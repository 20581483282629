import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MAX_SNACKBAR, POSITION, headersGap } from '@constants/snackBar';

const useStyles = makeStyles(theme => ({
    anchorOriginTopCenter: {
        width: '100vw',
        transform: 'translateX(0)',
    },
    containerRoot: {
        [theme.breakpoints.up('xs')]: {
            top: headersGap.xs,
        },
        [theme.breakpoints.up('sm')]: {
            top: headersGap.sm,
        },
        [theme.breakpoints.up('md')]: {
            top: headersGap.md,
        },
        [theme.breakpoints.up('lg')]: {
            top: headersGap.lg,
        },
    },

    message: {
        width: '100%',
        padding: '15px 0',
    },

    // should use !important (found an answer https://github.com/iamhosseindhv/notistack/issues/305#issuecomment-685993284)
    variantSuccess: {
        backgroundColor: '#f0fae5 !important',
        boxShadow: 'none',
    },
    variantError: {
        backgroundColor: '#FAE5E8 !important',
        boxShadow: 'none',
    },
}));

export const NotistackProvider = ({ children }) => {
    const classes = useStyles();

    return (
        <SnackbarProvider classes={classes} hideIconVariant maxSnack={MAX_SNACKBAR} anchorOrigin={POSITION}>
            {children}
        </SnackbarProvider>
    );
};
