import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Label, Message } from '@components/ui/Form/styled';
import {
    ControlElement,
    IndicatorContainerElement,
    InputNode,
    MenuListElement,
    OptionElement,
    OptionText,
    SelectIcon,
    SingleValueElement,
    ValueContainerElement,
} from '@components/ui/Form/Select/styled';
import { SelectContainerElement } from './styled';

const SelectContainer = ({ children, innerProps, innerRef, selectProps, isFocused }) => {
    const value = selectProps?.value?.label || '';
    const isValid = !selectProps.error && value;

    const containerProps = {
        ref: innerRef,
        ...innerProps,
        $disabled: selectProps.isDisabled,
        $error: selectProps.error && selectProps.touched,
        $value: value || isFocused,
        $valid: isValid,
    };

    return (
        <>
            <SelectContainerElement {...containerProps}>
                <>{children}</>
            </SelectContainerElement>

            <Label $focused={isFocused}>
                <FormattedMessage id={selectProps.label} />
            </Label>
            {selectProps.touched && selectProps.error && <Message>{selectProps.error}</Message>}
        </>
    );
};

const Control = ({ children, innerProps, innerRef, selectProps }) => (
    <ControlElement ref={innerRef} {...innerProps} value={selectProps.value}>
        {children}
    </ControlElement>
);

const ValueContainer = ({ children, innerProps, innerRef }) => (
    <ValueContainerElement ref={innerRef} {...innerProps}>
        {children}
    </ValueContainerElement>
);

const Option = ({ children, innerProps, innerRef }) => (
    <OptionElement ref={innerRef} {...innerProps}>
        <OptionText>{children}</OptionText>
    </OptionElement>
);

const MenuList = ({ children, innerProps, innerRef }) => (
    <MenuListElement ref={innerRef} {...innerProps}>
        {children}
    </MenuListElement>
);

const IndicatorSeparator = () => <></>;

const IndicatorsContainer = ({ innerProps, innerRef, selectProps }) => (
    <IndicatorContainerElement ref={innerRef} {...innerProps}>
        <SelectIcon id={selectProps.menuIsOpen ? 'chevron_up' : 'chevron_down'} $disabled={selectProps.isDisabled} />
    </IndicatorContainerElement>
);

const SingleValue = ({ children, innerProps, innerRef, selectProps }) => (
    <SingleValueElement ref={innerRef} {...innerProps} $disabled={selectProps.isDisabled}>
        {children}
    </SingleValueElement>
);

const NoOptionsMessage = ({ innerProps, innerRef }) => (
    <OptionElement ref={innerRef} {...innerProps}>
        <OptionText>
            <FormattedMessage id="NO_OPTIONS_FOUND" />
        </OptionText>
    </OptionElement>
);

export default {
    IndicatorSeparator,
    SelectContainer,
    Control,
    ValueContainer,
    IndicatorsContainer,
    SingleValue,
    Input: InputNode,
    Option,
    MenuList,
    NoOptionsMessage,
};
