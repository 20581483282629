export const CREATE_START_SURVEY_REQUEST = 'CREATE_START_SURVEY_REQUEST';
export const CREATE_START_SURVEY_SUCCESS = 'CREATE_START_SURVEY_SUCCESS';
export const CREATE_START_SURVEY_FAILURE = 'CREATE_START_SURVEY_FAILURE';

export const RESET_SURVEY = 'RESET_SURVEY';

export const CREATE_SAVE_ANSWER_SURVEY_REQUEST = 'CREATE_SAVE_ANSWER_SURVEY_REQUEST';
export const CREATE_SAVE_ANSWER_SURVEY_SUCCESS = 'CREATE_SAVE_ANSWER_SURVEY_SUCCESS';
export const CREATE_SAVE_ANSWER_SURVEY_FAILURE = 'CREATE_SAVE_ANSWER_SURVEY_FAILURE';

export const SILENT_SAVE_ANSWER_SURVEY_REQUEST = 'SILENT_SAVE_ANSWER_SURVEY_REQUEST';
export const SILENT_SAVE_ANSWER_SURVEY_SUCCESS = 'SILENT_SAVE_ANSWER_SURVEY_SUCCESS';
export const SILENT_SAVE_ANSWER_SURVEY_FAILURE = 'SILENT_SAVE_ANSWER_SURVEY_FAILURE';

export const LOAD_SURVEY_QUESTION_MEDIA_REQUEST = 'LOAD_SURVEY_QUESTION_MEDIA_REQUEST';
export const LOAD_SURVEY_QUESTION_MEDIA_SUCCESS = 'LOAD_SURVEY_QUESTION_MEDIA_SUCCESS';
export const LOAD_SURVEY_QUESTION_MEDIA_FAILURE = 'LOAD_SURVEY_QUESTION_MEDIA_FAILURE';

export const INIT_SURVEY_QUESTION_MEDIA = 'INIT_SURVEY_QUESTION_MEDIA';
