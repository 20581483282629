import { put, takeLatest } from 'redux-saga/effects';
import { getItem, setItem } from '@utils/storageUtils';
import { DASHBOARD_CELLS } from '@constants';
import { storageCellsSelector } from '@services/settings/selectors';
import { addSuccessNotification, addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';

export function* loaDashboardCellsWorker() {
    try {
        const cells = storageCellsSelector();
        yield put(actions.loadDashboardCellsSuccess(cells));

        setItem([DASHBOARD_CELLS], [cells]);
        yield put(actions.loadDashboardCellsSuccess(cells));
    } catch (e) {
        yield put(actions.loadDashboardCellsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateDashboardCellsWorker(action) {
    try {
        setItem([DASHBOARD_CELLS], [action.payload]);
        yield put(actions.updateDashboardCellsSuccess(action.payload));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updateDashboardCellsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* addDashboardCellsWorker(action) {
    try {
        const cells = getItem(DASHBOARD_CELLS);
        const updatedCells = cells.concat(action.payload);

        setItem([DASHBOARD_CELLS], [updatedCells]);
        yield put(actions.addDashboardCellsSuccess(updatedCells));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.addDashboardCellsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* deleteDashboardCellsWorker(action) {
    try {
        const cells = getItem(DASHBOARD_CELLS);
        const updatedCells = cells.filter(id => id !== action.payload);

        setItem([DASHBOARD_CELLS], [updatedCells]);
        yield put(actions.deleteDashboardCellsSuccess(updatedCells));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.deleteDashboardCellsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadDashboardCellsRequest, loaDashboardCellsWorker);
    yield takeLatest(actions.updateDashboardCellsRequest, updateDashboardCellsWorker);
    yield takeLatest(actions.deleteDashboardCellsRequest, deleteDashboardCellsWorker);
    yield takeLatest(actions.addDashboardCellsRequest, addDashboardCellsWorker);
}
