import React from 'react';
import { FormattedMessage } from 'react-intl';

// all limits are in first units in every list (by default it's metric)

export const metrics = {
    blood: {
        name: <FormattedMessage id="common_blood_values_title" />,
        variants: [
            {
                label: 'UNIT_MM_L',
                value: 'mmol/l',
                step: 0.1,
            },
            {
                label: 'UNIT_MG_DL',
                value: 'mg/dl',
                step: 0.1,
            },
        ],
        limits: {
            min: 4,
            max: 10,
        },
    },
    distance: {
        name: <FormattedMessage id="DISTANCE" />,
        variants: [
            {
                label: 'UNIT_KM',
                value: 'km',
                step: 0.001,
            },
            {
                label: 'UNIT_MI',
                value: 'mi',
                step: 0.001,
            },
        ],
        limits: {
            min: 0.001,
            max: 1000,
        },
    },
    elevation: {
        name: <FormattedMessage id="ELEVATION" />,
        variants: [
            {
                label: 'UNIT_M',
                value: 'm',
                step: 1,
            },
            {
                label: 'UNIT_FT',
                value: 'ft',
                step: 1,
            },
        ],
        limits: {
            min: -12000,
            max: 20000,
        },
    },
    height: {
        name: <FormattedMessage id="HEIGHT" />,
        variants: [
            {
                label: 'UNIT_M',
                value: 'm',
                step: 0.01,
            },
            {
                label: 'UNIT_FT_IN',
                value: 'ft/in',
                step: 1,
            },
        ],
        limits: {
            min: 0.8,
            max: 3,
        },
    },
    liquids: {
        name: <FormattedMessage id="LIQUIDS" />,
        variants: [
            {
                label: 'UNIT_L',
                value: 'l',
                step: 0.1,
            },
            {
                label: 'UNIT_OZ_US',
                value: 'oz(US)',
                step: 1,
            },
            {
                label: 'UNIT_OZ_UK',
                value: 'oz(UK)',
                step: 1,
            },
        ],
        limits: {
            min: 1,
            max: 6,
        },
    },
    speed: {
        name: <FormattedMessage id="SPEED" />,
        variants: [
            {
                label: 'UNIT_KMH',
                value: 'km/h',
                step: 0.1,
            },
            {
                label: 'unit_short_name_miles_per_hour',
                value: 'mph',
                step: 0.1,
            },
        ],
        limits: {
            min: 0,
            max: 216,
        },
    },
    temperature: {
        name: <FormattedMessage id="TEMPERATURE" />,
        variants: [
            {
                label: 'UNIT_C',
                value: 'C',
                step: 1,
            },
            {
                label: 'UNIT_F',
                value: 'F',
                step: 1,
            },
        ],
        limits: {
            min: -40,
            max: 60,
        },
    },
    waist: {
        name: <FormattedMessage id="body_value_waist_circumference_name" />,
        variants: [
            {
                label: 'UNIT_CM',
                value: 'cm',
            },
            {
                label: 'UNIT_IN',
                value: 'in',
            },
        ],
    },
    weight: {
        name: <FormattedMessage id="body_value_weight_name" />,
        variants: [
            {
                label: 'UNIT_KG',
                value: 'kg',
                step: 1,
            },
            {
                label: 'UNIT_LB',
                value: 'lb',
                step: 1,
            },
            {
                label: 'UNIT_ST_LB',
                value: 'st lb',
                step: 1,
            },
        ],
        limits: {
            min: 20,
            max: 300,
        },
    },
};
