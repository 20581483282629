import routes from '@constants/apiRoutes';
import { get, post, remove, upload } from './request';

export const getItems = (route, params) => get(route, null, params);
export const deleteItems = (route, params) => remove(route, null, params);
export const getItem = (route, id, params) => get(route, id, params);
export const editItem = (route, data, id, params) => post(route, { data }, id, params);
export const editItems = (route, data, params) => post(route, { data }, null, params);
export const newItem = (route, data, params) => post(route, { data }, null, params);
export const deleteItem = (route, id, params) => remove(route, id, params);
export const uploadItem = (route, id, params) => upload(route, id, params);
export const getEntityItems = params => get(routes[params.entity], null, { ...params });
