import { createSelector } from 'reselect';
import { keys, compose, head, filter, map } from 'ramda';

const transformFields = {
    name: ['firstName', 'lastName', 'displayName'],
    body: ['dateOfBirth', 'sex', 'height'],
    weight: ['mass', 'time'],
    custom: ['city', 'county', 'country'],
};

export const proxyUser = user =>
    new Proxy(user, {
        get(target, targetKey) {
            const transformKey = compose(
                head,
                filter(field => field),
                map(field => (transformFields[field].includes(targetKey) ? field : undefined)),
                keys,
            )(transformFields);
            return transformKey ? target[transformKey] && target[transformKey][targetKey] : target[targetKey];
        },
    });

const defaultUserSelector = state => state.user;
export const userSelector = createSelector(defaultUserSelector, proxyUser);
export const loadingUserSelector = state => state.indicator.loading.user;
export const loadingUserEmailSelector = state => state.indicator.loading.confirmation;
export const updatingUserSelector = state => state.indicator.updating.user;
export const updatingOnboardingSelector = state => state.indicator.updating.onboarding;
export const smsCodeWasSentSelector = state => state.user.smsCodeWasSent;
