import { ARTICLE, clientRoutes, PLATFORM_SETTINGS } from '@constants';
import routes from '@constants/routes';
import { loginSSORequest } from '@services/auth/actions';
import { loadUserRequest } from '@services/user/actions';
import { push } from 'connected-react-router';
import { always, compose, head, identity, ifElse, includes, last, split } from 'ramda';

const DACADOO_PROTOCOL = 'dacadoo';
const ACCOUNT = 'account';
const APPSETTINGS = 'appsettings';
const DISCOVER = 'discover';
const SIGNED_LINK = 'signed_link';

const getPointsActions = id => {
    switch (id) {
        case 'check':
            return { action: loadUserRequest, isAction: true, linkTo: '#' };
        default:
            return null;
    }
};

export const staticResources = {
    resources: {
        ...clientRoutes,
    },
    functions: {
        ...clientRoutes,
        points: getPointsActions,
    },
};
export const collectionsMap = {
    assessment: 'assessment',
    assets: 'assets',
    article: 'articlePage',
    challenges: 'challenge',
    challengetemplates: 'challengeTemplates',
    comments: 'comments',
    media: 'media',
    moves: 'moves',
    program: 'program',
    scopes: 'scopes',
    settings: 'profile',
    subscore: 'subscore',
    privacy: 'settings',
    membership: 'MY_ACCOUNT',
};

export const getLinkParams = ifElse(includes('?'), compose(last, split('?')), always(''));

export const removeLinkParams = ifElse(includes('?'), compose(head, split('?')), identity);

export const parseLink = function(link = '', isDeeplink) {
    const [params, updatedLink] = [getLinkParams(link), removeLinkParams(link)];
    const parts = updatedLink.split(/\/+id\/|\/|:\/{2}/);
    const protocol = head(parts);
    const isExternalLink = protocol !== DACADOO_PROTOCOL;
    const isSignedLink = !isExternalLink && parts[1] === SIGNED_LINK;
    let index = 1;

    if (!isDeeplink) {
        index = isExternalLink ? 3 : 2;
    }

    const res = {
        action: parts[index],
        collection: parts[index + 1],
        params,
        id: parts[index + 2],
        path: parts.slice(index + 1).join('/'),
        isExternalLink,
        link,
        isSignedLink,
    };

    if (parts[index + 2] === PLATFORM_SETTINGS.toLowerCase() && parts[index + 3]) {
        res.id = collectionsMap[parts[index + 3]];
    }

    if (res.id === APPSETTINGS) {
        res.id = ACCOUNT;
    }

    if (res.collection === DISCOVER && res.id) {
        res.collection = ARTICLE;
    }

    res.collection = collectionsMap[res.collection] || parts[index + 1];

    return res;
};

export const getAndOpenLink = (getUri, isLoading, setLoading = () => {}) => {
    if (isLoading) {
        return;
    }

    setLoading(true);
    getUri()
        .then(uri => {
            if (uri) {
                window.open(uri, '_blank', 'noopener');
            }
        })
        .finally(() => {
            setLoading(false);
        });
};

export const openSignup = (dispatch, useSSO) =>
    useSSO ? dispatch(loginSSORequest()) : dispatch(push(routes.signup.path()));

export const openLogin = (dispatch, useSSO) =>
    useSSO ? dispatch(loginSSORequest()) : dispatch(push(routes.login.path()));
