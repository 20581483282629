import { handleActions } from 'redux-actions';
import * as types from '@services/challengeParticipants/actionTypes';
import * as challengeTypes from '@services/challenges/actionTypes';

export const initialState = {
    items: [],
    counts: 0,
    nextLink: null,
    userCP: null,
};

export default handleActions(
    {
        [types.LOAD_CHALLENGE_PARTICIPANTS_SUCCESS]: (state, action) => ({
            ...state,
            items: [...action.payload.items],
            counts: action.payload.counts,
            nextLink: action.payload.nextLink,
        }),
        [types.LOAD_MORE_CHALLENGE_PARTICIPANT_SUCCESS]: (state, action) => ({
            ...state,
            items: [...state.items, ...action.payload.items],
            counts: action.payload.counts,
            nextLink: action.payload.nextLink,
        }),
        [types.LOAD_USER_CP_SUCCESS]: (state, action) => ({
            ...state,
            userCP: action.payload,
        }),
        [challengeTypes.CLEAN_CHALLENGE_DETAIL_PAGE]: () => initialState,
        [challengeTypes.LOAD_CHALLENGE_DETAIL_REQUEST]: () => initialState,
        [types.DELETE_USER_CP_SUCCESS]: state => ({
            ...state,
            userCP: null,
        }),
    },
    initialState,
);
