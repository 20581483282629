import React from 'react';
import { Img } from 'react-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getMapLink, getMediaLink } from '@utils/media';

const Card = styled.div`
    overflow: hidden;
    img {
        width: 100%;
    }
    &:empty {
        display: none;
    }
`;

const Image = props => {
    const { alt, id, type, loaderComponent, className, defaultImage, onLoad = () => {} } = props;
    const src = props.src || (id && getMediaLink(id, type));

    return (
        <Card className={className} role="img" tabIndex={props.tabIndex} {...props}>
            {src ? (
                <Img
                    key={src}
                    alt={alt}
                    src={src}
                    loader={loaderComponent || defaultImage}
                    unloader={defaultImage}
                    onLoad={onLoad}
                />
            ) : (
                defaultImage
            )}
        </Card>
    );
};

export const Map = props => {
    const { id, locale } = props;
    const src = id && getMapLink(id, locale);

    return <Image {...props} src={src} type="map" />;
};

Image.propTypes = {
    alt: PropTypes.string.isRequired,
    id: PropTypes.string,
    type: PropTypes.string,
    loaderSize: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    withLoader: PropTypes.bool,
};

Image.defaultProps = {
    withLoader: false,
};

export default Image;
