import { handleActions } from 'redux-actions';
import * as configTypes from '../config/actionTypes';

export const initialState = { items: [], catalogStrings: {} };

export default handleActions(
    {
        [configTypes.LOAD_CONFIG_SUCCESS]: (state, action) => ({
            ...state,
            catalogStrings: action.payload.catStrings,
        }),
    },
    initialState,
);
