import React from 'react';
import { handleActions } from 'redux-actions';
import { DEFAULT_LOCALE } from '@constants/locale';
import * as types from './actionTypes';

export const initialState = {
    locale: DEFAULT_LOCALE,
};

export default handleActions(
    {
        [types.CHANGE_LOCALE_REQUEST]: (state, action) => ({
            ...state,
            locale: action.payload.locale,
        }),
    },
    initialState,
);
