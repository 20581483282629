import * as types from '@services/stagings/actionTypes';
import { createAction } from '@utils/redux';

export const loadStagingsRequest = createAction(types.LOAD_STAGINGS_REQUEST);
export const loadStagingsSuccess = createAction(types.LOAD_STAGINGS_SUCCESS);
export const loadStagingsFailure = createAction(types.LOAD_STAGINGS_FAILURE);

export const updateStagingsRequest = createAction(types.UPDATE_STAGINGS_REQUEST);
export const updateStagingsSuccess = createAction(types.UPDATE_STAGINGS_SUCCESS);
export const updateStagingsFailure = createAction(types.UPDATE_STAGINGS_FAILURE);
