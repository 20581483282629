import { getItems, getItem, newItem, deleteItem } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';

export function getPrograms(params) {
    return getItems(routes.programs, params);
}

export function getProgram({ id, ...params }) {
    return getItem(routes.programs, id, params);
}

export function getProgramSession(id) {
    return getItem(routes.programSessions, id);
}

export function getProgramSessions(params) {
    return getItems(routes.programSessions, params);
}

export function getUri(params) {
    return getItems(routes.uris, params);
}

export function addParticipant(data) {
    return newItem(routes.programParticipants, data);
}

export function deleteParticipant({ id }) {
    return deleteItem(routes.programParticipants, id);
}

export function getUserSessions(params) {
    return getItems(routes.getUserSessions, params);
}
