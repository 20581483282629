import { indicatorTypes, initialState, getAction, isRequest, isResponse, setState } from '@utils/indicator';

const indicatorReducer = (state = initialState, action) => {
    const { type } = action;
    const callAction = getAction(type);

    if (!indicatorTypes[callAction]) {
        return state;
    }

    const requestAction = isRequest(callAction, type);
    const responseAction = isResponse(callAction, type);
    const indicatorValue = requestAction || false;

    return requestAction || responseAction ? setState(state, type, indicatorTypes[callAction], indicatorValue) : state;
};

export default indicatorReducer;
