import * as types from '@services/challenges/actionTypes';
import { createAction } from '@utils/redux';
import { LOAD_USER_CP_FAILURE, LOAD_USER_CP_REQUEST, LOAD_USER_CP_SUCCESS } from '@services/challenges/actionTypes';

export const loadChallengesRequest = createAction(types.LOAD_CHALLENGES_REQUEST);
export const loadChallengesSuccess = createAction(types.LOAD_CHALLENGES_SUCCESS);
export const loadChallengesFailure = createAction(types.LOAD_CHALLENGES_FAILURE);

export const loadAvailableChallengesRequest = createAction(types.LOAD_AVAILABLE_CHALLENGES_REQUEST);
export const loadAvailableChallengesSuccess = createAction(types.LOAD_AVAILABLE_CHALLENGES_SUCCESS);
export const loadAvailableChallengesFailure = createAction(types.LOAD_AVAILABLE_CHALLENGES_FAILURE);

export const loadPastChallengesRequest = createAction(types.LOAD_PAST_CHALLENGES_REQUEST);
export const loadPastChallengesSuccess = createAction(types.LOAD_PAST_CHALLENGES_SUCCESS);
export const loadPastChallengesFailure = createAction(types.LOAD_PAST_CHALLENGES_FAILURE);

export const joinChallengeRequest = createAction(types.JOIN_CHALLENGE_REQUEST);
export const joinChallengeSuccess = createAction(types.JOIN_CHALLENGE_SUCCESS);
export const joinChallengeFailure = createAction(types.JOIN_CHALLENGE_FAILURE);

export const setOpenedChallenge = createAction(types.SET_OPENED_CHALLENGE);
export const setTeamIdRequest = createAction(types.SET_TEAM_ID);
export const cleanChallengeDetailPage = createAction(types.CLEAN_CHALLENGE_DETAIL_PAGE);

export const loadChallengeDetailRequest = createAction(types.LOAD_CHALLENGE_DETAIL_REQUEST);
export const loadChallengeDetailSuccess = createAction(types.LOAD_CHALLENGE_DETAIL_SUCCESS);
export const loadChallengeDetailFailure = createAction(types.LOAD_CHALLENGE_DETAIL_FAILURE);
