import { createAction as createDefaultAction } from 'redux-actions';
import { dissoc, identity, ifElse, propOr, compose, equals, type } from 'ramda';

const META_PROP = 'meta';

const defaultPayloadCreator = ifElse(compose(equals('Object'), type), dissoc(META_PROP), identity);
const defaultMetaCreator = propOr({}, META_PROP);

export const createAction = (type, payloadCreator = defaultPayloadCreator, metaCreator = defaultMetaCreator) =>
    createDefaultAction(type, payloadCreator, metaCreator);

export const handleListLoad = (state, action) => {
    const routeList = state[action?.meta?.route] || { items: [] };

    return action?.meta?.route
        ? {
              ...state,
              [action.meta.route]: {
                  ...state[action.meta.route],
                  ...action.payload,
                  items: routeList.items.concat(action.payload.items),
                  nextLink: action.payload.nextLink,
              },
          }
        : { ...state, list: action.payload };
};

export const deduplicateArray = arr =>
    arr.filter((value, index, array) => array.findIndex(i => i.id === value.id) === index);
