import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useField } from 'formik';
import useMeasure from '@hooks/useMeasure';
import UnitSelect from '@components/ui/Form/Select/Custom/UnitSelect';
import { IMPERIAL } from '@constants';
import {
    InputBlock,
    InputWrapper,
    Label,
    EndIconWrapper,
    IconWrapper,
    Message,
    Container,
    getDefaultStyles,
    HintBlock,
    OptionalLabel,
} from '@components/ui/Form/styled';
import { getItem } from '@utils/storageUtils';

export const InputElement = styled.input(
    ({ theme, value, capitalize, inputMeta = {} }) => `
	color: ${theme.color.textPrimary};
	text-transform: ${capitalize ? 'capitalize' : 'none'};
	${getDefaultStyles(value, theme, inputMeta)}
`,
);

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
`;

const ButtonBlock = styled.div(
    ({ theme }) => `
    & button {
        height: auto;
        margin-top: ${theme.spacing.xs}px;
    }
`,
);
const MESSAGE_HEIGHT_LIMIT = 20;
const isRefTooHigh = height => height > MESSAGE_HEIGHT_LIMIT;

const ErrorMessage = ({ forwardRef, touched, error, apiError, id }) => (
    <div ref={forwardRef}>
        {apiError && !(touched && error) && (
            <Message id={id} aria-live="assertive" aria-relevant="additions removals">
                {apiError}
            </Message>
        )}
        {touched && error && (
            <Message id={id} aria-live="assertive" aria-relevant="additions removals">
                {error}
            </Message>
        )}
    </div>
);
const HintMessage = ({ forwardRef, error, hint, id }) => (
    <div ref={forwardRef}>
        {hint && !error && (
            <HintBlock id={id}>
                <FormattedMessage id={hint} />
            </HintBlock>
        )}
    </div>
);
const Input = props => {
    const {
        required,
        icon,
        endIcon,
        endIconAction,
        children,
        label,
        name,
        button = null,
        forwardRef,
        hint,
        className,
        capitalize,
        options,
        endSelect,
        initialValue,
        placeholder,
        setValues,
        values,
        apiError,
        autofocus,
        hideErrorMessage,
        ...inputProps
    } = props;
    const [field, meta] = useField(props);
    const [focused, setFocused] = useState(false);
    const { onChange, onBlur, value } = field;
    const { error, touched } = meta;
    const intl = useIntl();
    const inputMeta = { error: (error || inputProps.error) && touched, disabled: props.disabled };
    const messageRef = useRef(null);
    const hintRef = useRef(null);
    const { height: messageHeight } = useMeasure(messageRef);
    const { height: hintHeight } = useMeasure(hintRef);
    const helperTextId = `${name}-helperText`;
    const labelId = `${name}-label`;
    const needExtraSpace = !!button || isRefTooHigh(messageHeight) || isRefTooHigh(hintHeight);
    const isInvalid = Boolean(error || inputProps.error);
    const unitSystem = getItem('SYSTEM');

    const handleBlur = useCallback(
        e => {
            setFocused(false);
            onBlur(e);
            if (props.onBlur) {
                props.onBlur(e);
            }
        },
        [value, error],
    );

    const handleFocus = useCallback(
        e => {
            setFocused(true);
            if (props.onFocus) {
                props.onFocus(e);
            }
        },
        [value, error],
    );

    const handleUnitSelectChange = system => {
        const [object, objectProperty] = name.split('.');
        if (value === '') {
            return setValues({ ...values, [object]: { ...values[object], unitSystem: system.value } });
        }
        const convertedValue =
            system.value === IMPERIAL ? parseFloat(value) / system.factor : parseFloat(value) * system.factor;
        const updatedValues = {
            ...values,
            [object]: {
                ...values[object],
                [objectProperty]: convertedValue.toFixed(1).toString(),
                unitSystem: system.value,
            },
        };
        return setValues(updatedValues);
    };

    return (
        <Container $needExtraSpace={needExtraSpace} className={className}>
            <InputWrapper focused={focused}>
                {icon && <IconWrapper>{icon}</IconWrapper>}
                <InputBlock $value={value}>
                    {label && (
                        <Label id={labelId} htmlFor={name}>
                            <FormattedMessage id={label} />
                        </Label>
                    )}
                    <InputContainer onFocus={handleFocus}>
                        <InputElement
                            {...inputProps}
                            ref={forwardRef}
                            name={name}
                            capitalize={capitalize}
                            id={name}
                            onChange={props.onChange || onChange}
                            onBlur={handleBlur}
                            value={inputProps.value || value}
                            inputMeta={inputMeta}
                            onFocus={handleFocus}
                            aria-labelledby={labelId}
                            aria-describedby={helperTextId}
                            aria-required={required}
                            aria-invalid={isInvalid}
                            endSelect={endSelect}
                            placeholder={placeholder}
                            autoFocus={autofocus}
                        />
                        {endSelect && (
                            <UnitSelect
                                options={options}
                                initialValue={unitSystem || 'metric'}
                                name={name}
                                error={error || inputProps.error}
                                handleChange={data => handleUnitSelectChange(data, setValues)}
                            />
                        )}
                    </InputContainer>
                    {endIcon && <EndIconWrapper onClick={endIconAction}>{endIcon}</EndIconWrapper>}
                    {button && <ButtonBlock>{button}</ButtonBlock>}
                    <HintMessage id={helperTextId} forwardRef={hintRef} error={error} hint={hint} />
                    {!required && (
                        <OptionalLabel>
                            <FormattedMessage id="OPTIONAL" />
                        </OptionalLabel>
                    )}
                    {!hideErrorMessage && (
                        <ErrorMessage
                            id={helperTextId}
                            forwardRef={messageRef}
                            touched={touched}
                            error={error || inputProps.error}
                            apiError={apiError}
                        />
                    )}
                </InputBlock>
                {children}
            </InputWrapper>
        </Container>
    );
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string.isRequired,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Input.defaultProps = {
    type: 'text',
};

export default Input;
