import { call, put, takeLatest } from 'redux-saga/effects';
import {
    addParticipant,
    deleteParticipant,
    getProgram,
    getPrograms,
    getProgramSession,
    getProgramSessions,
    getUserSessions,
} from '@services/programs/api';
import * as actions from '@services/programs/actions';
import { addErrorNotification } from '@services/notifications/actions';

export function* loadProgramsWorker({ payload, meta }) {
    try {
        const programs = yield call(getPrograms, payload);
        yield put(actions.loadProgramsSuccess({ ...programs.data, meta }));
    } catch (e) {
        yield put(actions.loadProgramsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadPastProgramsWorker({ payload, meta }) {
    try {
        const programs = yield call(getPrograms, payload);
        yield put(actions.loadPastProgramsSuccess({ ...programs.data, meta }));
    } catch (e) {
        yield put(actions.loadPastProgramsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadProgramWorker({ payload, meta }) {
    try {
        const { data } = yield call(getProgram, payload);
        yield put(actions.loadProgramSuccess({ data, meta }));
    } catch (e) {
        yield put(actions.loadProgramFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadProgramSessionWorker({ payload, meta }) {
    try {
        const { data } = yield call(getProgramSession, payload);
        yield put(actions.loadProgramSessionSuccess({ data, meta }));
    } catch (e) {
        yield put(actions.loadProgramSessionFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadProgramSessionsWorker({ payload, meta }) {
    try {
        const sessions = yield call(getProgramSessions, payload);
        yield put(actions.loadProgramSessionsSuccess({ ...sessions.data, meta }));
    } catch (e) {
        yield put(actions.loadProgramSessionsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadPartnerContentWorker({ payload, meta }) {
    try {
        const programs = yield call(getPrograms, payload);
        yield put(actions.loadPartnerContentSuccess({ ...programs.data, meta }));
    } catch (e) {
        yield put(actions.loadPartnerContentFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* joinSessionWorker({ payload, meta }) {
    try {
        const data = yield call(addParticipant, payload);
        yield put(actions.joinSessionSuccess({ ...data.data, meta }));
    } catch (e) {
        yield put(actions.joinSessionFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* leaveSessionWorker({ payload, meta }) {
    try {
        const data = yield call(deleteParticipant, payload);
        yield put(actions.leaveSessionSuccess({ ...data.data, meta }));
    } catch (e) {
        yield put(actions.leaveSessionFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* getUserSessionsWorker({ payload, meta }) {
    try {
        const data = yield call(getUserSessions, payload);
        yield put(actions.loadUserSessionsSuccess({ ...data.data, meta }));
    } catch (e) {
        yield put(actions.loadUserSessionsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* getPartnerProgramWorker({ payload }) {
    try {
        const data = yield call(getProgram, payload);
        yield put(actions.loadPartnerProgramSuccess({ ...data }));
    } catch (e) {
        yield put(actions.loadPartnerProgramFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadProgramsRequest, loadProgramsWorker);
    yield takeLatest(actions.loadPastProgramsRequest, loadPastProgramsWorker);
    yield takeLatest(actions.loadProgramRequest, loadProgramWorker);
    yield takeLatest(actions.joinSessionRequest, joinSessionWorker);
    yield takeLatest(actions.leaveSessionRequest, leaveSessionWorker);
    yield takeLatest(actions.loadProgramSessionRequest, loadProgramSessionWorker);
    yield takeLatest(actions.loadProgramSessionsRequest, loadProgramSessionsWorker);
    yield takeLatest(actions.loadPartnerContentRequest, loadPartnerContentWorker);
    yield takeLatest(actions.loadUserSessionsRequest, getUserSessionsWorker);
    yield takeLatest(actions.loadPartnerProgramRequest, getPartnerProgramWorker);
}
