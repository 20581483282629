import styled from 'styled-components';
import React from 'react';
import { useField } from 'formik';

export const Label = styled.label(
    ({ theme }) => `
	padding: 9px 12px;
	line-height: 1;
	user-select: none;
	border: 1px solid ${theme.color.primary};
	border-left: 0;
	`,
);

const RadioElement = styled.input(
    ({ theme }) => `
	position: absolute;
	left: -9999px;
	&:checked + label {
		color: ${theme.color.cardBackground};
		background: ${theme.color.primary};
	}
	&:not(:checked) + label {
		color: ${theme.color.primary};
		background: ${theme.color.cardBackground};
		cursor: pointer;
	}
`,
);

export const Card = styled.div(
    ({ theme }) => `
	${Label}:first-of-type {
		border-radius: 100px 0 0 100px;
		border-left: 1px solid ${theme.color.primary};

	}
	${Label}:last-of-type {
		border-radius: 0 100px 100px 0;
		border-left: 0;
	}
	display: flex;
`,
);

export default props => {
    const { name, variant, ...inputProps } = props;
    const [field] = useField(name);
    const { onChange, value } = field;

    return (
        <RadioElement
            {...inputProps}
            name={name}
            type="radio"
            onChange={inputProps.onChange || onChange}
            value={variant}
            checked={(inputProps.value || value) === variant}
        />
    );
};
