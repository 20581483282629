import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = {
    allAchievements: [],
    userEarnedAchievements: [],
    userUpcomingAchievements: [],
};

export default handleActions(
    {
        [types.LOAD_ACHIEVEMENTS_SUCCESS]: (state, action) => ({
            ...state,
            allAchievements: action.payload,
        }),
        [types.LOAD_ACHIEVEMENTS_EARNED_SUCCESS]: (state, action) => ({
            ...state,
            userEarnedAchievements: action.payload,
        }),
        [types.LOAD_ACHIEVEMENTS_UPCOMING_SUCCESS]: (state, action) => ({
            ...state,
            userUpcomingAchievements: action.payload,
        }),
    },
    initialState,
);
