import { combineActions, handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = {
    dashboardCells: [],
};

const successActions = combineActions(
    types.LOAD_DASHBOARD_CELLS_SUCCESS,
    types.UPDATE_DASHBOARD_CELLS_SUCCESS,
    types.DELETE_DASHBOARD_CELLS_SUCCESS,
    types.ADD_DASHBOARD_CELLS_SUCCESS,
);

export default handleActions(
    {
        [successActions]: (state, action) => ({ ...state, dashboardCells: action.payload?.filter(Boolean) || [] }),
    },
    initialState,
);
