export const LINK_NAME_SHOP = 'rewardshop';
export const LINK_NAME_WELLBEING = 'wellbeing_link';

export const MESSAGES_CATEGORY_TODAY = 'today';
export const MESSAGES_CATEGORY_SOCIAL = 'social';
export const MESSAGES_ORDER_BEFORE = 9999999999999;

export const PROGRAM_TYPE_EVENT = 'event';
export const PROGRAM_TYPE_PARTNER_CONTENT = 'partnercontent';
export const PROGRAM_STATUS_DISMISSED = 'dismissed';

export const RELATION_STATUSES = {
    ACCEPTED: 'accepted',
    PENDING: 'pending',
    REJECTED: 'rejected',
};

export const STAGING_STATUSES = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
};

export const STIPULATION_STATUS_MANDATORY = 'mandatory';
