import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SUPPORTED_POLICIES } from '@utils/password';
import Input from '../Input';
import { PasswordPolicyContainer, StyledInput } from './styled';

const buildHintList = policy => {
    const { formatMessage } = useIntl();
    const conditions = SUPPORTED_POLICIES.filter(conditionId => Object.keys(policy).includes(conditionId))
        .filter(conditionId => policy[conditionId] > 0)
        .map(conditionId => (
            <li key={conditionId}>
                <FormattedMessage id={conditionId} values={{ count: policy[conditionId] }} />
            </li>
        ));

    const secondaryConditions = SUPPORTED_POLICIES.filter(conditionId => Object.keys(policy).includes(conditionId))
        .filter(conditionId => policy[conditionId] < 0)
        .map(conditionId => formatMessage({ id: conditionId }, { count: Math.abs(policy[conditionId]) }))
        .join(` ${formatMessage({ id: 'OR' })} `);

    if (secondaryConditions) {
        conditions.push(<li key="secondary">{secondaryConditions}</li>);
    }

    return <PasswordPolicyContainer>{conditions}</PasswordPolicyContainer>;
};

const PasswordInput = ({ policy, ...props }) =>
    policy && Object.keys(policy).length ? (
        <>
            <StyledInput {...props} hideErrorMessage />
            {buildHintList(policy)}
        </>
    ) : (
        <Input {...props} />
    );

export default PasswordInput;
