export const CREATE_USER_RELATION_REQUEST = 'CREATE_USER_RELATION_REQUEST';
export const CREATE_USER_RELATION_SUCCESS = 'CREATE_USER_RELATION_SUCCESS';
export const CREATE_USER_RELATION_FAILURE = 'CREATE_USER_RELATION_FAILURE';

export const UPDATE_USER_RELATION_REQUEST = 'UPDATE_USER_RELATION_REQUEST';
export const UPDATE_USER_RELATION_SUCCESS = 'UPDATE_USER_RELATION_SUCCESS';
export const UPDATE_USER_RELATION_FAILURE = 'UPDATE_USER_RELATION_FAILURE';

export const LOAD_USER_FRIENDS_COUNTS_REQUEST = 'LOAD_USER_FRIENDS_COUNTS_REQUEST';
export const LOAD_USER_FRIENDS_COUNTS_SUCCESS = 'LOAD_USER_FRIENDS_COUNTS_SUCCESS';
export const LOAD_USER_FRIENDS_COUNTS_FAILURE = 'LOAD_USER_FRIENDS_COUNTS_FAILURE';

export const LOAD_USER_FRIENDS_REQUEST = 'LOAD_USER_FRIENDS_REQUEST';
export const LOAD_USER_FRIENDS_SUCCESS = 'LOAD_USER_FRIENDS_SUCCESS';
export const LOAD_USER_FRIENDS_FAILURE = 'LOAD_USER_FRIENDS_FAILURE';

export const LOAD_PENDING_FRIENDS_REQUEST = 'LOAD_PENDING_FRIENDS_REQUEST';
export const LOAD_PENDING_FRIENDS_SUCCESS = 'LOAD_PENDING_FRIENDS_SUCCESS';
export const LOAD_PENDING_FRIENDS_FAILURE = 'LOAD_PENDING_FRIENDS_FAILURE';

export const SET_LOADING_ITEM_ID_REQUEST = 'SET_LOADING_ITEM_ID_REQUEST';
