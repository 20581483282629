import metricUnits from '@utils/units/metricUnits';
import imperialUSUnits from '@utils/units/imperialUSUnits';
import imperialUKUnits from '@utils/units/imperialUKUnits';

const UNITS = {
    ...metricUnits,
    ...imperialUSUnits,
    ...imperialUKUnits,
};

export default {
    isMetric: unit => !!metricUnits[unit],
    isImperialUS: unit => !!imperialUSUnits[unit],
    isImperialUK: unit => !!imperialUKUnits[unit],

    convert: (value, fromUnit, toUnit) => {
        const from = UNITS[fromUnit];
        const conversionFunc = from && from[toUnit];

        if (!conversionFunc) {
            if (fromUnit !== toUnit) {
                // eslint-disable-next-line no-console
                console.warn('No conversion pair:', fromUnit, toUnit);
            }

            return value;
        }

        return typeof value === 'string' ? conversionFunc(parseFloat(value)) : conversionFunc(value);
    },
};
