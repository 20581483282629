import routes from '@constants/apiRoutes';
import { getUserId } from '@utils/user';
import { editItem, newItem, getItems } from 'src/common/api/basic';

export function addUserRelation(data) {
    return newItem(routes.relations, data);
}

export function updateUserRelation(data) {
    const id = getUserId();

    return editItem(routes.relations, data, id);
}

export function getUserRelation(data) {
    return getItems(routes.relations, data);
}

export function getUserFriends(data) {
    return getItems(routes.users, data);
}
