import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
    deleteUserCP,
    getChallengeParticipants,
    getUserChallengeParticipant,
} from '@services/challengeParticipants/api';
import * as actions from '@services/challengeParticipants/actions';
import { loadChallengeDetailRequest } from '@services/challenges/actions';
import { challengeSelector } from '@services/challenges/selectors';
import { addErrorNotification, addSuccessNotification } from '@services/notifications/actions';

export function* loadChallengeParticipantsWorker({ payload }) {
    try {
        const challengeParticipants = yield call(getChallengeParticipants, payload);
        yield put(actions.loadChallengeParticipantsSuccess(challengeParticipants.data));
    } catch (e) {
        yield put(actions.loadChallengeParticipantsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadMoreChallengeParticipantWorker({ payload }) {
    try {
        const challengeParticipants = yield call(getChallengeParticipants, payload);
        yield put(actions.loadMoreChallengeParticipantSuccess(challengeParticipants.data));
    } catch (e) {
        yield put(actions.loadMoreChallengeParticipantFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadUserCPWorker({ payload }) {
    try {
        const challengeParticipant = yield call(getUserChallengeParticipant, payload);
        yield put(actions.loadUserCPSuccess(challengeParticipant.data));
    } catch (e) {
        yield put(actions.loadUserCPFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* deleteUserCPWorker({ payload }) {
    try {
        yield call(deleteUserCP, payload);
        yield put(actions.deleteUserCPSuccess());
        const detailedChallenge = yield select(challengeSelector);
        if (detailedChallenge !== null) {
            yield put(loadChallengeDetailRequest({ id: detailedChallenge.id, showJoinTime: true }));
        }
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.deleteUserCPFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadChallengeParticipantsRequest, loadChallengeParticipantsWorker);
    yield takeLatest(actions.loadMoreChallengeParticipantRequest, loadMoreChallengeParticipantWorker);
    yield takeLatest(actions.loadUserCPRequest, loadUserCPWorker);
    yield takeLatest(actions.deleteUserCPRequest, deleteUserCPWorker);
}
