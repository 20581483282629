import { handleActions } from 'redux-actions';
import { add, subtract } from 'ramda';
import * as tagTypes from '@services/tags/actionTypes';
import { MOVE } from '@constants';
import * as types from './actionTypes';

export const initialState = { details: null };

const updateMoveLike = (action, move) => {
    const { deleted } = action.payload;
    const mathMethod = deleted ? subtract : add;

    return {
        ...move,
        tagId: !deleted && action.payload.id,
        likeCount: mathMethod(move.likeCount, 1),
        lastLikeUser: action.payload.lastLikeUser || {},
    };
};

export default handleActions(
    {
        [types.LOAD_ACTIVITY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.LOAD_ACTIVITY_FAILURE]: state => ({
            ...state,
            details: null,
        }),
        [tagTypes.CREATE_TAGS_SUCCESS]: (state, action) => {
            if (action.meta.type === MOVE) {
                return {
                    ...state,
                    details: updateMoveLike(action, state.details),
                };
            }
            return state;
        },
    },
    initialState,
);
