import React from 'react';
import { SelectIcon } from '@components/ui/Form/Select/styled';
import {
    PlaceholderComponent,
    MenuListComponent,
    MenuComponent,
    OptionComponent,
    ControlUnderlineComponent,
    InputValueContainerComponent,
    InputIndicatorContainerElement,
    InputSingleValueComponent,
    StyledIcon,
} from './styled';

const Control = ({ children, innerProps, innerRef, selectProps }) => (
    <ControlUnderlineComponent
        ref={innerRef}
        {...innerProps}
        value={selectProps.value}
        disabled={selectProps.isDisabled}
    >
        {children}
    </ControlUnderlineComponent>
);

const Option = ({ children, innerProps, innerRef, isFocused, selectProps }) => {
    const { icon, value } = selectProps;
    const displayIcon = icon && value.label === children;

    return (
        <OptionComponent ref={innerRef} {...innerProps} isFocused={isFocused} icon={icon}>
            {children}
            {displayIcon && <StyledIcon id={icon} />}
        </OptionComponent>
    );
};

const MenuList = ({ children, innerProps, innerRef }) => (
    <MenuListComponent role="listbox" ref={innerRef} {...innerProps}>
        {children}
    </MenuListComponent>
);

const Menu = props => <MenuComponent {...props} />;

const IndicatorSeparator = () => <></>;

const IndicatorsContainer = ({ innerProps, innerRef, selectProps }) => (
    <InputIndicatorContainerElement ref={innerRef} {...innerProps} role="button" aria-label={selectProps['aria-label']}>
        <SelectIcon
            id={selectProps.menuIsOpen ? 'chevron_up' : 'chevron_down'}
            $disabled={selectProps.isDisabled}
            role="button"
            aria-label={selectProps['aria-label']}
        />
    </InputIndicatorContainerElement>
);
const ValueContainer = ({ innerProps, innerRef, children, selectProps }) => (
    <InputValueContainerComponent ref={innerRef} {...innerProps} disabled={selectProps.isDisabled}>
        {children}
    </InputValueContainerComponent>
);
const Placeholder = ({ innerProps, innerRef, children }) => (
    <PlaceholderComponent ref={innerRef} {...innerProps}>
        {children}
    </PlaceholderComponent>
);

const SingleValue = ({ innerProps, innerRef, children, selectProps }) => (
    <InputSingleValueComponent ref={innerRef} {...innerProps} disabled={selectProps.isDisabled}>
        {children}
    </InputSingleValueComponent>
);

export default {
    IndicatorSeparator,
    ValueContainer,
    Control,
    IndicatorsContainer,
    Placeholder,
    Option,
    MenuList,
    Menu,
    SingleValue,
};
