const files = require.context('../../assets/icons', true);

export const iconMap = files.keys().reduce((images, path) => {
    const fileName = path.substring(path.lastIndexOf('/') + 1);
    const key = fileName.substring(0, fileName.lastIndexOf('.'));

    return {
        ...images,
        [key]: {
            component: files(path).ReactComponent,
            path: files(path).default || files(path),
        },
    };
}, {});

export const FONT_ICON_RULE = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{6}$/;

export const achievementIcons = {
    polo: '0xe800',
    powerplate: '0xe801',
    power_yoga: '0xe802',
    racquetball: '0xe803',
    riding: '0xe804',
    roller_skiing: '0xe805',
    rowing: '0xe806',
    rowing_machine: '0xe807',
    rugby: '0xe808',
    running: '0xe809',
    sailing: '0xe80a',
    scuba_diving: '0xe80b',
    skiing_crosscountry: '0xe80c',
    soccer: '0xe80d',
    spinning: '0xe80e',
    squash: '0xe80f',
    step: '0xe810',
    stepper: '0xe811',
    stretching: '0xe812',
    surfing: '0xe813',
    back_stroke: '0xe814',
    breast_stroke: '0xe815',
    crawl: '0xe816',
    butterfly: '0xe817',
    freestyle: '0xe818',
    swimming: '0xe819',
    trx: '0xe81a',
    vipr: '0xe81b',
    volleyball: '0xe81c',
    wakeboarding: '0xe81d',
    walking: '0xe81e',
    water_aerobics: '0xe81f',
    water_polo: '0xe820',
    water_skiing: '0xe821',
    weight_lifting: '0xe822',
    windsurfing: '0xe823',
    yoga: '0xe824',
    zumba: '0xe825',
    baseball: '0xe826',
    basketball: '0xe827',
    beach_volleyball: '0xe828',
    bikram_yoga: '0xe829',
    body_attack: '0xe82a',
    body_balance: '0xe82b',
    body_combat: '0xe82c',
    body_jam: '0xe82d',
    body_pump: '0xe82e',
    body_step: '0xe82f',
    body_toning: '0xe830',
    body_vive: '0xe831',
    boxing: '0xe832',
    curling: '0xe833',
    cx_worx: '0xe834',
    cycling: '0xe835',
    fencing: '0xe836',
    field_hockey: '0xe837',
    fitness_class: '0xe838',
    gardening: '0xe839',
    general_martial_arts: '0xe83a',
    general_workout_session: '0xe83b',
    golf: '0xe83c',
    gym: '0xe83d',
    handball: '0xe83e',
    hip_hop: '0xe83f',
    karate: '0xe840',
    kayaking: '0xe841',
    kendo: '0xe842',
    kettlebells: '0xe843',
    kickboxing: '0xe844',
    kitesurfing: '0xe845',
    kung_fu: '0xe846',
    legs_bums_tums: '0xe847',
    martial_arts_bestehend_karate: '0xe848',
    meditation: '0xe849',
    mountain_biking: '0xe84a',
    mountain_hiking: '0xe84b',
    muay_thai_boxing: '0xe84c',
    abdominal_training: '0xe84d',
    aerobics: '0xe84e',
    aikido: '0xe84f',
    all_over_body_workout: '0xe850',
    american_football: '0xe851',
    badminton: '0xe852',
    ballet: '0xe853',
    boxing_fit: '0xe854',
    brazilian_jiu_jitsu: '0xe855',
    cardio_dance: '0xe856',
    circuit_training: '0xe857',
    climbing: '0xe858',
    cricket: '0xe859',
    cross_trainer: '0xe85a',
    hockey: '0xe85b',
    iceskating: '0xe85c',
    indoor_cardio_machine: '0xe85d',
    indoor_cycling: '0xe85e',
    inline_skating: '0xe85f',
    jiu_jitsu: '0xe860',
    judo: '0xe861',
    'netball-1': '0xe862',
    nordic_walking: '0xe863',
    nutrition_tracker: '0xe864',
    orienteering: '0xe865',
    paddle_boarding: '0xe866',
    paddle_boating: '0xe867',
    pilates: '0xe868',
    skiing_downhill: '0xe869',
    skipping: '0xe86a',
    skitouring: '0xe86b',
    sledging: '0xe86c',
    snowboarding: '0xe86d',
    snow_mountaineering: '0xe86e',
    snow_shoe_walking: '0xe86f',
    tabletennis: '0xe870',
    taekwondo: '0xe871',
    tai_chi: '0xe872',
    tennis: '0xe873',
    trackandfield: '0xe874',
    trampoline: '0xe875',
    treadmill: '0xe876',
    crossfit: '0xe877',
    formation_dance: '0xe878',
    salsa: '0xe879',
    pixformance: '0xe87a',
    'netball-2': '0xe87b',
    around_the_clock: '0xe87c',
    active_stickyness: '0xe87d',
    border_crosser: '0xe87e',
    polar_circle: '0xe87f',
    training_day: '0xe880',
    friends: '0xe881',
    intercontinental: '0xe882',
    anytime_anywhere: '0xe883',
    multisport: '0xe884',
    fitness_duration: '0xe885',
    walking_night: '0xe886',
    running_night: '0xe887',
    running_velocity: '0xe888',
    cycling_ascent: '0xe889',
    cycling_velocity: '0xe88a',
    mountainbiking_ascent: '0xe88b',
    skiing_downhill_descent: '0xe88c',
    'indoor_cardio_machine-mastery': '0xe88d',
    rowing_velocity: '0xe88e',
    qhs_stable_500: '0xe88f',
    qhs_stable_750: '0xe890',
    qhs_dailysteps_count: '0xe891',
    stability: '0xe892',
    everybody_likes_gadgets: '0xe893',
    early_adopters_1year: '0xe894',
    early_adopters_2years: '0xe895',
    early_adopters_3years: '0xe896',
    early_adopters_4years: '0xe897',
    early_adopters_5years: '0xe898',
    early_adopters_6years: '0xe899',
    early_adopters_7years: '0xe89a',
    happy_birthday: '0xe89b',
    walking_20km_1day: '0xe89c',
    walking_2_5km_5day: '0xe89d',
    finisher: '0xe89e',
    runing_50kmin_1day: '0xe89f',
    runing_5kmin_1day: '0xe8a0',
    cycling_250km_1day: '0xe8a1',
    cycling_25km_5day: '0xe8a2',
    mountain_cycling_2500m_1day: '0xe8a3',
    mountain_cycling_250m_5day: '0xe8a4',
    rollerskating_100km_1day: '0xe8a5',
    rollerskating_10km_5day: '0xe8a6',
    skiing_10km_1day: '0xe8a7',
    skiing_1km_5days: '0xe8a8',
    crosscountry_100km_1day: '0xe8a9',
    crosscountry_10km_5day: '0xe8aa',
    gym_5days: '0xe8ab',
    gym_mastery: '0xe8ac',
    fitness_classes: '0xe8ad',
    fitness_classes_5day: '0xe8ae',
    indoor_cardio_machine_5day: '0xe8af',
    spinning_class: '0xe8b0',
    rowing_50km_1day: '0xe8b1',
    rowing_5km_5day: '0xe8b2',
    tennis_5h_1day: '0xe8b3',
    tennis_1h_5day: '0xe8b4',
    squash_5h_1day: '0xe8b5',
    squash_1h_5day: '0xe8b6',
    soccer_5h_1day: '0xe8b7',
    soccer_1h_5day: '0xe8b8',
    golf_15km_1day: '0xe8b9',
    golf_5km_5day: '0xe8ba',
    vertical_hiking_5000m_1day: '0xe8bb',
    vertical_hiking_500m_5day: '0xe8bc',
    blood_values: '0xe8bd',
    blood_pressure: '0xe8be',
    nutrition: '0xe8bf',
    netball: '0xe8c0',
    touch_rugby: '0xe8c1',
    tubing: '0xe8c2',
    beverage: '0xe8c3',
    body_information: '0xe8c4',
    feelings: '0xe8c5',
    food: '0xe8c6',
    heart_blood: '0xe8c7',
    lifestyle: '0xe8c8',
    medical: '0xe8c9',
    'running-1': '0xe8ca',
    sleep: '0xe8cb',
    steps: '0xe8cc',
    stress: '0xe8cd',
    goal: '0xe8ce',
    achievements: '0xe8cf',
    challenges: '0xe8d0',
    heartrate: '0xe8d1',
    missingyou: '0xe8d2',
    onboarding: '0xe8d3',
    seasonal: '0xe8d4',
    social: '0xe8d5',
    subscription: '0xe8d6',
    'calories-o': '0xe8d7',
    'distance-o': '0xe8d8',
    'elevation-o': '0xe8d9',
    weight: '0xe8da',
    'conversation-o': '0xe8db',
    'user-o': '0xe8dc',
    'achievement-o': '0xe8dd',
    'security-o': '0xe8de',
    navigation: '0xe8df',
    weather: '0xe8e0',
    mobile: '0xe8e1',
    wheelchair_basketball: '0xe8e2',
    track: '0xe8e3',
    wheeling: '0xe8e4',
    handcycle: '0xe8e5',
    heart_blood_outline: '0xe8e8',
    lifestyle_outline: '0xe8ea',
    heartrate_outline: '0xe8eb',
    food_outline: '0xe8ed',
    fishing: '0xe8ee',
    feelings_outline: '0xe8ef',
    wheelchair_tabeltennis: '0xe8f0',
    stretching_outline: '0xe8f1',
    blood_pressure_outline: '0xe8f2',
    ebike: '0xe8f3',
    body_information_outline: '0xe8f4',
    sleep_outline: '0xe8f6',
    steps_outline: '0xe8f9',
    weight_outline: '0xe8fc',
};
