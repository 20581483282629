import styled from 'styled-components';
import { lighten } from 'polished';
import { Icon } from '@components/ui';

export const List = styled.div(
    ({ theme, opened }) => `
	z-index: 1;
	width: 100%;
	min-width: 240px;
	position: absolute;
	top: 80%;
	right: -33%;
	display:  ${opened ? 'block' : 'none'};
	transition: 0.3s ease-out;
	max-height: 0;
	margin: 0;
	padding: 0;
	background-color: ${theme.color.navBg};
	border-radius: 8px;
	max-height: ${opened ? '2000px' : ''};
	transition: ${opened ? '0.3s ease-out' : ''};
	box-shadow: 0px 0px 32px -6px ${theme.color.lightGrey};

	${theme.media.mdToLg`
		right: -25%;
  	`}

	& button {
		background-color: ${theme.color.selectOptionBackground};

		border: 0;
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 2px solid ${theme.color.lightGrey};
		padding: 14px;
		cursor: pointer;
		text-decoration: none;
		opacity: 1;
		color: ${theme.color.textPrimary};
		font-size: 16px;
		font-family: ${theme.font.bold};

		&:last-child {
			border-bottom: none;
		}

		&:first-of-type {
			&:after {
				content: '';
				position: absolute;
				top: -8px;
				right: 23px;
				width: 0px;
				height: 0px;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 9px solid ${theme.color.cardBackground};
			}
			&:hover {
				&:after {
					border-bottom: 9px solid
						${theme.color.selectOptionBackgroundHover};
				}
			}
		}

		&:hover {
			background-color: ${theme.color.selectOptionBackgroundHover};
		}

		& a {
			display: block;
			width: 100%;
			text-decoration: none;
			opacity: 1;
			color: ${theme.color.textPrimary};
			font-size: 14px;
			font-family: ${theme.font.bold};
		}

		& p {
			margin: 0;
		}
	}
`,
);

export const Wrapper = styled.div(
    ({ theme, opened }) => `
	& {
		display: flex;
		justify-content: flex-end;
		position: relative;
		align-items: center;
		overflow: ${opened ? '' : 'hidden'};

		& img {
			box-sizing: content-box;
			border-radius: 50%;
			border: 3px solid ${lighten(0.3, theme.color.primary)};
		}
	}
`,
);

export const ToggleIcon = styled(Icon)(
    ({ theme, opened }) => `
	height: 18px;
	width: 18px;
	margin-left: 14px;
	transition: 0.3s ease-out;
	cursor: pointer;
	transform: ${opened ? 'rotateX(180deg)' : ''};
	path {
		stroke: ${theme.color.primary}
	}
`,
);
