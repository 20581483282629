import React from 'react';
import styled from 'styled-components';
import { IndicatorContainerElement, SelectIcon } from '@components/ui/Form/Select/styled';
import {
    PlaceholderComponent,
    MenuListComponent,
    MenuComponent,
    OptionComponent,
    ControlComponent,
    ValueContainerComponent,
    SingleValueComponent,
    SelectContainerComponent,
} from './styled';

const Control = ({ children, innerProps, innerRef, selectProps, menuIsOpen }) => (
    <ControlComponent
        ref={innerRef}
        {...innerProps}
        role="combobox"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-controls={`${selectProps.name}-listbox`}
        aria-expanded={menuIsOpen}
        aria-labelledby={selectProps['aria-labelledby']}
    >
        {children}
    </ControlComponent>
);

const Option = ({ children, innerProps, innerRef, isFocused, selectProps, value }) => (
    <OptionComponent
        ref={innerRef}
        {...innerProps}
        isFocused={isFocused}
        role="option"
        aria-selected={isFocused}
        tabIndex="0"
        id={`${selectProps.name}.option.${value}`}
    >
        {children}
    </OptionComponent>
);

const MenuList = ({ children, innerProps, innerRef, selectProps }) => (
    <MenuListComponent
        ref={innerRef}
        {...innerProps}
        role="listbox"
        tabIndex="-1"
        id={`${selectProps.name}-listbox`}
        aria-labelledby={selectProps['aria-labelledby']}
    >
        {children}
    </MenuListComponent>
);

const Menu = props => <MenuComponent {...props} />;

const HiddenMenu = styled(Menu)`
    display: none;
`;

const IndicatorSeparator = () => <></>;

const IndicatorsContainer = ({ innerProps, innerRef, selectProps }) => (
    <IndicatorContainerElement ref={innerRef} {...innerProps} aria-hidden="true" tabIndex="-1">
        <SelectIcon
            role="button"
            id={selectProps.menuIsOpen ? 'chevron_up' : 'chevron_down'}
            $disabled={selectProps.isDisabled}
            aria-label={selectProps['aria-label']}
            aria-hidden="true"
            tabIndex="-1"
        />
    </IndicatorContainerElement>
);

const hideAriaForListedChildren = (children = [], listedChildTypes = []) =>
    React.Children.map(children, child => {
        if (React.isValidElement(child) && listedChildTypes.includes(child?.type?.name)) {
            return React.cloneElement(child, { tabIndex: '-1', 'aria-hidden': true });
        }
        return child;
    });

const ValueContainer = ({ innerProps, innerRef, children }) => (
    <ValueContainerComponent ref={innerRef} {...innerProps}>
        {hideAriaForListedChildren(children, ['DummyInput', 'Input', 'L'])}
    </ValueContainerComponent>
);

const Placeholder = ({ innerProps, innerRef, children }) => (
    <PlaceholderComponent ref={innerRef} {...innerProps}>
        {children}
    </PlaceholderComponent>
);

const SingleValue = ({ innerProps, innerRef, children }) => (
    <SingleValueComponent ref={innerRef} {...innerProps}>
        {children}
    </SingleValueComponent>
);

const filterUnwantedAccessibilityElements = children =>
    children?.filter(child => !['A11yText', 'E'].includes(child?.type?.name));

const SelectContainer = ({ children, ...props }) => (
    <SelectContainerComponent {...props}>
        {filterUnwantedAccessibilityElements(children)}
        {!props.menuIsOpen && (
            <HiddenMenu {...props}>
                <MenuListComponent
                    role="listbox"
                    tabIndex="-1"
                    id={`${props.selectProps.name}-listbox`}
                    aria-labelledby={props.selectProps['aria-labelledby']}
                >
                    {props.options?.map(option => (
                        <OptionComponent
                            role="option"
                            aria-selected={props.selectProps.value.value === option.value}
                            id={`${props.selectProps.name}.option.${option.value}`}
                        >
                            {option.label}
                        </OptionComponent>
                    ))}
                </MenuListComponent>
            </HiddenMenu>
        )}
    </SelectContainerComponent>
);

export default {
    IndicatorSeparator,
    ValueContainer,
    Control,
    IndicatorsContainer,
    Placeholder,
    Option,
    MenuList,
    Menu,
    SingleValue,
    SelectContainer,
};
