import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { configSelector } from '@services/auth/selectors';
import { formatMessage } from '@utils/intl';
import Play from '@icons/play.svg';
import { Area, Body, IllustrationBox, IllustrationCircles, IntroVideo, Title, VideoIcon, VideoLink } from './styled';

const Page = ({ title, body, illustration, skipCircles }) => {
    const config = useSelector(configSelector);
    const showIntroVideo = config?.features?.welcome?.showIntroVideo;

    return (
        <Area>
            <IllustrationBox>
                {illustration}
                {skipCircles ? null : <IllustrationCircles />}
            </IllustrationBox>
            <Title>
                <FormattedMessage id={title} />
            </Title>
            <Body>
                <FormattedMessage id={body} />
            </Body>

            {showIntroVideo && (
                <IntroVideo>
                    <VideoLink href={formatMessage('INTRO_VIDEO_URL')} target="_blank" rel="noopener">
                        <FormattedMessage id="WATCH_INTRO_VIDEO" />
                    </VideoLink>
                    <VideoIcon href={formatMessage('INTRO_VIDEO_URL')} target="_blank" rel="noopener">
                        <img src={Play} alt="play" />
                    </VideoIcon>
                </IntroVideo>
            )}
        </Area>
    );
};

export default Page;
