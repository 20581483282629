import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { selectLanguage } from '@services/language/selectors';
import enTranslationMessages from 'src/translations/en-US/index.json';

export const getTranslationMessages = async locale => import(`@translations/${locale}/index.json`);

export function LanguageProvider({ children }) {
    const [messages, setMessages] = useState(enTranslationMessages);
    const locale = useSelector(selectLanguage);

    useEffect(() => {
        getTranslationMessages(locale)
            .catch(() => {
                setMessages(messages);
            })
            .then(module => (module ? setMessages(module.default) : setMessages(messages)));
    }, [locale]);

    return (
        <IntlProvider locale={locale} key={locale} messages={messages}>
            {React.Children.only(children)}
        </IntlProvider>
    );
}

LanguageProvider.propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.object,
    children: PropTypes.element.isRequired,
};

export default LanguageProvider;
