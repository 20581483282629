import React from 'react';
import { FormattedMessage } from 'react-intl';
import { handleActions } from 'redux-actions';
import { setMomentLocale } from '@utils/date';
import { removeItem, setItem } from '@utils/storageUtils';
import { BLOOD_VALUES, MGDL, ONBOARDING } from '@constants';
import * as types from './actionTypes';

const passwordError = <FormattedMessage id="PASSWORD_IS_INCORRECT" />;

export const initialState = {};

export default handleActions(
    {
        [types.LOAD_USER_SUCCESS]: (state, action) => {
            const user = action.payload;
            setMomentLocale(user.language);

            return {
                ...state,
                ...action.payload,
            };
        },
        [types.UPDATE_USER_SUCCESS]: (state, action) => {
            const user = action.payload;
            setMomentLocale(user.language);

            return {
                ...state,
                ...action.payload.user,
            };
        },
        [types.UPDATE_ONBOARDING_SUCCESS]: (state, action) => {
            setItem([BLOOD_VALUES], [MGDL]);
            removeItem(ONBOARDING);

            return {
                ...state,
                ...action.payload.user,
            };
        },
        [types.UPDATE_USER_PICTURE_SUCCESS]: (state, action) => ({
            ...state,
            ...action.payload.user,
        }),
        [types.DELETE_USER_AVATAR_SUCCESS]: (state, action) => ({
            ...state,
            ...action.payload.user,
        }),
        [types.DELETE_USER_FAILURE]: state => ({
            ...state,
            password: {
                error: passwordError,
            },
        }),
        [types.CLEAR_ERROR_FIELD]: (state, action) => ({
            ...state,
        }),
        [types.UPDATE_USER_TERMS_SUCCESS]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [types.UPDATE_USER_SUBSCRIPTION_SUCCESS]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [types.UPDATE_USER_PHONE_NUMBER_SUCCESS]: state => ({
            ...state,
            smsCodeWasSent: true,
        }),
        [types.ENABLE_SMS_CODE_RESEND]: state => ({
            ...state,
            smsCodeWasSent: false,
        }),
    },
    initialState,
);
