import { call, put, takeLatest, select } from 'redux-saga/effects';
import { addSuccessNotification, addErrorNotification } from '@services/notifications/actions';
import { getUserId } from '@utils/user';
import * as actions from './actions';
import { getChallenge, getChallenges, joinChallenge } from './api';
import { challengeSelector } from './selectors';

export function* loadChallengesWorker({ payload, meta }) {
    try {
        const challenges = yield call(getChallenges, payload);
        yield put(actions.loadChallengesSuccess({ ...challenges.data, meta }));
    } catch (e) {
        yield put(actions.loadChallengesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadAvailableChallengesWorker({ payload, meta }) {
    const params = { ...payload, mode: 'available' };
    try {
        const challenges = yield call(getChallenges, params);
        yield put(actions.loadAvailableChallengesSuccess({ ...challenges.data, meta }));
    } catch (e) {
        yield put(actions.loadAvailableChallengesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadPastChallengesWorker({ payload, meta }) {
    try {
        const challenges = yield call(getChallenges, { ...payload, mode: 'past' });
        yield put(actions.loadPastChallengesSuccess({ ...challenges.data, meta }));
    } catch (e) {
        yield put(actions.loadPastChallengesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* joinChallengeWorker({ payload }) {
    try {
        const challenges = yield call(joinChallenge, { ...payload });
        yield put(actions.joinChallengeSuccess({ ...challenges.data }));
        const detailedChallenge = yield select(challengeSelector);
        if (detailedChallenge !== null) {
            yield put(actions.loadChallengeDetailRequest({ id: detailedChallenge.id, showJoinTime: true }));
        }
        const userId = getUserId();
        yield put(actions.loadAvailableChallengesRequest({ userId }));
        yield put(actions.loadChallengesRequest({ userId, isParticipant: true, mode: 'available' }));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.joinChallengeFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadChallengeDetailWorker({ payload }) {
    try {
        const { data: challenge } = yield call(getChallenge, payload);
        yield put(actions.loadChallengeDetailSuccess(challenge));
    } catch (e) {
        yield put(actions.loadChallengeDetailFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadChallengesRequest, loadChallengesWorker);
    yield takeLatest(actions.loadAvailableChallengesRequest, loadAvailableChallengesWorker);
    yield takeLatest(actions.loadPastChallengesRequest, loadPastChallengesWorker);
    yield takeLatest(actions.joinChallengeRequest, joinChallengeWorker);
    yield takeLatest(actions.loadChallengeDetailRequest, loadChallengeDetailWorker);
}
