const brandColors = {
    primary: '#5C61AC',
    primarySelect: '#2C2D8B',
    disabled: '#D0D5ED',
    secondary: '#55C2B6',
    secondarySelect: '#40A494',
    tertiary: '#EBEEFF',
    welcomeCircles: '#FFFFFF',
};

const systemColors = {
    notification: '#E03E52',
    validation: '#96C919',
    lightValidation: '#cbe58d',

    // colors below are not from specification so they might will change
    // AVOID TO USE IT
    white: '#FFFFFF',
};

const wheelColors = {
    nutritionWheel: '#FFDE65',
    indulgencesWheel: '#ACF48C',
    movementWheel: '#4EF3EE',
    mindfulnessWheel: '#C68DEE',
    sleepWheel: '#84D0FC',
    feelingsWheel: '#56FBD1',
    bodyWheel: '#56FBD1',
};

const mapping = {
    navBg: 'primary',
    link: 'textContrast',
    linkHover: 'textContrast',
    burgerColor: 'textContrast',
    welcomeGalleryTextColor: 'textContrast',
    welcomeGalleryArrows: 'textContrast',
    welcomeGalleryBullet: 'disabled',
    welcomeGalleryActiveBullet: 'textContrast',
    welcomeNav: 'textContrast',
    signUpButtonBgColor: 'secondary',
    signUpButtonHoverBgColor: 'secondarySelect',
    toggleBar: 'lightValidation',
    toggleBullet: 'validation',
};

export default {
    colors: {
        ...brandColors,
        ...systemColors,
        ...wheelColors,
    },
    mapping,
};
