import { createAction } from '@utils/redux';
import * as types from '@services/stipulations/actionTypes';

export const loadUserStipulationsRequest = createAction(types.LOAD_USER_STIPULATIONS_REQUEST);
export const loadUserStipulationsSuccess = createAction(types.LOAD_USER_STIPULATIONS_SUCCESS);
export const loadUserStipulationsFailure = createAction(types.LOAD_USER_STIPULATIONS_FAILURE);

export const loadTermsOfUseCheckRequest = createAction(types.LOAD_TERMS_OF_USE_CHECK_REQUEST);
export const loadTermsOfUseCheckSuccess = createAction(types.LOAD_TERMS_OF_USE_CHECK_SUCCESS);
export const loadTermsOfUseCheckFailure = createAction(types.LOAD_TERMS_OF_USE_CHECK_FAILURE);
