import partner from './partner';

const colors = {
    primary: '#018943',
    primarySelect: '#006D34',
    disabled: '#A6E0C2',
    secondary: '#4E7E65',
    secondarySelect: '#3E6350',
    tertiary: '#CCEDDC',
};

export const green = {
    colors,
    mapping: {},
    partner,
};
