import Modal, { CloseButton, ModalComponent } from '@components/Modal';
import styled from 'styled-components';
import { Icon, Loader, Typography } from '@components/ui';
import { TextButton } from '@components/ui/Form/Button';

export const Title = styled(Typography)`
    font-size: 32px;
    margin: 24px 0 8px 0;
`;

export const Description = styled(Typography)`
    margin-bottom: 30px;
`;

export const Container = styled.div`
    padding: 0 50px;
`;

export const ButtonContainer = styled.div`
    text-align: right;
    position: absolute;
    right: 0;
`;

export const ErrorDialog = styled(Modal)`
    padding: 30px;
    text-align: center;
    & > ${ModalComponent} {
        width: 420px;
        height: 320px;
    }

    & > ${CloseButton} {
        display: none;
    }
`;

export const LocationIcon = styled(Icon)`
    margin-bottom: 15px;
    width: 80px;
    height: 80px;
    stroke: none;
`;

export const ActionButton = styled(TextButton)(
    ({ theme }) => `
	width: 50%;
	border-top: 1px solid ${theme.color.lightGrey};
	display: block;
	height: 100%;
`,
);

export const Actions = styled.div(
    ({ theme }) => `
	position: absolute;
	left: 0;
	right: 0;
	bottom: -20px;
	display: flex;
	justify-content: space-between;
	height: 46px;
	background: ${theme.color.cardBackground};
	border-radius: 6px;
	& ${ActionButton}:first-of-type {
		border-right: 1px solid ${theme.color.lightGrey};
	}
`,
);

export const ConfirmButtonContainer = styled.div`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
`;

export const FindLoader = styled(Loader)`
    width: auto;
`;

export const FindLocationIcon = styled(Icon).attrs({ id: 'ic_locationpin_m' })(
    ({ theme }) => `
    width: ${theme.iconSize.md}px;
    height: ${theme.iconSize.md}px;
    path {
    	fill: ${theme.color.primary};
    }
`,
);
