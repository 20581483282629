import { getItems, editItem } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';

export function getAgreements(params) {
    return getItems(routes.agreements, { ...params });
}

export function updateAgreement(data) {
    return editItem(routes.agreements, data, data.id);
}
