import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import units from '@utils/units';
import Icon from '@components/ui/Icon';
import { metrics } from '@constants';
import Input, { InputElement } from './index';

const UnitSelect = styled.select(
    ({ theme }) => `
	position: relative;
	border: 0;
	outline: 0;
	 -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    margin-left: 10px;
    background: ${theme.color.cardBackground};
	color: ${theme.color.primary};
	font-size: 18px;
	margin-top: 17px;
	font-family: ${theme.font.bold};
	background: transparent;
`,
);

const ExtraInput = styled(InputElement)`
    margin-left: 20px;
    margin-right: 5px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const EndIcon = styled(Icon)(
    ({ theme }) => `
	path {
		stroke: ${theme.color.primary}
	}
	position: absolute;
	right: 0;
	bottom: 3px;
	pointer-events: none;
`,
);

class UnitInput extends PureComponent {
    state = {
        selectedUnit: '',
        firstInput: '',
        secondInput: '',
        steps: {},
        limits: {},
    };

    componentDidMount() {
        const { unitType, initValue } = this.props;
        const { variants, limits } = metrics[unitType];
        const steps = variants.reduce((acc, { value, step }) => {
            acc[value] = step;

            return acc;
        }, {});

        this.setState(
            {
                selectedUnit: variants[0].value,
                steps,
                limits,
            },
            () => {
                // input must be set after limits and unit
                const [firstInput, secondInput] = this.convertUnits(initValue || '');

                this.setState({
                    firstInput,
                    secondInput,
                });
            },
        );
    }

    hasExtraInput = () => {
        const { selectedUnit } = this.state;

        return selectedUnit === 'ft/in' || selectedUnit === 'st lb';
    };

    splitUnit = unit => unit.split(/[ /]+/);

    onUnitSelect = e => {
        const { value } = this.props;

        this.setState(
            {
                selectedUnit: e.target.value,
            },
            () => {
                const [firstInput, secondInput] = this.convertUnits(value);

                this.setState({
                    firstInput,
                    secondInput,
                });
            },
        );
    };

    applyUnits = () => {
        const { setFieldValue, name } = this.props;
        const { limits } = this.state;

        let value = this.unitsToMetricValue();

        if (value) {
            value = Math.min(limits.max, Math.max(limits.min, value));

            const [firstInput, secondInput] = this.convertUnits(value);

            this.setState({
                firstInput,
                secondInput,
            });

            setFieldValue(name, value);
        }
    };

    onFirstInputChange = e => {
        const { value } = e.target;

        this.setState(
            {
                firstInput: value,
            },
            this.applyUnits,
        );
    };

    onSecondInputChange = e => {
        const { value } = e.target;

        this.setState(
            {
                secondInput: value,
            },
            this.applyUnits,
        );
    };

    onInputBlur = e => {
        const { onBlur } = this.props;

        this.applyUnits();

        if (onBlur) {
            onBlur(e);
        }
    };

    createUnitSelect = unitType => {
        const { intl } = this.props;
        const units = metrics[unitType].variants;

        return (
            <Wrapper>
                <UnitSelect onChange={this.onUnitSelect}>
                    {units.map(({ label, value }) => (
                        <option value={value} key={value}>
                            {intl.formatMessage({
                                id: label,
                                defaultMessage: label,
                            })}
                        </option>
                    ))}
                </UnitSelect>
                <EndIcon id="chevron_down" />
            </Wrapper>
        );
    };

    getFractionLength = value => {
        const strValue = value.toString();
        const dotIndex = strValue.indexOf('.');

        return dotIndex >= 0 ? strValue.length - dotIndex - 1 : 0;
    };

    convertUnits = stringValue => {
        const { unitType } = this.props;
        const metric = metrics[unitType].variants[0].value;
        const { selectedUnit, steps } = this.state;
        const value = Number.parseFloat(stringValue);

        let secondInput = 0;

        if (!Number.isFinite(value)) {
            return ['', ''];
        }

        const [firstUnit, secondUnit] = this.splitUnit(selectedUnit);
        let firstInput = units.convert(value, metric, firstUnit);

        if (this.hasExtraInput()) {
            const flooredFirst = Math.floor(firstInput);

            secondInput = units.convert(firstInput - flooredFirst, firstUnit, secondUnit);
            firstInput = flooredFirst;
        }

        return [
            firstInput.toFixed(this.getFractionLength(steps[selectedUnit])),
            secondInput.toFixed(this.getFractionLength(steps[selectedUnit])),
        ];
    };

    unitsToMetricValue = () => {
        const { unitType } = this.props;
        const { firstInput, secondInput, selectedUnit } = this.state;
        const metric = metrics[unitType].variants[0].value;
        const [firstUnit, secondUnit] = this.splitUnit(selectedUnit);

        const first = units.convert(firstInput, firstUnit, metric);

        if (this.hasExtraInput()) {
            return first + units.convert(secondInput, secondUnit, metric);
        }

        return first;
    };

    render() {
        const { unitType, ...inputProps } = this.props;
        const { steps, selectedUnit, firstInput, secondInput } = this.state;

        return (
            <Input
                {...inputProps}
                type="number"
                step={steps[selectedUnit]}
                value={firstInput}
                onChange={this.onFirstInputChange}
                onBlur={this.onInputBlur}
            >
                {this.hasExtraInput() && (
                    <ExtraInput
                        id="ExtraInput"
                        name="ExtraInput"
                        min={0}
                        step={steps[selectedUnit]}
                        onChange={this.onSecondInputChange}
                        type="number"
                        value={secondInput}
                    />
                )}
                {this.createUnitSelect(unitType)}
            </Input>
        );
    }
}

export default injectIntl(UnitInput);
