import { get } from 'src/common/api/request';
import routes from '@constants/apiRoutes';

const route = routes.services;

export function loadTrackers(params) {
    const defaultParams = {
        categories: 'tracker',
        ...params,
    };

    return get(route, null, defaultParams);
}
