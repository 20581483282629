import { DEFAULT_LOCALE } from '@constants/locale';

export const getUserLocale = (supportedLocales = []) => {
    const browserLocale = navigator.language || '';
    const localeIsSupported = supportedLocales.some(locale => locale === browserLocale);
    if (localeIsSupported) {
        return browserLocale;
    }

    const browserLanguage = browserLocale.split('-')[0];
    if (!browserLanguage) {
        return DEFAULT_LOCALE;
    }

    return supportedLocales.find(locale => locale.startsWith(browserLanguage)) || DEFAULT_LOCALE;
};
