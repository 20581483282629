import { createSelector } from 'reselect';
import { getItem } from '@utils/storageUtils';
import { LOCALE } from '@constants';
import { initialState } from './reducer';

const selectLanguage = state => getItem(LOCALE) || state.language.locale || initialState.locale;

const makeSelectLocale = () => createSelector(selectLanguage, languageState => languageState.locale);

export { selectLanguage, makeSelectLocale };
