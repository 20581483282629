import React from 'react';
import styled from 'styled-components';

const Button = styled.button(
    ({ theme }) => `
    width: 35px;
    min-height: 35px;
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background: ${theme.color.cardBackground};
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`,
);

export default props => {
    const { children, onClick, className } = props;

    return (
        <Button onClick={onClick} className={className} {...props}>
            {children}
        </Button>
    );
};
