import { editItem, getItem, newItem, getItems, deleteItem, uploadItem } from 'src/common/api/basic';
import { post } from 'src/common/api/request';
import routes from '@constants/apiRoutes';

export function getUser(id) {
    return getItem(routes.users, id);
}

export function signUp(data) {
    return newItem(routes.users, data);
}

export function updateUser(data) {
    return editItem(routes.users, data, data.id);
}

export function deleteUser(data) {
    return deleteItem(routes.users, data.id, data);
}

export function getUsers(data) {
    return getItems(routes.users, data);
}

export function updateUserAvatar({ id, file, type }) {
    return uploadItem(routes.avatar(id), file, type);
}

export function updateUserTerms(data) {
    return editItem(routes.updatePrivacy, data);
}

export function updateUserSubscription(data) {
    return editItem(routes.updateSubscription, data);
}

export function updatePhone(data) {
    return post(`${routes.auth}/update-phone`, data);
}

export function confirmPhoneNumber(data) {
    return post(`${routes.auth}/confirm-phone`, data);
}
