import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getArticles } from './api';

export function* loadArticlesWorker({ meta, payload }) {
    try {
        const { data } = yield call(getArticles, payload);
        yield put(actions.loadArticlesSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.loadArticlesFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadArticlesRequest, loadArticlesWorker);
}
