import { handleActions } from 'redux-actions';
import * as types from './actionTypes';
import * as userTypes from '../user/actionTypes';

export const initialState = {
    items: [],
    stipulationsToAgree: [],
};

export default handleActions(
    {
        [types.LOAD_USER_STIPULATIONS_SUCCESS]: (state, action) => ({
            ...state,
            items: action.payload.items,
        }),
        [types.LOAD_TERMS_OF_USE_CHECK_SUCCESS]: (state, action) => ({
            ...state,
            stipulationsToAgree: action.payload.data,
        }),
        [userTypes.UPDATE_USER_TERMS_SUCCESS]: (state, action) => ({
            ...state,
            stipulationsToAgree: [],
        }),
    },
    initialState,
);
