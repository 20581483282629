import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotification } from '@services/notifications/actions';
import { formatMessage } from '@utils/intl';
import { generateRandomString } from '@utils';
import { FormattedMessage } from 'react-intl';
import { CloseButton, CloseIcon, MessageBox, MessageText, InfoIcon } from './styled';

let displayed = [];
const UNAUTHORIZED = 'Unauthorized user';

const getMessage = (status, message) => {
    const errorByCode = {
        402: <FormattedMessage id="INVALID_CREDENTIALS" />,
    };

    return errorByCode[status] || message;
};

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications.items || []);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = id => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = id => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({ key, message, status, options = {}, dismissed = false, type }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            if (displayed.includes(key)) {
                // do nothing if snackbar is already displayed
                return;
            }

            if (message === UNAUTHORIZED && status === 402) {
                return;
            }

            const params = {
                ...options,
                key: key || generateRandomString(),
                action: key => (
                    <CloseButton onClick={() => closeSnackbar(key)} aria-label={formatMessage('common_close')}>
                        <CloseIcon id="ic_closecircle_m" />
                    </CloseButton>
                ),
            };

            const notifierMessage = (
                <MessageBox>
                    <InfoIcon id="ic_info_circlefilled_s" variant={type} />
                    <MessageText variant="body">{getMessage(status, message)}</MessageText>
                </MessageBox>
            );

            // display snackbar using notistack
            enqueueSnackbar(notifierMessage, {
                ...params,
                variant: type,
                onClose: (e, reason, key) => {
                    if (options.onClose) {
                        options.onClose(e, reason, key);
                    }
                },
                onExited: (e, key) => {
                    // remove this snackbar from redux store
                    dispatch(removeNotification({ key }));
                    removeDisplayed(key);
                },
            });
            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
