import React from 'react';
import { FormattedMessage } from 'react-intl';
import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

const emailError = <FormattedMessage id="NEW_EMAIL_ERROR" />;

export const initialState = {
    items: [],
    value: '',
    counts: 0,
    nextLink: null,
    checkEmail: {
        error: null,
    },
};

export default handleActions(
    {
        [types.LOAD_SEARCH_ITEMS_SUCCESS]: (state, action) => ({
            ...state,
            items: [...state.items, ...action.payload.items],
            nextLink: action.payload.nextLink,
            counts: action.payload.counts,
        }),
        [types.CLEAR_SEARCH_ITEMS_REQUEST]: state => ({
            ...state,
            items: [],
            value: '',
            counts: 0,
            nextLink: null,
        }),
        [types.STORE_SEARCH_VALUE]: (state, action) => ({
            ...state,
            value: action.meta.value,
        }),
        [types.LOAD_CHECK_EMAIL_SUCCESS]: (state, action) => ({
            ...state,
            checkEmail: {
                error: action.payload.items.length ? emailError : null,
            },
        }),
    },
    initialState,
);
