import React, { useState } from 'react';
import SelectElement from 'react-select';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useField } from 'formik';
import { find, map, propEq, __ } from 'ramda';
import PropTypes from 'prop-types';
import { IconWrapper, Container, OptionalLabel } from '@components/ui/Form/styled';
import components from './components';
import inputComponents from './inputStyleComponents';
import { Label, SelectBlock, SelectWrapper } from './styled';

export const findProp = propEq('value', __);
export const noneOption = { label: 'common_none', value: 'none' };

const Select = props => {
    const {
        options,
        intl,
        name,
        label,
        icon,
        iconPrefix,
        inputStyle,
        placeholder,
        skipTranslate,
        inline,
        isSearchable = false,
        ...restProps
    } = props;
    const [field, meta, helpers] = useField(name);
    const { setValue, setTouched } = helpers;
    const { value } = field;
    const { error, touched } = meta;

    const translateOption = option => ({
        ...option,
        label: intl.formatMessage({ id: option.label, defaultMessage: option.label }),
    });

    const selectOptions = skipTranslate ? options : map(translateOption, options);
    const optionFinder = findProp(props.value || value);
    const findOption = find(optionFinder);
    const labelId = `${name}-label`;
    const selectedValue = selectOptions ? findOption(selectOptions) : '';
    const selectedOption = selectedValue || (inputStyle ? '' : translateOption(noneOption));

    const onChange = selectedOption => {
        const updatedValue = selectedOption.value;
        setValue(updatedValue);

        if (props.onChange) {
            props.onChange(selectedOption);
        }
    };

    const onBlur = () => setTouched(true);

    return (
        <Container $noBottomMargin={inline}>
            <SelectWrapper>
                {iconPrefix && <IconWrapper>{iconPrefix}</IconWrapper>}
                <SelectBlock
                    $value={touched || selectedOption}
                    inline={inline}
                    input={inputStyle}
                    onFocus={() => setTouched(true)}
                    onBlur={() => !selectedOption && setTouched(false)}
                >
                    {label && (
                        <Label id={labelId} htmlFor={name} $disabled={props.isDisabled}>
                            <FormattedMessage id={label} />
                        </Label>
                    )}
                    <SelectElement
                        aria-labelledby={name}
                        components={inputStyle ? inputComponents : components}
                        placeholder={placeholder || ''}
                        label={label}
                        name={name}
                        onBlur={onBlur}
                        options={selectOptions}
                        touched={touched}
                        error={error}
                        {...restProps}
                        onChange={onChange}
                        value={selectedOption}
                        icon={icon}
                        isSearchable={isSearchable}
                    />
                    {!restProps.required && (
                        <OptionalLabel>
                            <FormattedMessage id="OPTIONAL" />
                        </OptionalLabel>
                    )}
                </SelectBlock>
            </SelectWrapper>
        </Container>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array.isRequired,
    autoFocus: PropTypes.bool,
    backspaceRemovesValue: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    closeMenuOnScroll: PropTypes.bool,
    delimiter: PropTypes.string,
    filterOption: PropTypes.func,
    hideSelectedOptions: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOptionDisabled: PropTypes.func,
    isOptionSelected: PropTypes.func,
    isMulti: PropTypes.bool,
    isRtl: PropTypes.bool,
    isSearchable: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
};

export default injectIntl(Select);
