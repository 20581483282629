export const LOAD_ACHIEVEMENTS_REQUEST = 'LOAD_ACHIEVEMENTS_REQUEST';
export const LOAD_ACHIEVEMENTS_SUCCESS = 'LOAD_ACHIEVEMENTS_SUCCESS';
export const LOAD_ACHIEVEMENTS_FAILURE = 'LOAD_ACHIEVEMENTS_FAILURE';

export const LOAD_ACHIEVEMENTS_EARNED_REQUEST = 'LOAD_ACHIEVEMENTS_EARNED_REQUEST';
export const LOAD_ACHIEVEMENTS_EARNED_SUCCESS = 'LOAD_ACHIEVEMENTS_EARNED_SUCCESS';
export const LOAD_ACHIEVEMENTS_EARNED_FAILURE = 'LOAD_ACHIEVEMENTS_EARNED_FAILURE';

export const LOAD_ACHIEVEMENTS_UPCOMING_REQUEST = 'LOAD_ACHIEVEMENTS_UPCOMING_REQUEST';
export const LOAD_ACHIEVEMENTS_UPCOMING_SUCCESS = 'LOAD_ACHIEVEMENTS_UPCOMING_SUCCESS';
export const LOAD_ACHIEVEMENTS_UPCOMING_FAILURE = 'LOAD_ACHIEVEMENTS_UPCOMING_FAILURE';
