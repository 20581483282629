import { call, put, takeLatest } from 'redux-saga/effects';
import { addSuccessNotification, addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { createTag } from './api';

export function* createTagWorker({ payload, meta }) {
    try {
        const tag = yield call(createTag, payload);
        yield put(actions.createTagSuccess({ ...tag.data, meta }));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.createTagFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.createTagRequest, createTagWorker);
}
