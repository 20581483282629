import { call, put, takeLatest } from 'redux-saga/effects';
import { getEntities } from '@services/entities/api';
import * as actions from '@services/entities/actions';

export function* getEntitiesWorker({ payload }) {
    try {
        const { data } = yield call(getEntities, payload);
        yield put(actions.loadEntitiesSuccess({ data }));
    } catch (e) {
        yield put(actions.loadEntitiesFailure(e.message));
    }
}

export function* getEntityChildrenWorker({ payload }) {
    try {
        const { data } = yield call(getEntities, payload);
        yield put(actions.loadEntityCountrySuccess({ data }));
    } catch (e) {
        yield put(actions.loadEntityCountryFailure(e.message));
    }
}

export function* getEntityDepartmentsWorker({ payload }) {
    try {
        const { data } = yield call(getEntities, payload);
        yield put(actions.loadEntityDepartmentsSuccess({ data }));
    } catch (e) {
        yield put(actions.loadEntityDepartmentsFailure(e.message));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadEntitiesRequest, getEntitiesWorker);
    yield takeLatest(actions.loadEntityCountryRequest, getEntityChildrenWorker);
    yield takeLatest(actions.loadEntityDepartmentsRequest, getEntityDepartmentsWorker);
}
