import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { configSelector } from '@services/auth/selectors';

let loadedPageTitle = '';

const useMatomoScript = title => {
    const config = useSelector(configSelector);

    useEffect(() => {
        const { _paq = [] } = window;
        if (Object.keys(config).length > 0 && loadedPageTitle !== title && process.env.NODE_ENV !== 'development') {
            const { matomo } = config;

            if (matomo.disabled) {
                return;
            }

            const { siteId, url } = matomo;
            _paq.push(['disableCookies']);
            _paq.push(['setDocumentTitle', title]);
            _paq.push(['setCustomUrl', window.location.href.toLowerCase()]);
            _paq.push(['enableLinkTracking']);
            _paq.push(['setTrackerUrl', `${url}matomo.php`]);
            _paq.push(['setSiteId', siteId]);
            _paq.push(['trackPageView']);
            loadedPageTitle = title;
        }
    }, [config]);
};

export default useMatomoScript;
