import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import LanguageProvider from 'src/common/containers/LanguageProvider';
import * as serviceWorker from './serviceWorker';
import App from './common/containers/App';
import store, { history } from './store';

import 'focus-visible/src/focus-visible';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'assets/fonts/index.css';

const MOUNT_NODE = document.getElementById('root');

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <LanguageProvider>
                    <App />
                </LanguageProvider>
            </ConnectedRouter>
        </Provider>,
        MOUNT_NODE,
    );
};

if (module.hot) {
    module.hot.accept(['./common/containers/App'], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
    });
}

render();

serviceWorker.register();
