import { createAction } from '@utils/redux';
import * as types from './actionTypes';

export const loadAchievementsRequest = createAction(types.LOAD_ACHIEVEMENTS_REQUEST);
export const loadAchievementsSuccess = createAction(types.LOAD_ACHIEVEMENTS_SUCCESS);
export const loadAchievementsFailure = createAction(types.LOAD_ACHIEVEMENTS_FAILURE);

export const loadUserAchievementsRequest = createAction(types.LOAD_ACHIEVEMENTS_EARNED_REQUEST);
export const loadUserAchievementsSuccess = createAction(types.LOAD_ACHIEVEMENTS_EARNED_SUCCESS);
export const loadUserAchievementsFailure = createAction(types.LOAD_ACHIEVEMENTS_EARNED_FAILURE);

export const loadUpcomingUserAchievementsRequest = createAction(types.LOAD_ACHIEVEMENTS_UPCOMING_REQUEST);
export const loadUpcomingUserAchievementsSuccess = createAction(types.LOAD_ACHIEVEMENTS_UPCOMING_SUCCESS);
export const loadUpcomingUserAchievementsFailure = createAction(types.LOAD_ACHIEVEMENTS_UPCOMING_FAILURE);
