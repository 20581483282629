import partner from './partner';

const colors = {
    primary: '#001A4B',
    primarySelect: '#00143C',
    disabled: '#A6AFC0',
    secondary: '#005387',
    secondarySelect: '#003C61',
    tertiary: '#CCDCE7',
};

export const darkBlue = {
    colors,
    mapping: {},
    partner,
};
