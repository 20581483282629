import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { checkTermsOfUse, getStipulations } from './api';

export function* loadUserStipulationsWorker({ meta, payload }) {
    try {
        const { data } = yield call(getStipulations, payload);
        yield put(actions.loadUserStipulationsSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.loadUserStipulationsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadTermsOfUseCheckWorker({ meta, payload }) {
    try {
        const { data } = yield call(checkTermsOfUse, payload);
        yield put(actions.loadTermsOfUseCheckSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.loadTermsOfUseCheckFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadUserStipulationsRequest, loadUserStipulationsWorker);
    yield takeLatest(actions.loadTermsOfUseCheckRequest, loadTermsOfUseCheckWorker);
}
