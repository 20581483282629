import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification, addSuccessNotification } from '@services/notifications/actions';
import { push } from 'connected-react-router';
import { clientRoutes } from '@constants';
import * as actions from './actions';
import { getStagings, updateStaging } from './api';

export function* loadStagingsWorker({ payload }) {
    try {
        const data = yield call(getStagings, payload);
        yield put(actions.loadStagingsSuccess(data));
    } catch (e) {
        yield put(actions.loadStagingsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateStagingWorker({ payload }) {
    try {
        const data = yield call(updateStaging, payload);
        yield put(actions.updateStagingsSuccess(data));
        yield put(addSuccessNotification());
        yield put(push(clientRoutes.me()));
    } catch (e) {
        yield put(actions.updateStagingsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadStagingsRequest, loadStagingsWorker);
    yield takeLatest(actions.updateStagingsRequest, updateStagingWorker);
}
