import styled from 'styled-components';
import { components } from 'react-select';
import {
    INPUT_HEIGHT,
    LABEL_HIGH_FONT_SIZE,
    LABEL_HIGH_POSITION,
    LABEL_LINE_HEIGHT,
    LABEL_LOW_FONT_SIZE,
    LABEL_LOW_POSITION,
} from '@components/ui/Form/styled';
import { Icon } from '@components/ui';

const MENU_SELECTOR = '.react-select__menu';

export const MenuListComponent = styled.ul(
    ({ theme }) => `
    padding: 0;
    box-sizing: border-box;
    max-height: 300px;
    border-radius: ${theme.spacing.xs}px;
    position: relative;
    overflow-y: auto;
	::-webkit-scrollbar {
		background: ${theme.color.lightGrey};
		width: 8px;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		border-radius: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: ${theme.color.primary};
		border-radius: 4px;
	}
    &:last-child: {
		borderBottom: 'none',
	},
	${MENU_SELECTOR} {
		box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.25)};
		border-radius: ${theme.spacing.xs}px;
		white-space: nowrap;
	}
`,
);

export const MenuComponent = styled(components.Menu)`
    width: auto !important;
`;

export const OptionComponent = styled.li(
    ({ theme, isFocused, icon }) => `
		display: flex;
		justify-content: ${icon ? 'space-between' : 'center'};
		align-items: center;
		min-width: 100px;
		height: 45px;
		margin-right: 0;
		padding: 0 10px;
		cursor: default;
		background-color: ${isFocused ? theme.color.lightGrey : theme.color.cardBackground};
		color: ${theme.color.textPrimary};
		border-bottom: 1px solid ${theme.color.lightGrey};
		&:hover {
            background-color: ${theme.color.lightGrey};
        }
	`,
);

export const ControlComponent = styled.div`
    min-width: 100px;
    border: 0;
    display: flex;
`;

export const ValueContainerComponent = styled.div`
    align-items: center;
    margin-right: 20px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: flex-end;

    input {
        left: 28px;
    }
`;

export const PlaceholderComponent = styled.div(
    ({ theme }) => `
  	margin-right: ${theme.spacing.md}px;
  	color: ${theme.color.tertiaryButton};
  `,
);

export const SingleValueComponent = styled.div`
    color: ${({ theme }) => theme.color.textPrimary};
`;

export const InputSingleValueComponent = styled.div`
    color: ${({ theme, disabled }) => (disabled ? theme.color.textDisabled : theme.color.textPrimary)};
`;

export const ControlUnderlineComponent = styled.div(
    ({ theme, disabled }) => `
	    min-width: 100px;
		border-bottom: 1px solid ${disabled ? theme.color.mediumGrey : theme.color.textSecondary};
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	`,
);

export const InputValueContainerComponent = styled.div`
    align-items: flex-end;
    margin-right: 20px;
    display: contents;
    flex: 1;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    font-weight: bold;
`;

export const InputIndicatorContainerElement = styled.div`
    display: flex;
    align-items: flex-end;
    height: 39px;
`;

export const Label = styled.label(
    ({ theme, $focused, $disabled }) => `
	transition: 0.2s ease all;
	font-size: ${theme.fontSize.label2};
	font-family: ${theme.font.regular};
	color: ${theme.color.textDisabled};
	text-transform: uppercase;

	${$focused &&
        `bottom: ${LABEL_HIGH_POSITION}px;
		font-size: ${theme.fontSize.label};
		font-family: ${theme.font.bold};
		color: ${theme.color.textSecondary};
		line-height: ${LABEL_HIGH_FONT_SIZE}px;`};
	${$disabled &&
        `
		color: ${theme.color.textDisabled};
	`}
`,
);

export const SelectBlock = styled.div(
    ({ theme, $value, input, inline, iconPrefix }) => `
	position: relative;
	width: ${input ? '100%' : 'auto'};
	margin-bottom: 0;
	flex-direction: column;
	display: flex;
	${!inline &&
        `
		min-height: 60px;
		max-height: 64px;
	`}
	${!iconPrefix &&
        `
		margin-bottom: 0px;
	`}
	justify-content: flex-end;

	label {
		margin-bottom: ${$value ? 0 : `-40px`};
		font-size: ${$value ? `${LABEL_HIGH_FONT_SIZE}px` : `${LABEL_LOW_FONT_SIZE}px`};
		font-family: ${$value ? theme.font.bold : theme.font.regular};
		line-height: ${$value ? `${LABEL_HIGH_FONT_SIZE}px` : `${LABEL_LINE_HEIGHT}px`};
	}
`,
);

export const SelectWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
`;

export const SelectValueContainerComponent = styled.div(
    ({ theme }) => `
    align-items: center;
    margin-right: ${theme.spacing.xs}px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    justify-content: flex-end;
	margin-top: 26px;
	padding-left: ${theme.spacing.xs}px;
	color: ${theme.color.primary};
`,
);

export const SelectSingleValueComponent = styled.div(
    ({ theme }) => `
    color: ${({ theme }) => theme.color.primary};
	font-weight: bold;
	border-bottom: 2px solid ${theme.color.primary};
`,
);

export const SelectControlUnderlineComponent = styled.div`
    display: flex;
    height: 46px;
    width: 54px;
`;

export const SelectContainerComponent = styled(components.SelectContainer)``;

export const StyledIcon = styled(Icon)(
    ({ theme }) => `
	width: 20px;
	height: 20px;
	margin-left: 5px;
	stroke: ${theme.color.primary};
`,
);
