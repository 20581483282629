import { deleteItem, editItem, getItems, editItems } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';

export function getMessages(params) {
    return getItems(routes.messages, params);
}

export function updateMessage({ id, ...data }) {
    return editItem(routes.messages, data, id);
}

export function deleteMessage({ id }) {
    return deleteItem(routes.messages, id);
}

export function updateMessages(data) {
    return editItems(routes.messagesAction('updateNotifications'), data, null);
}
