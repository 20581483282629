export const LOAD_MESSAGES_REQUEST = 'LOAD_MESSAGES_REQUEST';
export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_FAILURE = 'LOAD_MESSAGES_FAILURE';

export const LOAD_COACH_MESSAGES_REQUEST = 'LOAD_COACH_MESSAGES_REQUEST';
export const LOAD_COACH_MESSAGES_SUCCESS = 'LOAD_COACH_MESSAGES_SUCCESS';
export const LOAD_COACH_MESSAGES_FAILURE = 'LOAD_COACH_MESSAGES_FAILURE';

export const LOAD_LAST_COACH_MESSAGES_REQUEST = 'LOAD_LAST_COACH_MESSAGES_REQUEST';
export const LOAD_LAST_COACH_MESSAGES_SUCCESS = 'LOAD_LAST_COACH_MESSAGES_SUCCESS';
export const LOAD_LAST_COACH_MESSAGES_FAILURE = 'LOAD_LAST_COACH_MESSAGES_FAILURE';

export const SKIP_MESSAGES_REQUEST = 'SKIP_MESSAGES_REQUEST';
export const SKIP_MESSAGES_SUCCESS = 'SKIP_MESSAGES_SUCCESS';
export const SKIP_MESSAGES_FAILURE = 'SKIP_MESSAGES_FAILURE';

export const ANSWER_COACH_MESSAGES_REQUEST = 'ANSWER_COACH_MESSAGES_REQUEST';
export const ANSWER_COACH_MESSAGES_SUCCESS = 'ANSWER_COACH_MESSAGES_SUCCESS';
export const ANSWER_COACH_MESSAGES_FAILURE = 'ANSWER_COACH_MESSAGES_FAILURE';

export const LOAD_COACH_COMPLETED_MESSAGES_REQUEST = 'LOAD_COACH_COMPLETED_MESSAGES_REQUEST';
export const LOAD_COACH_COMPLETED_MESSAGES_SUCCESS = 'LOAD_COACH_COMPLETED_MESSAGES_SUCCESS';
export const LOAD_COACH_COMPLETED_MESSAGES_FAILURE = 'LOAD_COACH_COMPLETED_MESSAGES_FAILURE';

export const SKIP_COACH_MESSAGE_REQUEST = 'SKIP_COACH_MESSAGE_REQUEST';
export const SKIP_COACH_MESSAGE_SUCCESS = 'SKIP_COACH_MESSAGE_SUCCESS';
export const SKIP_COACH_MESSAGE_FAILURE = 'SKIP_COACH_MESSAGE_FAILURE';

export const CLEAR_UNSEEN_NOTIFICATIONS_REQUEST = 'CLEAR_UNSEEN_NOTIFICATIONS_REQUEST';
export const CLEAR_UNSEEN_NOTIFICATIONS_SUCCESS = 'CLEAR_UNSEEN_NOTIFICATIONS_SUCCESS';
export const CLEAR_UNSEEN_NOTIFICATIONS_FAILURE = 'CLEAR_UNSEEN_NOTIFICATIONS_FAILURE';

export const LOAD_MESSAGES_COUNT_REQUEST = 'LOAD_MESSAGES_COUNT_REQUEST';
export const LOAD_MESSAGES_COUNT_SUCCESS = 'LOAD_MESSAGES_COUNT_SUCCESS';
export const LOAD_MESSAGES_COUNT_FAILURE = 'LOAD_MESSAGES_COUNT_FAILURE';

export const CANCEL_COMPLETE_MESSAGE_REQUEST = 'CANCEL_COMPLETE_MESSAGE_REQUEST';

export const PROCEED_COACH_MESSAGES_REQUEST = 'PROCEED_COACH_MESSAGES_REQUEST';
export const PROCEED_COACH_MESSAGES_SUCCESS = 'PROCEED_COACH_MESSAGES_SUCCESS';
export const PROCEED_COACH_MESSAGES_FAILURE = 'PROCEED_COACH_MESSAGES_FAILURE';

export const LOAD_SOCIAL_MESSAGES_REQUEST = 'LOAD_SOCIAL_MESSAGES_REQUEST';
export const LOAD_SOCIAL_MESSAGES_SUCCESS = 'LOAD_SOCIAL_MESSAGES_SUCCESS';
export const LOAD_SOCIAL_MESSAGES_FAILURE = 'LOAD_SOCIAL_MESSAGES_FAILURE';

export const LOAD_SOCIAL_MESSAGES_COUNTS_REQUEST = 'LOAD_SOCIAL_MESSAGES_COUNTS_REQUEST';
export const LOAD_SOCIAL_MESSAGES_COUNTS_SUCCESS = 'LOAD_SOCIAL_MESSAGES_COUNTS_SUCCESS';
export const LOAD_SOCIAL_MESSAGES_COUNTS_FAILURE = 'LOAD_SOCIAL_MESSAGES_COUNTS_FAILURE';

export const MARK_MESSAGE_SEEN_REQUEST = 'MARK_MESSAGE_SEEN_REQUEST';
export const MARK_MESSAGE_SEEN_SUCCESS = 'MARK_MESSAGE_SEEN_SUCCESS';
export const MARK_MESSAGE_SEEN_FAILURE = 'MARK_MESSAGE_SEEN_FAILURE';
