import React from 'react';
import { handleActions } from 'redux-actions';
import { head } from 'ramda';
import * as types from './actionTypes';

export const initialState = { items: [], active: null };

export default handleActions(
    {
        [types.LOAD_BRANDING_SUCCESS]: (state, action) => ({
            ...state,
            ...action.payload,
            active: head(action.payload.items),
        }),
    },
    initialState,
);
