export const LOAD_CHALLENGE_PARTICIPANTS_REQUEST = 'LOAD_CHALLENGE_PARTICIPANTS_REQUEST';
export const LOAD_CHALLENGE_PARTICIPANTS_SUCCESS = 'LOAD_CHALLENGE_PARTICIPANTS_SUCCESS';
export const LOAD_CHALLENGE_PARTICIPANTS_FAILURE = 'LOAD_CHALLENGE_PARTICIPANTS_FAILURE';

export const LOAD_MORE_CHALLENGE_PARTICIPANT_REQUEST = 'LOAD_MCP_REQUEST';
export const LOAD_MORE_CHALLENGE_PARTICIPANT_SUCCESS = 'LOAD_MCP_SUCCESS';
export const LOAD_MORE_CHALLENGE_PARTICIPANT_FAILURE = 'LOAD_MCP_FAILURE';

export const LOAD_USER_CP_REQUEST = 'LOAD_USER_CP_REQUEST';
export const LOAD_USER_CP_SUCCESS = 'LOAD_USER_CP_SUCCESS';
export const LOAD_USER_CP_FAILURE = 'LOAD_USER_CP_FAILURE';

export const DELETE_USER_CP_REQUEST = 'DELETE_USER_CP_REQUEST';
export const DELETE_USER_CP_SUCCESS = 'DELETE_USER_CP_SUCCESS';
export const DELETE_USER_CP_FAILURE = 'DELETE_USER_CP_FAILURE';
