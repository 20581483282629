import styled from 'styled-components';
import { Card } from '@components/ui';

const Dialog = styled(Card)(
    ({ theme, width, fullWidth }) => `
    width: ${width ? `${width}px` : '100%'};
    padding: ${fullWidth ? '50px 0' : '40px 40px 16px'};
    flex-direction: column;
    text-align: justify;
    text-justify: inter-word;
    ${theme.media.sm`
    	width: 100%;
    `}
`,
);

export default Dialog;
