import partner from './partner';

const colors = {
    primary: '#E7131A',
    primarySelect: '#C9171E',
    disabled: '#F9B0B3',
    secondary: '#BE4B4F',
    secondarySelect: '#993C40',
    tertiary: '#FBD2D3',
};

export const red = {
    colors,
    mapping: {},
    partner,
};
