import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getAchievements } from './api';

export function* achievementsWorker(action) {
    try {
        const { data } = yield call(getAchievements, action.payload);
        yield put(actions.loadAchievementsSuccess(data.items));
    } catch (e) {
        yield put(actions.loadAchievementsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* userAchievementsWorker(action) {
    try {
        const { data } = yield call(getAchievements, action.payload);
        yield put(actions.loadUserAchievementsSuccess(data.items));
    } catch (e) {
        yield put(actions.loadUserAchievementsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* userUpcomingAchievementsWorker(action) {
    try {
        const { data } = yield call(getAchievements, action.payload);
        yield put(actions.loadUpcomingUserAchievementsSuccess(data.items));
    } catch (e) {
        yield put(actions.loadUpcomingUserAchievementsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadAchievementsRequest, achievementsWorker);
    yield takeLatest(actions.loadUserAchievementsRequest, userAchievementsWorker);
    yield takeLatest(actions.loadUpcomingUserAchievementsRequest, userUpcomingAchievementsWorker);
}
