import { call, put, takeLatest } from 'redux-saga/effects';
import { loadConfigFailure, loadConfigRequest, loadConfigSuccess } from '@services/config/actions';
import { addErrorNotification } from '@services/notifications/actions';
import { getConfig } from '@services/config/api';

export function* configSaga(action) {
    try {
        const config = yield call(getConfig, action.payload);
        yield put(loadConfigSuccess(config.data));
    } catch (e) {
        yield put(loadConfigFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(loadConfigRequest, configSaga);
}
