import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getHealthScore } from './api';

export function* healthScoreWorker(action) {
    try {
        const { data } = yield call(getHealthScore, action.payload);
        yield put(actions.healthScoreSuccess(data.items[0]));
    } catch (e) {
        yield put(actions.healthScoreFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.healthScoreRequest, healthScoreWorker);
}
