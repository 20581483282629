import React from 'react';
import styled from 'styled-components';

const height = 16;

const Piece = styled.div`
    background: ${({ theme }) => theme.color.pageBackground};
    width: ${({ width }) => `calc(1px + ${width}%)`};
    height: ${height}px;
    position: relative;
    &::after {
        display: ${({ last }) => (last ? 'none' : 'block')};
        height: ${height / 4}px;
        width: ${height / 8}px;
        background: ${({ theme }) => theme.color.primary};
        position: absolute;
        right: 0;
        top: ${(height - height / 4) / 2}px;
        border-radius: 1px;
        content: '';
    }
`;

const ProgressBox = styled.div`
    position: absolute;
    border-radius: 0 6px 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${height}px;
    display: flex;
`;

const Index = styled.div`
    height: ${height}px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${({ width }) => `${width}%`};
    background: ${({ theme }) => theme.color.primary};
    border-radius: 0 6px 6px 0;
`;

export default ({ items, step }) => {
    const pieceLength = items.length ? 100 / items.length : 0;
    const lastIndex = items.length - 1;
    return (
        <ProgressBox>
            {items.map((item, i) => (
                <Piece key={i} width={pieceLength} last={i === lastIndex} />
            ))}
            <Index width={pieceLength * step} />
        </ProgressBox>
    );
};
