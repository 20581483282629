import { handleActions } from 'redux-actions';
import { LEADERS } from '@constants';
import { deduplicateArray } from '@utils/redux';
import * as types from './actionTypes';

export const initialState = { items: [], pushes: 0 };

export default handleActions(
    {
        [types.LOAD_USER_FRIENDS_SUCCESS]: (state, action) => ({
            ...state,
            items: deduplicateArray([...state.items, ...action.payload.items]),
        }),
        [types.LOAD_PENDING_FRIENDS_SUCCESS]: (state, action) => ({
            ...state,
            items: deduplicateArray([...state.items, ...action.payload.items]),
            pushes: action.payload.items.length,
        }),
        [types.UPDATE_USER_RELATION_SUCCESS]: (state, action) => ({
            ...state,
            items: state.items.map(user =>
                user.id === action.payload.data[action.meta.kind].id
                    ? {
                          ...user,
                          friendStatus: {
                              ...user.friendStatus,
                              status: action.payload.data.status,
                          },
                      }
                    : user,
            ),
        }),
        [types.CREATE_USER_RELATION_SUCCESS]: (state, action) => ({
            ...state,
            items: [
                ...state.items,
                {
                    ...action.meta.friend,
                    friendStatus: {
                        status: action.payload.data.status,
                        kind: LEADERS,
                        time: action.payload.data.validFrom,
                    },
                },
            ],
        }),
        [types.CREATE_USER_RELATION_FAILURE]: state => ({
            ...state,
            loadingItem: null,
        }),
        [types.SET_LOADING_ITEM_ID_REQUEST]: (state, action) => ({
            ...state,
            loadingItem: action.meta.itemId,
        }),
    },
    initialState,
);
