import * as R from 'ramda';
import { mediaTypes } from '@constants';

const MEDIA_LINK_EXCEPTIONS = ['http', '/static/media'];

export const getMediaLink = (link, type) =>
    MEDIA_LINK_EXCEPTIONS.some(exception => link?.includes(exception)) ? link : `/media/${link}?type=${type}`;

export const getMapLink = (id, locale) => `/media/map/${id}?locale=${locale}`;

export const getMediaByType = (model, type) => {
    if (!model || !model.media) return null;
    return R.compose(
        R.cond([
            [R.isNil, R.identity],
            [R.T, R.find(R.propEq('type', type))],
        ]),
        R.prop('media'),
    )(model);
};

export const getMediaIdByType = (model, type) => {
    const media = getMediaByType(model, type);
    return R.ifElse(R.isNil, R.always(null), R.prop('id'))(media);
};

export const getUserAvatarId = R.compose(R.curry(getMediaIdByType)(R.__, mediaTypes.profile));

const getRadianAngle = degreeValue => (degreeValue * Math.PI) / 180;

export const getCroppedImage = (image, crop, rotation) => {
    const canvas = document.createElement('canvas');
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - crop.x,
        0 - safeArea / 2 + image.height * 0.5 - crop.y,
    );

    return canvas.toDataURL('image/png');
};

export const setLoadedImageData = (url, setData, setLoading) => {
    setLoading(true);
    const img = new Image();
    img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(this, 0, 0);

        const dataURL = canvas.toDataURL('image/png');
        setLoading(false);
        setData({ image: img, source: dataURL });
    };

    img.onerror = function() {
        setLoading(false);
    };

    img.src = url;
};

export const getBase64FromImagePath = file => {
    if (!FileReader || !file) {
        return false;
    }

    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.readAsDataURL(file);
        } catch (e) {
            reject(e);
        }
    });
};
