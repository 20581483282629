import { createSelector } from 'reselect';

export const authSelector = state => state.auth;

export const isAuthSelector = createSelector(authSelector, auth => auth.isAuth);
export const authCodeSelector = createSelector(authSelector, auth => auth.code);
export const authCredentialsSelector = createSelector(authSelector, auth => auth.credentials);
export const authCouponCodeSelector = createSelector(authSelector, auth => auth.couponCode);
export const is2FACodeResendEnabledSelector = createSelector(authSelector, auth => auth.is2FACodeResendEnabled);
export const otpStatusSelector = createSelector(authSelector, auth => auth.otpStatus);
export const externalClientSelector = createSelector(authSelector, auth => auth.externalClient);

export const configSelector = state => state.config;
