import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NARROW_MODAL_WIDTH } from '@constants';
import { ModalContainer, ModalWrapper } from '@containers/Footer/styled';
import { modalMetaSelector } from '@services/modal/selectors';
import { ButtonBox, EmailButtonLink, ReactivationTitle, RectivationButton, TextModalBox } from './styled';

const ReactivationModal = ({ handleClose }) => {
    const { emailAddress, emailLink } = useSelector(modalMetaSelector);

    return (
        <ModalWrapper onClose={handleClose} width={NARROW_MODAL_WIDTH} label={'REACTIVATION'}>
            <ModalContainer>
                <ReactivationTitle variant="h2">
                    <FormattedMessage id="reactivation_popup_title" />
                </ReactivationTitle>
                <TextModalBox variant="body">
                    <FormattedMessage id="reactivation_popup_text" values={{ email: emailAddress }} />
                </TextModalBox>
                <ButtonBox>
                    <RectivationButton onClick={handleClose}>
                        <FormattedMessage id="OK_LABEL" />
                    </RectivationButton>
                    <RectivationButton>
                        <EmailButtonLink to={emailLink} external target="_blank">
                            <FormattedMessage id="reactivation_popup_button_label" />
                        </EmailButtonLink>
                    </RectivationButton>
                </ButtonBox>
            </ModalContainer>
        </ModalWrapper>
    );
};

export default ReactivationModal;
