import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { map } from 'ramda';
import { noneOption } from '@components/ui/Form/Select/Custom';
import { getItem, setItem } from '@utils/storageUtils';
import components from './selectStyleComponents';

const UnitSelect = props => {
    const { options, intl, name, label, type, initialValue, handleChange, setFieldValue, error, ...restProps } = props;
    const [touched, setTouched] = useState(false);
    const unitSystem = getItem('SYSTEM');
    const [selectedOption, setSelectedOption] = useState(options.filter(item => item.value === unitSystem));

    const translateOption = option => ({
        ...option,
        label: intl.formatMessage({ id: option.label, defaultMessage: option.label }),
    });
    const selectOptions = map(translateOption, options);

    const findOption = option => {
        const findOption = selectOptions.filter(item => item.value === option);
        const selectedValue = selectOptions ? findOption : '';
        setSelectedOption(selectedValue || translateOption(noneOption));
    };

    const onChange = option => {
        if (option.value === selectedOption[0].value) return;
        handleChange(option);
        setItem(['SYSTEM'], [option.value]);
        findOption(option.value);
    };

    const onBlur = () => setTouched(true);

    useEffect(() => {
        findOption(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const data = options?.find(item => item.value === unitSystem);
        if (data) onChange(data);
    }, [unitSystem]);

    return (
        <Select
            components={components}
            placeholder={''}
            onBlur={onBlur}
            options={selectOptions}
            openMenuOnFocus
            touched={touched}
            error={error}
            isSearchable={false}
            {...restProps}
            onChange={onChange}
            value={selectedOption}
        />
    );
};

export default injectIntl(UnitSelect);
