import styled from 'styled-components';

const pointSize = 16;
const containerHeight = 28;
const trackHeight = 12;

export const StyledInputRange = styled.div(
    ({ theme }) => `
	.input-range__slider {
		appearance: none;
		background: ${theme.color.primary};
		border-radius: 100%;
		cursor: pointer;
		display: block;
		height: ${pointSize}px;
		width: ${pointSize}px;
		margin-top: -${pointSize / 2}px;
		outline: none;
		position: absolute;
		top: 50%;
		transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
	}
	.input-range__slider:active {
		transform: scale(1.15);
	}
	.input-range__slider:focus {
		box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
	}
	.input-range--disabled .input-range__slider {
		background: ${theme.color.disabled};
		border: 1px solid ${theme.color.disabled};
		box-shadow: none;
		transform: none;
	}

	.input-range__slider-container {
		transition: left 0.3s ease-out;
		width: ${containerHeight}px;
		height: ${containerHeight}px;
		background: ${theme.color.cardBackground};
		border-radius: 50%;
   	 	display: flex;
    	justify-content: center;
    	box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.15);
		margin-left: -${containerHeight / 2}px;

	}

	.input-range__label {
		color: #aaaaaa;
		font-family: 'Helvetica Neue', san-serif;
		font-size: 0.8rem;
		transform: translateZ(0);
		white-space: nowrap;
	}

	.input-range__label--min,
	.input-range__label--max {
		bottom: -1.4rem;
		position: absolute;
	}

	.input-range__label--min {
		left: 0;
	}

	.input-range__label--max {
		right: 0;
	}

	.input-range__label--value {
		position: absolute;
		top: -1.8rem;
	}

	.input-range__label-container {
		left: -50%;
		position: relative;
	}
	.input-range__label--max .input-range__label-container {
		left: 50%;
	}

	.input-range__track {
		background: ${theme.color.pageBackground};
		border-radius: 6px;
		cursor: pointer;
		display: block;
		height: ${trackHeight}px;
		position: relative;
		border: 1px solid ${theme.color.primary};
		transition: left 0.3s ease-out, width 0.3s ease-out;
	}
	.input-range--disabled .input-range__track {
		background: ${theme.color.disabled};
	}

	.input-range__track--background {
		left: 0;
		position: absolute;
		right: 0;
		display: flex;
		align-items: center;
	}

	.input-range__track--active {
		background: ${theme.color.primary};
		height: 6px;
		border: 0;
		background: none;
	}

	.input-range {
		display: flex;
		align-items: center;
		height: ${containerHeight}px;
		position: relative;
		width: 100%;
	}
`,
);
