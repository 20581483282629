import React from 'react';
import PropTypes from 'prop-types';
import { iconMap } from '@constants';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Icon = props => {
    const IconSvg = iconMap[props.id] && iconMap[props.id].component;
    const intl = useIntl();
    const iconLabel = intl.formatMessage({ id: 'ICONS_LABEL' }, { title: props.title });

    if (!IconSvg) {
        console.warn(`Wrong icon id ${props.id}`); // eslint-disable-line
        return null;
    }

    return (
        <IconSvg
            id={props.id}
            className={props.className}
            onClick={props.onClick}
            title={iconLabel}
            role={props.role || 'img'}
            aria-label={props['aria-label'] || iconLabel}
            aria-hidden={true}
            focusable={false}
        />
    );
};

export const LinkIcon = styled(Icon).attrs(() => ({
    id: 'chevron_right',
}))(
    ({ theme, disabled }) => `
	path {
		stroke: ${disabled ? theme.color.disabled : theme.color.primary};
	}
`,
);

export const getIconPath = id => iconMap[id] && iconMap[id].path;

Icon.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default Icon;
