import { createSelector } from 'reselect';
import { compose, descend, prop, sortWith, ascend } from 'ramda';
import moment from 'moment';

const formatTime = endTime => moment(endTime).format('YYYYMMDD');
const formatEndTime = () => compose(formatTime, prop('endTime'));

const sortChallengesByEndTime = sortWith([descend(formatEndTime())]);
const sortChallengesByEndTimeAscending = sortWith([ascend(formatEndTime())]);

export const challengesSelector = state => state.challenges.joined || { items: [] };
export const availableChallengesSelector = state => state.challenges.available;
export const userChallengesSelector = state => state.challenges.user.items;
export const pastChallengesSelector = state => state.challenges.past;
export const nextLinkSelector = type => state => state.challenges[type].nextLink;
export const loadingSelector = type => state => state.challenges[type].loading;
export const initLoadedSelector = type => state => state.challenges[type].initLoaded;
export const openChallengeSelector = state => state.challenges.joined.loading;
export const challengeTeamSelector = state => state.challenges.teamId;
export const challengeTeamsSelector = state => state.challenges.challenge?.teams || [];
export const challengeSelector = state => state.challenges.challenge;
export const challengeDetailLoadingSelector = state =>
    state.challenges.challenge ? state.challenges.challenge.loading : true;

export const sortedPastChallengesSelector = createSelector(
    pastChallengesSelector,
    compose(sortChallengesByEndTime, prop('items')),
);
export const sortedChallengesSelector = createSelector(
    challengesSelector,
    compose(sortChallengesByEndTimeAscending, prop('items')),
);
export const sortedAvailableChallengesSelector = createSelector(
    availableChallengesSelector,
    compose(sortChallengesByEndTimeAscending, prop('items')),
);

export const sortedChallengeTeamsSelector = createSelector(
    challengeTeamsSelector,
    compose(sortWith([ascend(prop('rank'))])),
);
