import { createAction } from '@utils/redux';
import * as types from '@services/challengeParticipants/actionTypes';

export const loadUserCPRequest = createAction(types.LOAD_USER_CP_REQUEST);
export const loadUserCPSuccess = createAction(types.LOAD_USER_CP_SUCCESS);
export const loadUserCPFailure = createAction(types.LOAD_USER_CP_FAILURE);

export const loadChallengeParticipantsRequest = createAction(types.LOAD_CHALLENGE_PARTICIPANTS_REQUEST);
export const loadChallengeParticipantsSuccess = createAction(types.LOAD_CHALLENGE_PARTICIPANTS_SUCCESS);
export const loadChallengeParticipantsFailure = createAction(types.LOAD_CHALLENGE_PARTICIPANTS_FAILURE);

export const loadMoreChallengeParticipantRequest = createAction(types.LOAD_MORE_CHALLENGE_PARTICIPANT_REQUEST);
export const loadMoreChallengeParticipantSuccess = createAction(types.LOAD_MORE_CHALLENGE_PARTICIPANT_SUCCESS);
export const loadMoreChallengeParticipantFailure = createAction(types.LOAD_MORE_CHALLENGE_PARTICIPANT_FAILURE);

export const deleteUserCPRequest = createAction(types.DELETE_USER_CP_REQUEST);
export const deleteUserCPSuccess = createAction(types.DELETE_USER_CP_SUCCESS);
export const deleteUserCPFailure = createAction(types.DELETE_USER_CP_FAILURE);
