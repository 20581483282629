export const LOAD_CHALLENGES_REQUEST = 'LOAD_CHALLENGES_REQUEST';
export const LOAD_CHALLENGES_SUCCESS = 'LOAD_CHALLENGES_SUCCESS';
export const LOAD_CHALLENGES_FAILURE = 'LOAD_CHALLENGES_FAILURE';

export const LOAD_AVAILABLE_CHALLENGES_REQUEST = 'LOAD_AVAILABLE_CHALLENGES_REQUEST';
export const LOAD_AVAILABLE_CHALLENGES_SUCCESS = 'LOAD_AVAILABLE_CHALLENGES_SUCCESS';
export const LOAD_AVAILABLE_CHALLENGES_FAILURE = 'LOAD_AVAILABLE_CHALLENGES_FAILURE';

export const LOAD_PAST_CHALLENGES_REQUEST = 'LOAD_PAST_CHALLENGES_REQUEST';
export const LOAD_PAST_CHALLENGES_SUCCESS = 'LOAD_PAST_CHALLENGES_SUCCESS';
export const LOAD_PAST_CHALLENGES_FAILURE = 'LOAD_PAST_CHALLENGES_FAILURE';

export const JOIN_CHALLENGE_REQUEST = 'JOIN_CHALLENGE_REQUEST';
export const JOIN_CHALLENGE_SUCCESS = 'JOIN_CHALLENGE_SUCCESS';
export const JOIN_CHALLENGE_FAILURE = 'JOIN_CHALLENGE_FAILURE';

export const SET_OPENED_CHALLENGE = 'SET_OPENED_CHALLENGE';
export const SET_TEAM_ID = 'SET_TEAM_ID';
export const CLEAR_CHALLENGE_DETAILS = 'CLEAR_CHALLENGE_DETAILS';

export const LOAD_CHALLENGE_DETAIL_REQUEST = 'LOAD_CHALLENGE_DETAIL_REQUEST';
export const LOAD_CHALLENGE_DETAIL_SUCCESS = 'LOAD_CHALLENGE_DETAIL_SUCCESS';
export const LOAD_CHALLENGE_DETAIL_FAILURE = 'LOAD_CHALLENGE_DETAIL_FAILURE';
export const CLEAN_CHALLENGE_DETAIL_PAGE = 'CLEAN_CHALLENGE_DETAIL_PAGE';
