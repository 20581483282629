import { createAction } from '@utils/redux';
import * as types from '@services/agreements/actionTypes';

export const loadUserAgreementsRequest = createAction(types.LOAD_USER_AGREEMENTS_REQUEST);
export const loadUserAgreementsSuccess = createAction(types.LOAD_USER_AGREEMENTS_SUCCESS);
export const loadUserAgreementsFailure = createAction(types.LOAD_USER_AGREEMENTS_FAILURE);

export const updateUserAgreementsRequest = createAction(types.UPDATE_USER_AGREEMENTS_REQUEST);
export const updateUserAgreementsSuccess = createAction(types.UPDATE_USER_AGREEMENTS_SUCCESS);
export const updateUserAgreementsFailure = createAction(types.UPDATE_USER_AGREEMENTS_FAILURE);
