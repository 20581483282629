import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getCurrentGoals, getGoals } from './api';

export function* loadGoalsWorker({ payload }) {
    try {
        const { data } = yield call(getGoals, payload);
        yield put(actions.loadGoalsSuccess({ ...data }));
    } catch (e) {
        yield put(actions.loadGoalsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* loadOldGoalsWorker({ payload }) {
    try {
        const { data } = yield call(getCurrentGoals, payload);
        yield put(actions.loadOldGoalsSuccess({ ...data }));
    } catch (e) {
        yield put(actions.loadOldGoalsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadGoalsRequest, loadGoalsWorker);
    yield takeLatest(actions.loadOldGoalsRequest, loadOldGoalsWorker);
}
