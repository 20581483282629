import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@components/ui';
import { getCountryAndCity } from '@utils/geocoder';
import { TextButton } from '@components/ui/Form/Button';
import {
    ActionButton,
    Actions,
    ErrorDialog,
    FindLoader,
    LocationIcon,
    ButtonContainer,
    FindLocationIcon,
} from './styled';

const FindLocation = ({ setFieldValue }) => {
    const [locationError, setLocationError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const onClose = () => setLocationError('');
    const handleError = ({ message }) => {
        setLocationError(message);
        setLoading(false);
    };
    const setFieldsByPosition = (country, city) => {
        setFieldValue('custom.country', country);
        setFieldValue('custom.city', city);
        setLoading(false);
    };

    const findLocation = () => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(async position => {
            const { country, city, error } = await getCountryAndCity(position);
            if (error) {
                return handleError({ message: error });
            }

            return setFieldsByPosition(country.short_name, city.short_name);
        }, handleError);
    };

    return (
        <>
            <ButtonContainer tabIndex="-1">
                <TextButton onClick={findLocation}>
                    <FindLocationIcon />
                    <FormattedMessage id="FIND_ME" />
                    {isLoading ? <FindLoader size={15} /> : null}
                </TextButton>
            </ButtonContainer>

            {locationError && (
                <ErrorDialog head={<></>} onClose={onClose}>
                    <LocationIcon id="il_gps" />
                    <Typography variant="subtitle">
                        <FormattedMessage id="WE_CANT_FIND_YOU" />
                    </Typography>
                    <Typography variant="body">
                        <FormattedMessage id="ADJUST_YOUR_LOCATION" />
                    </Typography>
                    <Actions>
                        <ActionButton onClick={onClose}>
                            <FormattedMessage id="common_cancel" />
                        </ActionButton>
                        <ActionButton onClick={onClose}>
                            <FormattedMessage id="common_save" />
                        </ActionButton>
                    </Actions>
                </ErrorDialog>
            )}
        </>
    );
};
export default FindLocation;
