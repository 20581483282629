import styled from 'styled-components';
import { Container } from '@components/ui';
import Backdrop from './Backdrop';
import Dialog from './Dialog';
import Content from './Content';
import Title from './Title';

const MODAL_SIDE_GAP = 60;

const ModalContainer = styled(Container)`
    display: flex;
    justify-content: center;
    max-height: calc(100vh - ${MODAL_SIDE_GAP}px);
    @media only screen and (min-width: 992px) {
        max-width: 850px;
    }
`;

export default { Backdrop, Dialog, Content, Title, Container: ModalContainer };
