import { createAction } from '@utils/redux';
import * as types from '@services/programs/actionTypes';

export const loadProgramsRequest = createAction(types.LOAD_PROGRAMS_REQUEST);
export const loadProgramsSuccess = createAction(types.LOAD_PROGRAMS_SUCCESS);
export const loadProgramsFailure = createAction(types.LOAD_PROGRAMS_FAILURE);

export const loadPastProgramsRequest = createAction(types.LOAD_PAST_PROGRAMS_REQUEST);
export const loadPastProgramsSuccess = createAction(types.LOAD_PAST_PROGRAMS_SUCCESS);
export const loadPastProgramsFailure = createAction(types.LOAD_PAST_PROGRAMS_FAILURE);

export const loadPartnerContentRequest = createAction(types.LOAD_PARTNER_CONTENT_REQUEST);
export const loadPartnerContentSuccess = createAction(types.LOAD_PARTNER_CONTENT_SUCCESS);
export const loadPartnerContentFailure = createAction(types.LOAD_PARTNER_CONTENT_FAILURE);

export const loadProgramRequest = createAction(types.LOAD_PROGRAM_REQUEST);
export const loadProgramSuccess = createAction(types.LOAD_PROGRAM_SUCCESS);
export const loadProgramFailure = createAction(types.LOAD_PROGRAM_FAILURE);

export const loadProgramSessionsRequest = createAction(types.LOAD_PROGRAM_SESSIONS_REQUEST);
export const loadProgramSessionsSuccess = createAction(types.LOAD_PROGRAM_SESSIONS_SUCCESS);
export const loadProgramSessionsFailure = createAction(types.LOAD_PROGRAM_SESSIONS_FAILURE);

export const loadProgramSessionRequest = createAction(types.LOAD_PROGRAM_SESSION_REQUEST);
export const loadProgramSessionSuccess = createAction(types.LOAD_PROGRAM_SESSION_SUCCESS);
export const loadProgramSessionFailure = createAction(types.LOAD_PROGRAM_SESSION_FAILURE);
export const clearProgramSession = createAction(types.CLEAR_PROGRAM_SESSION);

export const joinSessionRequest = createAction(types.CREATE_SESSION_REQUEST);
export const joinSessionSuccess = createAction(types.CREATE_SESSION_SUCCESS);
export const joinSessionFailure = createAction(types.CREATE_SESSION_FAILURE);

export const leaveSessionRequest = createAction(types.DELETE_SESSION_REQUEST);
export const leaveSessionSuccess = createAction(types.DELETE_SESSION_SUCCESS);
export const leaveSessionFailure = createAction(types.DELETE_SESSION_FAILURE);

export const setOpenEvent = createAction(types.SET_OPEN_EVENT);
export const setOpenPartnerProgram = createAction(types.SET_OPEN_PARTNER_PROGRAM);
export const setOpenSession = createAction(types.SET_OPEN_SESSION);
export const clearSessionsRequest = createAction(types.CLEAR_SESSIONS_REQUEST);
export const clearEventRequest = createAction(types.CLEAR_EVENT_REQUEST);
export const clearPartnerProgram = createAction(types.CLEAR_PARTNER_PROGRAM);

export const loadUserSessionsRequest = createAction(types.LOAD_USER_SESSIONS_REQUEST);
export const loadUserSessionsSuccess = createAction(types.LOAD_USER_SESSIONS_SUCCESS);
export const loadUserSessionsFailure = createAction(types.LOAD_USER_SESSIONS_FAILURE);

export const loadPartnerProgramRequest = createAction(types.LOAD_PARTNER_PROGRAM_REQUEST);
export const loadPartnerProgramSuccess = createAction(types.LOAD_PARTNER_PROGRAM_SUCCESS);
export const loadPartnerProgramFailure = createAction(types.LOAD_PARTNER_PROGRAM_FAILURE);
