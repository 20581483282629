import styled from 'styled-components';
import { Typography } from '@components/ui';

const Card = styled.div(
    ({ theme }) => `
	border-radius: 6px;
	box-shadow: ${theme.defaultShadow};
	background: ${theme.color.cardBackground};
	position: relative;
	padding: ${theme.spacing.sm}px;
	display: flex;
`,
);

export const CardTitle = styled(Typography).attrs(() => ({
    variant: 'subtitle',
}))(
    ({ theme }) => `
    margin: 0;
    margin-bottom: ${theme.spacing.xs}px;
    line-height: 24px;
    word-break: break-word;
`,
);

export const EmptyTitle = styled(CardTitle).attrs(() => ({ variant: 'body' }))``;
export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export default Card;
