import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const defaultStyles = `margin-top: 0;`;

const H0 = styled.h1(
    ({ theme }) => `
	${defaultStyles}
	font-family: ${theme.font.black};
	color: ${theme.color.primary};
	font-size: 42px;
	${theme.media.md`
		font-size: 40px;
	`};
	${theme.media.sm`
		font-size: 32px;
	`};
`,
);

const H0WG = styled(H0)(
    ({ theme }) => `
	font-family: ${theme.font.bold};
	color: ${theme.color.textPrimary};
	${theme.media.md`
		font-size: 36px;
	`};
`,
);

const H1 = styled.h1(
    ({ theme }) => `
	${defaultStyles}
	font-family: ${theme.font.black};
	color: ${theme.color.textPrimary};
	font-size: 36px;
	${theme.media.md`
		font-size: 32px;
	`};
	${theme.media.xs`
		font-family: ${theme.font.bold};
	`};
`,
);

const H2 = styled.h2(
    ({ theme }) => `
	${defaultStyles}
	font-family: ${theme.font.bold};
	color: ${theme.color.textPrimary};
	font-size: 26px;
	margin-bottom: 30px;
	${theme.media.xs`
		font-size: 24px;
	`};
`,
);

const H3 = styled.h3(
    ({ theme }) => `
	${defaultStyles}
	font-family: ${theme.font.bold};
	color: ${theme.color.textPrimary};
	font-size: 24px;
	${theme.media.xs`
		font-family: ${theme.font.bold};
	`};
`,
);

const H4 = styled.h4(
    ({ theme }) => `
    margin-top: 0;
	font-family: ${theme.font.bold};
	color: ${theme.color.textPrimary};
	font-size: 22px;
`,
);

const Subtitle = styled.p(
    ({ theme }) => `
	${defaultStyles}
	font-family: ${theme.font.bold};
	color: ${theme.color.textPrimary};
	font-size: 20px;
`,
);

const SubtitleWG = styled(Subtitle)(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 22px;
`,
);

const Body = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 16px;
	color: ${theme.color.textPrimary};
	line-height: 24px;
	margin: 0;
`,
);

const BodySecondary = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 16px;
	color: ${theme.color.textSecondary};
`,
);

const BodyInteractive = styled.a(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 16px;
	color: ${theme.color.primary};
	text-decoration: underline;
`,
);

const BodyBold = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.bold};
	font-size: 16px;
	color: ${theme.color.textPrimary};
`,
);

const BodyItalic = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 16px;
	color: ${theme.color.textPrimary};
	line-height: 24px;
	margin: 0;
	font-style: italic;
`,
);

const BodyBoldUncolored = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.bold};
	font-size: 16px;
`,
);

const BodyBlack = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.black};
	font-size: 16px;
	color: ${theme.color.textPrimary};
`,
);

const CaptionInteractive1 = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 14px;
	color: ${theme.color.primary};
	text-decoration: underline;
`,
);

const CaptionInteractive2 = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 12px;
	color: ${theme.color.primary};
	text-decoration: underline;
`,
);

const Subtitle2 = styled.p(
    ({ theme }) => `
	${defaultStyles}
	font-family: ${theme.font.bold};
	color: ${theme.color.textPrimary};
	font-size: 16px;
`,
);

const Caption1 = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 14px;
	color: ${theme.color.textSecondary};
`,
);

const LabelPrimary = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.bold};
	font-size: 16px;
	color: ${theme.color.textPrimary};
	margin: 0;
`,
);

const LabelSecondary = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.bold};
	font-size: 12px;
	color: ${theme.color.textPrimary};
	margin: 0;
`,
);

const LabelSecondaryLight = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.bold};
	font-size: 12px;
	color: ${theme.color.textSecondary};
	margin: 0;
`,
);

const Caption2 = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 12px;
	color: ${theme.color.textSecondary};
	${theme.media.md`
		font-size: 14px;
	`};
`,
);

const Caption3 = styled.p(
    ({ theme }) => `
	font-family: ${theme.font.regular};
	font-size: 14px;
	color: ${theme.color.textPrimary};
`,
);

const Hint = styled.span(
    ({ theme }) => `
    margin: 0;
	font-family: ${theme.font.regular};
	font-size: 12px;
	color: ${theme.color.textSecondary};
`,
);

const components = {
    titleWg: H0WG,
    title: H0,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    subtitle: Subtitle,
    subtitleWg: SubtitleWG,
    body: Body,
    bodySecondary: BodySecondary,
    bodyInteractive: BodyInteractive,
    bodyBold: BodyBold,
    bodyItalic: BodyItalic,
    bodyBoldUncolored: BodyBoldUncolored,
    bodyBlack: BodyBlack,
    captionInteractive1: CaptionInteractive1,
    captionInteractive2: CaptionInteractive2,
    caption1: Caption1,
    labelPrimary: LabelPrimary,
    labelSecondary: LabelSecondary,
    labelSecondaryLight: LabelSecondaryLight,

    subtitle2: Subtitle2,
    caption2: Caption2,
    caption3: Caption3,
    hint: Hint,
};

const Typography = ({ variant, children, ...props }) => {
    const Component = components[variant];
    if (!Component) return <div {...props}>{children}</div>;
    return <Component {...props}>{children}</Component>;
};

Typography.propTypes = {
    variant: PropTypes.oneOf(Object.keys(components)).isRequired,
};

Typography.defaultProps = {
    variant: 'body',
};

export default Typography;
