import { add, subtract } from 'ramda';
import { handleActions } from 'redux-actions';
import { deduplicateArray } from '@utils/redux';
import { POST } from '@constants';
import * as types from './actionTypes';
import * as tagTypes from '../tags/actionTypes';

export const initialState = { items: [], tagPostId: null };

const updatePostLike = action => post => {
    const { deleted } = action.payload;
    const mathMethod = deleted ? subtract : add;
    const updatedObject = {
        ...post,
        tagId: deleted ? undefined : action.payload.id,
        likeCount: mathMethod(post.likeCount, 1),
        lastLikeUser: action.payload.lastLikeUser || {},
    };

    return post.originalId === action.meta.postId ? updatedObject : post;
};

export default handleActions(
    {
        [types.LOAD_POSTS_SUCCESS]: (state, action) => ({
            ...state,
            ...action.payload,
            items: deduplicateArray([...state.items, ...action.payload.items]),
        }),
        [tagTypes.CREATE_TAGS_SUCCESS]: (state, action) => {
            if (action.meta.type === POST) {
                return {
                    ...state,
                    items: state.items.map(updatePostLike(action)),
                    tagPostId: null,
                };
            }
            return state;
        },
        [tagTypes.CREATE_TAGS_REQUEST]: (state, action) => ({
            ...state,
            tagPostId: action.meta.postId,
        }),
        [types.CLEAR_POSTS]: state => ({
            ...state,
            items: [],
        }),
    },
    initialState,
);
