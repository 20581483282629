import { compose, reduce, values, split, slice, head, toLower, cond, isNil, T, always, __, concat } from 'ramda';

/* eslint-disable */
const _SUCCESS = '_SUCCESS';
const _FAILURE = '_FAILURE';
const _WARNING = '_WARNING';
const _REQUEST = '_REQUEST';
const LOAD_ = 'LOAD_';
const UPDATE_ = 'UPDATE_';
const DELETE_ = 'DELETE_';
const CREATE_ = 'CREATE_';
const CHANGE_ = 'CHANGE_';
/* eslint-enable */

const [loading, creating, updating, deleting, refreshing] = [
    'loading',
    'creating',
    'updating',
    'deleting',
    'refreshing',
];

export const indicatorTypes = {
    [LOAD_]: loading,
    [CREATE_]: creating,
    [UPDATE_]: updating,
    [DELETE_]: deleting,
    [CHANGE_]: refreshing,
};

const setInitialState = compose(
    reduce((acc, value) => ({ ...acc, [value]: {} }), {}),
    values,
);

export const initialState = setInitialState(indicatorTypes);

/**
 * IMPORTANT!
 * Your action MUST end with a correct suffix (REQUEST/SUCCESS/FAILURE)
 * The word before suffix will be used as a lowercase key
 *
 * Ex: LOAD_MORE_USERS_REQUEST - 'users' will be a key and you can access it as 'state.loading.users'
 */

export const getActionTarget = compose(
    cond([
        [isNil, always('')],
        [T, toLower],
    ]),
    head,
    slice(-2, -1),
    split('_'),
);

export const isRequest = (state, type) => type.startsWith(state) && type.endsWith(_REQUEST);

export const isResponse = (state, type) =>
    type.startsWith(state) && (type.endsWith(_SUCCESS) || type.endsWith(_FAILURE) || type.endsWith(_WARNING));

export const setState = (state, type, indicator, value) => ({
    ...state,
    [indicator]: {
        ...state[indicator],
        [getActionTarget(type)]: value,
    },
});

export const getAction = compose(concat(__, '_'), head, split('_'));
export const isAlreadyLoaded = indicator => indicator === false;
export const setObjectLoading = (object, value) => ({ ...object, loading: value });
