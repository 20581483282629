import { css } from 'styled-components';
import { mergeDeepRight } from 'ramda';
import { themes } from './colors';
import { sizeVars, sizes } from './vars/sizes';
import { page } from './vars/page';
import { font } from './vars/font';
import partner from './vars/partner';

const DEFAULT_THEME = 'default';

const rules = {
    mdToLg: `(min-width: ${sizes.md}px) and (max-width: ${sizes.lg}px)`,
    smToMd: `(min-width: ${sizes.sm}px) and (max-width: ${sizes.md}px)`,
    smToLg: `(min-width: ${sizes.sm}px) and (max-width: ${sizes.lg}px)`,
    xsToSm: `(min-width: ${sizes.xs}px) and (max-width: ${sizes.sm}px)`,
    xsToMd: `(min-width: ${sizes.xs}px) and (max-width: ${sizes.md}px)`,
};

const buildQueries = (keys, returnCss) =>
    Object.keys(keys).reduce(
        (acc, label) => ({
            ...acc,
            [label]: returnCss(label),
        }),
        {},
    );

const defaultQueries = buildQueries(sizes, label => (literals, ...placeholders) =>
    css`
        @media only screen and (max-width: ${sizes[label] - 1}px) {
            ${css(literals, ...placeholders)};
        }
    `.join(''),
);

const minMaxQueries = buildQueries(rules, label => (literals, ...placeholders) =>
    css`
        @media only screen and ${rules[label]} {
            ${css(literals, ...placeholders)};
        }
    `.join(''),
);

export const getTheme = (color = DEFAULT_THEME) => {
    const colors = themes[color] || themes[DEFAULT_THEME];

    const theme = {
        color: {
            ...colors,
        },
        page,
        font,
        ...sizeVars,
        defaultShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        media: {
            ...defaultQueries,
            ...minMaxQueries,
        },
    };

    return mergeDeepRight(theme, partner);
};
