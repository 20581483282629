import moment from 'moment/moment';
import { toApiFormat, toFormat, formats } from '@utils/date';
import { proxyUser } from '@services/user/selectors';
import { USER_ID, userRequiredFields } from '@constants';
import { consentsMap } from '@constants/consent';
import { resolvePath } from '@utils/object';
import { getItem } from '@utils/storageUtils';

const getConsentKeys = () =>
    Object.values(consentsMap).reduce((acc, consent) => {
        const keys = consent.map(item => item.key);
        return acc.concat(keys);
    }, []);

const getConsentFields = (custom, user) => {
    const keys = getConsentKeys();
    return keys.reduce((acc, key) => {
        if (user.custom[key] !== undefined) {
            acc[key] = user.custom[key];
        }

        return acc;
    }, {});
};

const prepareCustom = user => {
    const custom = {
        country: user.country || null,
        city: user.city || null,
        newsletter: user.custom.newsletter || null,
    };

    if (user.county) {
        custom.county = user.county;
    }

    const consentFields = getConsentFields(custom, user);

    return {
        ...custom,
        ...consentFields,
    };
};

const prepareBody = (user, completeOnBoarding) => {
    const { onboardingFormat } = formats;
    const pickerFormat = user.dateOfBirth.includes('-') ? formats.apiDate : moment.localeData().longDateFormat('L');
    const format = completeOnBoarding ? onboardingFormat : pickerFormat;
    const dateOfBirth = toFormat(moment(user.dateOfBirth, format), formats.apiDate);

    return {
        dateOfBirth,
        sex: user.sex,
        time: toApiFormat(new Date()),
        height: user.height,
    };
};

export const transformUser = userObject => {
    const user = proxyUser(userObject);
    const custom = user.custom ? prepareCustom(user) : undefined;
    const body = user.body ? prepareBody(user, user.completeOnBoarding) : undefined;
    return {
        ...user,
        body,
        custom,
    };
};

export const checkUserIsValid = (user, showCompanyInformation, showCounty, isAuth = true) => {
    const hasRequiredFields = isAuth && userRequiredFields.every(field => resolvePath(user, field.name));

    if (showCompanyInformation && !user?.entity?.department) {
        return false;
    }

    if (showCounty && !user?.custom?.county) {
        return false;
    }

    return hasRequiredFields;
};

export const getUserId = () => getItem(USER_ID);
