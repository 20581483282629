import styled from 'styled-components';
import { Typography } from '@components/ui';
import { isEmpty } from 'ramda';

export const INPUT_HEIGHT = 59;
export const LABEL_HIGH_POSITION = 38;
export const LABEL_LOW_POSITION = 0;
export const LABEL_HIGH_FONT_SIZE = 12;
export const LABEL_LOW_FONT_SIZE = 16;
export const LABEL_LINE_HEIGHT = 24;
const INPUT_BOTTOM_EXTRA_SPACE = 40;
const ICON_LABEL_SPACE = 4;
const MESSAGE_INPUT_SPACE = 4;
const SPACE_FOR_LABEL = 21;
const BORDER_HEIGHT = 1;
const INPUT_SPACE_ERROR = 4;

export const INPUT_MAX_HEIGHT = 25;
export const INPUT_BOTTOM_SPACE = 24;

export const getValue = (value, mainValue, defaultValue) => (!isEmpty(value) ? mainValue : defaultValue);

const getDefaultValues = (value, theme) => `
	bottom: ${getValue(value, `${LABEL_HIGH_POSITION}px`, `${LABEL_LOW_POSITION}px`)};
	font-size: ${getValue(value, `${LABEL_HIGH_FONT_SIZE}px`, `${LABEL_LOW_FONT_SIZE}px`)};
	font-family: ${getValue(value, theme.font.bold, theme.font.regular)};

`;

export const validLabelStyles = (theme, value) => `
	${getDefaultValues(value, theme)}
	color: ${theme.color.textSecondary};
	line-height: ${getValue(value, `${LABEL_HIGH_FONT_SIZE}px`, `${LABEL_LINE_HEIGHT}px`)};

`;

export const disabledLabelStyles = (theme, value) => `
	bottom: ${getValue(value, `${LABEL_HIGH_POSITION}px`, `${LABEL_LOW_POSITION}px`)};
	font-size: ${getValue(value, `${LABEL_HIGH_FONT_SIZE}px`, `${LABEL_LOW_FONT_SIZE}px`)};
	font-family: ${getValue(value, theme.font.bold, theme.font.regular)};
	color: ${theme.color.textDisabled};
`;

const focusedLabelStyles = theme => `
	bottom: ${LABEL_HIGH_POSITION}px;
	font-size: ${theme.fontSize.label};
	font-family: ${theme.font.bold};
	color: ${theme.color.textSecondary};
	line-height: ${LABEL_HIGH_FONT_SIZE}px;
`;

const focusedInputStyles = theme => `
	caret-color: ${theme.color.primary};
	border-color: ${theme.color.primary};

`;

export const validInputStyles = theme => `
	border-color: ${theme.color.textSecondary};
`;

export const disabledInputStyles = theme => `
	border-color: ${theme.color.mediumGrey};
	color: ${theme.color.textDisabled};
	background: none;
`;

export const errorInputStyles = theme => `
	border-color: ${theme.color.error};
`;

export const InputBlock = styled.div(
    ({ theme, $value }) => `
	position: relative;
	width: 100%;
	height: ${INPUT_HEIGHT}px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	label {
		${getDefaultValues($value, theme)}
		line-height: ${getValue($value, `${LABEL_HIGH_FONT_SIZE}px`, `${LABEL_LINE_HEIGHT}px`)};
	}

`,
);

export const InputWrapper = styled.div(
    ({ theme, focused }) => `
	display: flex;
	align-items: flex-end;
	${focused &&
        `
		border-color: ${theme.color.primary};
	`}
`,
);

export const HintBlock = styled(Typography).attrs(() => ({ variant: 'hint' }))`
    margin-top: ${MESSAGE_INPUT_SPACE}px;
`;

export const OptionalLabel = styled(Typography).attrs(() => ({ variant: 'hint' }))`
    margin-top: ${MESSAGE_INPUT_SPACE}px;
`;

export const Container = styled.div(
    ({ $needExtraSpace, $noBottomMargin }) => `
	margin-bottom: ${$needExtraSpace ? `${INPUT_BOTTOM_EXTRA_SPACE}px` : `${INPUT_BOTTOM_SPACE}px`};
	${$noBottomMargin && 'margin-bottom: 0;'}
`,
);

export const Label = styled.label(
    ({ theme, $focused }) => `
	transition: 0.2s ease all;
	position: absolute;
	font-size: ${theme.fontSize.label2};
	font-family: ${theme.font.regular};
	color: ${theme.color.textDisabled};
	text-transform: uppercase;
	left: 0;
	margin-bottom: 0;
	${$focused &&
        `bottom: ${LABEL_HIGH_POSITION}px;
		font-size: ${theme.fontSize.label};
		font-family: ${theme.font.bold};
		color: ${theme.color.textSecondary};
		line-height: ${LABEL_HIGH_FONT_SIZE}px;`}
`,
);

export const IconWrapper = styled.div`
    height: ${LABEL_LINE_HEIGHT + BORDER_HEIGHT}px;
    margin-right: ${ICON_LABEL_SPACE}px;
    display: flex;
    align-items: center;
`;

export const EndIconWrapper = styled.div(
    ({ onClick }) => `
	position: absolute;
	right: 0;
	bottom: 0;
	height: ${LABEL_LINE_HEIGHT + BORDER_HEIGHT}px;
    display: flex;
    align-items: center;
	cursor: ${onClick ? 'pointer' : 'default'};
`,
);

export const Message = styled.div(
    ({ theme }) => `
	font-size: ${theme.fontSize.label};
	color: ${theme.color.error};
	font-family: ${theme.font.regular};
	padding-top: ${MESSAGE_INPUT_SPACE}px;
`,
);

export const getDefaultStyles = (value, theme, { error, disabled } = {}) => `
	position: relative;
	width: 100%;
	border: 0;
	outline: 0;
	padding: 0;
	border-radius: 0;
	background: transparent;
	font-size: ${theme.fontSize.input};
	font-family: ${theme.font.bold};
	margin-top: ${SPACE_FOR_LABEL}px;
	line-height: ${LABEL_LINE_HEIGHT}px;
	border-bottom: ${BORDER_HEIGHT}px solid ${disabled ? theme.color.mediumGrey : theme.color.textSecondary};
	height: ${LABEL_HIGH_POSITION}px;
	padding-top: ${LABEL_HIGH_FONT_SIZE}px;
	input {
		font-family: ${theme.font.bold};
	}

    &:valid ~ label {
		${validLabelStyles(theme, value)};
	};
	&:disabled ~ label {
		${disabledLabelStyles(theme, value)};
	};
	&:focus ~ label {
		${focusedLabelStyles(theme)};
	}
	&:focus {
		${focusedInputStyles(theme)};

	};
	${value &&
        !error &&
        `
	&:valid {
		${validInputStyles(theme)};
	}

	`}
	${disabled &&
        value &&
        `
		&:disabled ~ label {
			color: ${theme.color.textDisabled};
		};
	`};
	${error && errorInputStyles(theme)};

	&:disabled {
		${disabledInputStyles(theme)};
	}
`;
