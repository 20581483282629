import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = {
    items: [],
    countries: [],
    departments: [],
};

export default handleActions(
    {
        [types.LOAD_ENTITIES_SUCCESS]: (state, action) => ({
            ...state,
            items: action.payload.data.items,
        }),
        [types.LOAD_ENTITY_COUNTRY_SUCCESS]: (state, action) => ({
            ...state,
            countries: action.payload.data.items,
        }),
        [types.LOAD_ENTITY_DEPARTMENT_SUCCESS]: (state, action) => ({
            ...state,
            departments: action.payload.data.items,
        }),
        [types.LOAD_ENTITY_COUNTRY_REQUEST]: (state, action) => ({
            ...state,
            departments: [],
            countries: [],
        }),
    },
    initialState,
);
