import React from 'react';
import counties from '@constants/counties';
import { Input } from '@components/ui';
import CustomSelect from '@components/ui/Form/Select/Custom';

const CountySelect = ({ isDisabled, ...props }) => {
    const country = props?.values?.custom?.country;
    const options = counties[country]?.map(county => ({ value: county, label: county }));
    return options ? (
        <CustomSelect options={options} isDisabled={isDisabled} {...props} />
    ) : (
        <Input {...props} disabled={isDisabled} />
    );
};

export default CountySelect;
