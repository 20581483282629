export const COMMON_MODAL_WIDTH = 730;
export const NARROW_MODAL_WIDTH = 458;

export const ABOUT_MODAL = 'about_modal';
export const CHOOSE_CHALLENGE_TEAM = 'choose_challenge_modal';
export const DELETE_ACCOUNT_MODAL = 'delete_account_modal';
export const DELETE_AVATAR_MODAL = 'user_details_modal';
export const FUTURE_CHALLENGE_MODAL = 'future_challenge_modal';
export const JOIN_CHALLENGE_MODAL = 'join_challenge_modal';
export const LEGAL_MODAL = 'legal_modal';
export const LOGOUT_MODAL = 'logout_modal';
export const MARKDOWN_MODAL = 'markdown_modal';
export const PAST_EVENTS_MODAL = 'past_events_modal';
export const REACTIVATION_MODAL = 'reactivation_modal';
export const RESTRICT_JOIN_CHALLENGE_MODAL = 'restrict_join_challenge_modal';
export const SESSION_MODAL = 'session_modal';
export const STIPULATION_WARNING_MODAL = 'stipulation_warning_modal';
export const TERMS_AGREEMENTS_MODAL = 'terms_agreements_modal';
export const UPDATE_CELLS_MODAL = 'update_cells_modal';
export const UPDATE_EMAIL_MODAL = 'update_email_modal';
export const UPDATE_PASSWORD_MODAL = 'update_password_modal';
export const UPDATE_SUBSCRIPTION_MODAL = 'update_subscription_modal';
export const LEAVE_CHALLENGE_MODAL = 'leave_challenge_modal';
