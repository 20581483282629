export const LOAD_LOGIN_REQUEST = 'LOAD_LOGIN_REQUEST';
export const LOAD_LOGIN_SUCCESS = 'LOAD_LOGIN_SUCCESS';
export const LOAD_LOGIN_FAILURE = 'LOAD_LOGIN_FAILURE';

export const LOAD_LOGIN_SSO_REQUEST = 'LOAD_LOGIN_SSO_REQUEST';
export const CHECK_LOGIN_SSO_REQUEST = 'CHECK_LOGIN_SSO_REQUEST';

export const LOAD_CODE_REQUEST = 'LOAD_CODE_REQUEST';
export const LOAD_CODE_SUCCESS = 'LOAD_CODE_SUCCESS';
export const LOAD_CODE_FAILURE = 'LOAD_CODE_FAILURE';

export const LOAD_COUPON_CODE_REQUEST = 'LOAD_COUPON_CODE_REQUEST';
export const LOAD_COUPON_CODE_SUCCESS = 'LOAD_COUPON_CODE_SUCCESS';
export const LOAD_COUPON_CODE_FAILURE = 'LOAD_COUPON_CODE_FAILURE';

export const LOAD_RESET_PASSWORD_REQUEST = 'LOAD_RESET_PASSWORD_REQUEST';
export const LOAD_RESET_PASSWORD_SUCCESS = 'LOAD_RESET_PASSWORD_SUCCESS';
export const LOAD_RESET_PASSWORD_FAILURE = 'LOAD_RESET_PASSWORD_FAILURE';

export const CLEAR_ACCESS_CODE = 'CLEAR_ACCESS_CODE';
export const CLEAR_ERROR_FIELD = 'CLEAR_ERROR_FIELD';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_2FA_PHONENUMBER_REQUEST = 'UPDATE_2FA_PHONENUMBER_REQUEST';
export const UPDATE_2FA_PHONENUMBER_SUCCESS = 'UPDATE_2FA_PHONENUMBER_SUCCESS';
export const UPDATE_2FA_PHONENUMBER_FAILURE = 'UPDATE_2FA_PHONENUMBER_FAILURE';

export const ENABLE_2FA_CODE_RESEND = 'ENABLE_2FA_CODE_RESEND';
export const DISABLE_2FA_CODE_RESEND = 'DISABLE_2FA_CODE_RESEND';

export const LOAD_2FA_CODE_REQUEST = 'LOAD_2FA_CODE_REQUEST';
export const LOAD_2FA_CODE_SUCCESS = 'LOAD_2FA_CODE_SUCCESS';
export const LOAD_2FA_CODE_FAILURE = 'LOAD_2FA_CODE_FAILURE';

export const UPDATE_2FA_CODE_REQUEST = 'UPDATE_2FA_CODE_REQUEST';
export const UPDATE_2FA_CODE_SUCCESS = 'UPDATE_2FA_CODE_SUCCESS';
export const UPDATE_2FA_CODE_FAILURE = 'UPDATE_2FA_CODE_FAILURE';

export const LOAD_2FA_CODE_STATUS_REQUEST = 'LOAD_2FA_CODE_STATUS_REQUEST';
export const LOAD_2FA_CODE_STATUS_SUCCESS = 'LOAD_2FA_CODE_STATUS_SUCCESS';
export const LOAD_2FA_CODE_STATUS_FAILURE = 'LOAD_2FA_CODE_STATUS_FAILURE';

export const LOAD_CLIENT_LOGIN_REQUEST = 'LOAD_CLIENT_LOGIN_REQUEST';
export const LOAD_CLIENT_LOGIN_SUCCESS = 'LOAD_CLIENT_LOGIN_SUCCESS';
export const LOAD_CLIENT_LOGIN_FAILURE = 'LOAD_CLIENT_LOGIN_FAILURE';
