import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = { items: [] };

export default handleActions(
    {
        [types.LOAD_USER_AGREEMENTS_SUCCESS]: (state, action) => ({
            ...state,
            items: action.payload.items,
        }),
        [types.UPDATE_USER_AGREEMENTS_SUCCESS]: (state, action) => ({
            ...state,
            items: state.items.map(el =>
                el.id === action.payload.id ? { ...el, agreement: action.payload.agreement } : el,
            ),
        }),
    },
    initialState,
);
