import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = {};

export default handleActions(
    {
        [types.LOAD_HEALTHSCORE_SUCCESS]: (state, action) => action.payload || null,
    },
    initialState,
);
