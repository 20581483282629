import { useState, useEffect } from 'react';
import { configSelector } from '@services/auth/selectors';
import { isMFARequired, isMFAPending } from '@utils/mfa';
import { useSelector } from 'react-redux';

export function useMfa(user = {}, isAuth) {
    const config = useSelector(configSelector);
    const enableClient2FA = config?.features ? config?.features?.phone?.enableClient2FA : true;
    const [shouldVerify2FA, setShouldVerify2FA] = useState(enableClient2FA ? isAuth : false);

    useEffect(() => {
        if (isAuth && enableClient2FA && (!user.id || isMFARequired(user) || isMFAPending(user))) {
            setShouldVerify2FA(true);
        } else {
            setShouldVerify2FA(false);
        }
    }, [isAuth, enableClient2FA, user]);

    return shouldVerify2FA;
}
