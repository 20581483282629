import imperialUnits from '@utils/units/imperialUnits';

export default {
    ...imperialUnits,
    // volume
    'US fl oz': {
        l: value => value / 33.814,
        dl: value => value / 3.3814,
        cl: value => value / 0.33814,
        ml: value => value / 0.033814,
    },
    // temperature
    F: {
        C: value => (value - 32) / 1.8,
    },
};
