import { getItems } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';

export function getStipulations(params) {
    return getItems(routes.stipulations, { ...params });
}

export function checkTermsOfUse(params) {
    return getItems(routes.checkTermsOfUse, params);
}
