import { __, always, compose, cond, findIndex, head, not, nth, prop, T, equals, isNil, reduce } from 'ramda';

const LOCALITY = 'locality';
const COUNTRY = 'country';
const ADDRESS_COMPONENT = 'address_components';

const handleError = () => ({ error: 'Failed' });

const getFirstType = compose(head, prop('types'));

const getLocalityIndex = findIndex(compose(equals(LOCALITY), getFirstType));

const isTypeEquals = type => compose(equals(type), getFirstType);

const getLocality = results => compose(nth(__, results), getLocalityIndex)(results);

const combineAddressTypes = (acc, component) =>
    cond([
        [isTypeEquals(LOCALITY), always({ ...acc, city: component })],
        [isTypeEquals(COUNTRY), always({ ...acc, country: component })],
        [T, always(acc)],
    ])(component);

const extractCountryAndCity = reduce(combineAddressTypes, {});

const parseLocality = compose(extractCountryAndCity, prop(ADDRESS_COMPONENT));

const parseResults = compose(parseLocality, getLocality);

const isExist = compose(not, isNil);

const takeCountryAndCity = cond([
    [isExist, always(parseResults)],
    [T, handleError],
])(nth(1));

export const getCountryAndCity = position => {
    const google = global.google || {};
    const geoCoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

    return new Promise(resolve =>
        geoCoder.geocode({ latLng }, (results, status) => {
            const actByStatus = cond([
                [equals(google.maps.GeocoderStatus.OK), always(takeCountryAndCity(results))],
                [T, handleError],
            ]);
            resolve(actByStatus(status));
        }),
    );
};

export const addGeocoderScript = config => {
    if (process.env.NODE_ENV === 'development') {
        const { host, geoPath, mapKey } = config.mapsConfig || {};
        const url = `https://${host}${geoPath}?key=${mapKey}&callback=Function.prototype`;
        const scripts = Array.from(document.getElementsByTagName('script'));
        const googleScript = scripts.find(item => item.src?.includes(url));

        if (!googleScript) {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
        }
    }
};
