import { call, put, takeLatest } from 'redux-saga/effects';
import { setItem } from '@utils/storageUtils';
import { head } from 'ramda';
import { addErrorNotification } from '@services/notifications/actions';
import { loadBrandingSuccess, loadBrandingFailure, loadBrandingRequest } from '@services/branding/actions';
import { getBranding } from '@services/branding/api';
import { BRANDING } from '@constants';

export function* brandingSaga(action) {
    try {
        const { data } = yield call(getBranding, action.payload);

        const prioritizedBranding = head(data?.items);
        setItem([BRANDING], [prioritizedBranding]);

        yield put(loadBrandingSuccess(data));
    } catch (e) {
        yield put(loadBrandingFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(loadBrandingRequest, brandingSaga);
}
