import styled from 'styled-components';

export const FullHeightPage = styled.div(
    ({ theme, transparent, fullHeight = false }) => `
    min-height: ${fullHeight ? '100vh' : `calc(100vh - ${theme.page.header.height}px)`};
	position: relative;
	background: ${transparent ? 'transparent' : theme.color.pageBackground};
	${theme.media.xsToMd`
    	min-height: ${fullHeight ? '100vh' : `calc(100vh - ${theme.page.header.heightSm}px)`};
	`}
`,
);
