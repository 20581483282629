import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = { items: [] };

export default handleActions(
    {
        [types.LOAD_TRACKERS_SUCCESS]: (state, action) => ({
            ...state,
            items: action.payload.items,
        }),
    },
    initialState,
);
