import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '@services/trackers/actions';
import { loadTrackers } from '@services/trackers/api';

export function* loadTrackersWorker(action) {
    try {
        const { data } = yield call(loadTrackers, action.payload);
        yield put(actions.loadTrackersSuccess(data));
    } catch (e) {
        yield put(actions.loadTrackersFailure(e.message));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadTrackersRequest, loadTrackersWorker);
}
