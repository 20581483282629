import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import 'slick-carousel/slick/slick.css';
import { font } from './theme/vars/font';

export const DefaultStyles = `
html, body {
	height: 100%;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
    justify-content: space-between;
}

#root-modal {
	position: relative;
	z-index: 999;
}

* {
	box-sizing: border-box;
	font-family: ${font.regular}, sans-serif;
}

a:hover {
	text-decoration: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
`;

const CommonStyle = createGlobalStyle`
${normalize()}
${DefaultStyles}
`;
export default CommonStyle;
