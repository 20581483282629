import { parseToRgb } from 'polished';
import partner from './partner';

const getRGBString = color => Object.values(parseToRgb(color)).join(', ');

const brandColors = {
    primary: '#0B719D',
    primarySelect: '#09658C',
    disabled: '#B5DAEB',
    secondary: '#5F9CBB',
    secondarySelect: '#427087',
    tertiary: '#E2F6FF',
};

const systemColors = {
    textPrimary: '#404040',
    textSecondary: '#6F6F6F',
    textDisabled: '#949494',
    textContrast: '#FFFFFF',
    tertiaryButton: '#9B9B9B',
    tertiaryButtonSelect: '#828282',
    mediumGrey: '#CCCCCC',
    lightGrey: '#EDEDED',
    pageBackground: '#F5F5F5',
    cardBackground: '#FFFFFF',
    error: '#D0021B',
    notification: '#D0021B',
    validation: '#6C9A37',
    errorLight: '#FAE5E8',
};

const wheelColors = {
    lockedWheel: '#EBEBEB',
    nutritionWheel: '#F9D77B',
    indulgencesWheel: '#C0E78E',
    movementWheel: '#9CEAEB',
    mindfulnessWheel: '#B5A3E8',
    sleepWheel: '#A0D1F7',
    feelingsWheel: '#A4EDD2',
    bodyWheel: '#A4EDD2',
};

const mapping = {
    primaryRgb: colors => getRGBString(colors.primary),
    button: 'primary',
    icons: 'primary',
    coachBubbleAnswer: 'secondary',
    coachBubbleNoAnswer: 'tertiary',
    coachBubbleNoAnswerOutline: 'primary',
    navBg: 'cardBackground',
    navNotificationBg: 'error',
    link: 'textPrimary',
    linkHover: 'primary',
    burgerColor: 'primary',
    wheelBg: 'cardBackground',
    selectOptionBackground: 'cardBackground',
    selectOptionBackgroundHover: 'lightGrey',
    welcomeCircles: 'primary',
    welcomeGalleryTextColor: 'textPrimary',
    welcomeGalleryArrows: 'primary',
    welcomeGalleryBullet: 'disabled',
    welcomeGalleryActiveBullet: 'primary',
    welcomeNav: 'cardBackground',
    signUpButtonBgColor: 'primary',
    signUpButtonHoverBgColor: 'primarySelect',
    signUpButtonDisabledBgColor: 'disabled',
    signUpButtonSelectedBgColor: 'primarySelect',
    signUpButtonActiveBgColor: 'primarySelect',
    loginButtonBgColor: 'cardBackground',
    loginButtonColor: 'primary',
    toggleBar: 'secondary',
    toggleBullet: 'primary',
};

export const defaultTheme = {
    colors: {
        ...brandColors,
        ...systemColors,
        ...wheelColors,
    },
    mapping,
    partner,
};
