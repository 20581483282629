import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const EXTERNAL_LINK_PATTERN = new RegExp('^(((https|http)://)|mailto:)(.*)');

const LinkBox = styled.span(
    ({ theme, underlined }) => `
    display: inline-flex;
    align-items: center;
    a {
    	display: flex;
        font-family: ${theme.font.bold};
        color: ${theme.color.primary};
        text-decoration: ${underlined ? 'underline' : 'none'};
        font-size: 16px;
        :hover {
            text-decoration: 'none';
        }
    }
`,
);

const InternalLink = styled(Link)`
    align-items: center;
`;
const ExternalLink = styled.a`
    align-items: center;
`;

const StyledLink = ({ icon: Icon, external, underlined, onClick, ...props }) => {
    const externalLink = props.to && EXTERNAL_LINK_PATTERN.test(props.to) ? props.to : `http://${props.to}`;
    const useCustomClickhandler = useMemo(() => typeof onClick === 'function', [onClick]);

    const handleClick = useCallback(
        event => {
            event.preventDefault();
            if (useCustomClickhandler) {
                onClick(event);
            }
        },
        [useCustomClickhandler],
    );

    return (
        <LinkBox underlined className={props.className}>
            {external ? (
                <ExternalLink
                    href={externalLink}
                    {...props}
                    onClick={useCustomClickhandler ? handleClick : null}
                    rel="noopener"
                >
                    {props.children}
                    {Icon && <Icon />}
                </ExternalLink>
            ) : (
                <InternalLink {...props} onClick={useCustomClickhandler ? handleClick : null}>
                    {props.children}
                    {Icon && <Icon />}
                </InternalLink>
            )}
        </LinkBox>
    );
};

StyledLink.propTypes = {
    underlined: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.object,
};

StyledLink.defaultProps = {
    to: '#',
};

export default StyledLink;
