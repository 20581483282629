import React from 'react';
import CustomSelect from '@components/ui/Form/Select/Custom';
import { getItem } from '@utils/storageUtils';
import { LOCALE } from '@constants';
import countries from 'i18n-iso-countries';
import { useSelector } from 'react-redux';
import { configSelector } from '@services/auth/selectors';
import { DEFAULT_LOCALE } from '@constants/locale';

export const getAlpha3 = alpha2 => countries.getAlpha2Codes()[alpha2];

const filterCountries = (countriesForSelect, configCountries) => {
    if (configCountries.length) {
        return countriesForSelect.filter(country => configCountries.includes(country.value));
    }
    return countriesForSelect;
};

export const CountrySelect = ({ isDisabled, ...props }) => {
    const locale = getItem(LOCALE) || DEFAULT_LOCALE;
    const { features } = useSelector(configSelector);
    const localeCode = locale.substring(0, 2);
    // eslint-disable-next-line import/no-dynamic-require
    countries.registerLocale(require(`i18n-iso-countries/langs/${localeCode}.json`));

    const countriesList = countries.getNames(localeCode, { select: 'official' });
    const countriesForSelect = Object.entries(countriesList).map(e => ({ value: e[0], label: e[1] }));
    const countriesConfigList = features?.country?.countryList || [];

    const filteredCountries = filterCountries(countriesForSelect, countriesConfigList);
    const sortedOptions = filteredCountries.sort((a, b) => a.label.localeCompare(b.label));

    return <CustomSelect options={sortedOptions} isDisabled={isDisabled} {...props} />;
};

export default CountrySelect;
