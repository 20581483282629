import { getItems, editItem } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';

export function getStagings(params) {
    return getItems(routes.stagings, params);
}

export function updateStaging(data) {
    return editItem(routes.stagings, data, data.id);
}
