import { createAction } from '@utils/redux';
import * as types from '@services/entities/actionTypes';

export const loadEntitiesRequest = createAction(types.LOAD_ENTITIES_REQUEST);
export const loadEntitiesSuccess = createAction(types.LOAD_ENTITIES_SUCCESS);
export const loadEntitiesFailure = createAction(types.LOAD_ENTITIES_FAILURE);

export const loadEntityCountryRequest = createAction(types.LOAD_ENTITY_COUNTRY_REQUEST);
export const loadEntityCountrySuccess = createAction(types.LOAD_ENTITY_COUNTRY_SUCCESS);
export const loadEntityCountryFailure = createAction(types.LOAD_ENTITY_COUNTRY_FAILURE);

export const loadEntityDepartmentsRequest = createAction(types.LOAD_ENTITY_DEPARTMENT_REQUEST);
export const loadEntityDepartmentsSuccess = createAction(types.LOAD_ENTITY_DEPARTMENT_SUCCESS);
export const loadEntityDepartmentsFailure = createAction(types.LOAD_ENTITY_DEPARTMENT_FAILURE);
