import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getActivity } from './api';

export function* loadActivityWorker({ payload }) {
    try {
        const { data } = yield call(getActivity, payload);
        yield put(actions.loadActivitySuccess({ ...data }));
    } catch (e) {
        yield put(actions.loadActivityFailure(e.message));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadActivityRequest, loadActivityWorker);
}
