import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as reducers from '@services/reducers';
import { LOGOUT_SUCCESS } from '@services/auth/actionTypes';

const rootReducer = history =>
    combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

export default history => (state, action) => {
    const validState = action.type === LOGOUT_SUCCESS ? undefined : state;
    return rootReducer(history)(validState, action);
};
