import styled from 'styled-components';
import Input from '../Input';

export const PasswordPolicyContainer = styled.ul(
    ({ theme }) => `
    margin-top: 0;
    margin-bottom: 0;
    color: ${theme.color.textSecondary};
    font-size: 12px;
`,
);

export const StyledInput = styled(Input)`
    margin-bottom: 8px;
`;
