import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SelectElement from 'react-select';
import { useField } from 'formik';
import { map, find, propEq } from 'ramda';
import { InputBlock, InputWrapper } from '@components/ui/Form/styled';
import { formatMessage } from '@utils/intl';
import components from './components';
import { Container } from './styled';

const Select = props => {
    const { options, intl, name, label, ...restProps } = props;
    const [field, meta, helpers] = useField(name);
    const { setValue, setTouched } = helpers;
    const { value } = field;
    const { error, touched } = meta;

    const translateOption = option => ({
        ...option,
        label: formatMessage(option.label, option.label),
    });

    const selectOptions = map(translateOption, options);
    const selectValue = selectOptions ? find(propEq('value', value))(selectOptions) : '';

    const onChange = value => setValue(value.value);
    const onBlur = () => setTouched(true);

    return (
        <Container>
            <InputWrapper>
                <InputBlock $value={value}>
                    <SelectElement
                        components={components}
                        placeholder={''}
                        label={label}
                        name={name}
                        value={selectValue}
                        onChange={onChange}
                        onBlur={onBlur}
                        options={selectOptions}
                        touched={touched}
                        error={error}
                        {...restProps}
                    />
                </InputBlock>
            </InputWrapper>
        </Container>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array.isRequired,
    autoFocus: PropTypes.bool,
    backspaceRemovesValue: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    closeMenuOnScroll: PropTypes.bool,
    delimiter: PropTypes.string,
    filterOption: PropTypes.func,
    hideSelectedOptions: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOptionDisabled: PropTypes.func,
    isOptionSelected: PropTypes.func,
    isMulti: PropTypes.bool,
    isRtl: PropTypes.bool,
    isSearchable: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
};

export default injectIntl(Select);
