import { addErrorNotification } from '@services/notifications/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getMedia, saveAnswers, startSurvey } from './api';

export function* createStartSurveyWorker({ meta, payload }) {
    try {
        const { data } = yield call(startSurvey, payload);

        const assetKeys = data.questions
            .map(question => question.assets?.find(({ key }) => key === 'image')?.value)
            .filter(Boolean);

        if (assetKeys.length) {
            yield put(actions.loadQuestionMediaRequest(assetKeys));
        }
        yield put(actions.createStartSurveySuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.createStartSurveyFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* saveAnswersWorker({ meta, payload }) {
    try {
        const { data } = yield call(saveAnswers, payload);
        yield put(actions.saveAnswersSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.saveAnswersFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* silentsaveAnswersWorker({ meta, payload }) {
    try {
        const { data } = yield call(saveAnswers, payload);
        yield put(actions.silentSaveAnswersSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.silentSaveAnswersFailure(e.message));
    }
}

export function* loadQuestionMediaWorker({ payload = [] }) {
    try {
        const { data } = yield call(getMedia, payload.join(','));
        yield put(actions.loadQuestionMediaSuccess(data.items.map(item => ({ ...item.media?.at(0), key: item.key }))));
    } catch (e) {
        yield put(actions.loadQuestionMediaFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.createStartSurveyRequest, createStartSurveyWorker);
    yield takeLatest(actions.saveAnswersRequest, saveAnswersWorker);
    yield takeLatest(actions.silentSaveAnswersRequest, silentsaveAnswersWorker);
    yield takeLatest(actions.loadQuestionMediaRequest, loadQuestionMediaWorker);
}
