import { call, put, takeLatest } from 'redux-saga/effects';
import { getUserFriends } from '@services/friends/api';
import * as actions from '@services/friends/actions';
import { addSuccessNotification, addErrorNotification } from '@services/notifications/actions';
import { addUserRelation, getUserRelation, updateUserRelation } from './api';

const friendsLoadOptions = { counts: '*', kind: 'followers', status: 'pending' };

export function* addUserRelationWorker({ payload, meta }) {
    try {
        const { data } = yield call(addUserRelation, payload);
        yield put(actions.createUserRelationSuccess({ data, meta }));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.createUserRelationFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* getUserFriendsWorker(action) {
    try {
        const { data } = yield call(getUserFriends, action.payload);
        yield put(actions.loadUserFriendsSuccess(data));
    } catch (e) {
        yield put(actions.loadUserFriendsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserRelationWorker({ payload, meta }) {
    try {
        const { data } = yield call(updateUserRelation, payload);
        yield put(actions.loadUserFriendsCountsRequest(friendsLoadOptions));
        yield put(actions.updateUserRelationSuccess({ data, meta }));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updateUserRelationFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* getUserRelationWorker({ payload, meta }) {
    try {
        const { data } = yield call(getUserRelation, payload);
        yield put(actions.loadUserFriendsCountsSuccess({ data, meta }));
    } catch (e) {
        yield put(actions.loadUserFriendsCountsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* getPendingFriendsWorker(action) {
    try {
        const { data } = yield call(getUserFriends, action.payload);
        yield put(actions.loadPendingFriendsSuccess(data));
    } catch (e) {
        yield put(actions.loadUserFriendsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.createUserRelationRequest, addUserRelationWorker);
    yield takeLatest(actions.updateUserRelationRequest, updateUserRelationWorker);
    yield takeLatest(actions.loadUserFriendsRequest, getUserFriendsWorker);
    yield takeLatest(actions.loadPendingFriendsRequest, getPendingFriendsWorker);
}
