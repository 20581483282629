import routes from '@constants/apiRoutes';
import { newItem, getItems, getItem } from 'src/common/api/basic';
import { getUserId } from '@utils/user';

export function getChallenges(params) {
    return getItems(routes.challenges, params);
}

export function joinChallenge(data) {
    const id = getUserId();

    return newItem(routes.challengeParticipants, { userId: id, ...data });
}

export function getChallenge({ id, ...params }) {
    return getItem(routes.challenges, id, params);
}
