export default {
    ft: {
        m: value => value / 3.2808,
        in: value => value * 12,
    },
    mi: {
        km: value => value / 0.6213,
    },
    in: {
        cm: value => value / 0.3937,
        m: value => value / 39.37,
    },
    mph: {
        'km/h': value => value / 0.6213,
    },
    'min/mi': {
        'min/km': value => value / 1.609528,
    },
    // weight
    lb: {
        kg: value => value / 2.2046,
    },
    st: {
        kg: value => value / 0.157473,
        lb: value => value * 14,
    },
    oz: {
        g: value => value / 0.035274,
    },
};
