import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = [];

export default handleActions(
    {
        [types.LOAD_SUMMARIES_SUCCESS]: (state, action) => action.payload,
    },
    initialState,
);
