import { handleActions } from 'redux-actions';
import { setObjectLoading } from '@utils/indicator';
import * as types from './actionTypes';

export const initialState = {
    joined: {
        items: [],
    },
    available: {
        items: [],
        loading: false,
        initLoaded: false,
    },
    past: {
        items: [],
        loading: false,
        initLoaded: false,
    },
    teamId: null,
    challenge: null,
};

export default handleActions(
    {
        [types.LOAD_CHALLENGES_SUCCESS]: (state, action) => ({
            ...state,
            joined: action.payload,
        }),
        [types.LOAD_AVAILABLE_CHALLENGES_REQUEST]: state => ({
            ...state,
            available: setObjectLoading(state.available, true),
        }),
        [types.LOAD_AVAILABLE_CHALLENGES_FAILURE]: state => ({
            ...state,
            available: setObjectLoading(state.available, false),
        }),
        [types.LOAD_AVAILABLE_CHALLENGES_SUCCESS]: (state, action) => ({
            ...state,
            available: { ...setObjectLoading(action.payload, false), initLoaded: true },
        }),

        [types.LOAD_PAST_CHALLENGES_REQUEST]: state => ({
            ...state,
            past: setObjectLoading(state.past, true),
        }),
        [types.LOAD_PAST_CHALLENGES_FAILURE]: state => ({
            ...state,
            past: setObjectLoading(state.past, false),
        }),
        [types.LOAD_PAST_CHALLENGES_SUCCESS]: (state, action) => ({
            ...state,
            past: {
                ...action.payload,
                items: [...state.past.items, ...action.payload.items],
                initLoaded: true,
                loading: false,
            },
        }),
        [types.JOIN_CHALLENGE_SUCCESS]: (state, action) => {
            const challenge = state.available.items.filter(item => item.id === action.payload.object.id)[0];

            if (state.challenge !== null) {
                return state;
            }

            return {
                ...state,
                joined: {
                    ...state.joined,
                    items: [
                        ...state.joined.items,
                        {
                            ...challenge,
                            participants: challenge.participants ? ++challenge.participants : 1,
                        },
                    ],
                    loading: null,
                },
                available: {
                    ...state.available,
                    items: state.available.items.filter(item => item.id !== action.payload.object.id),
                },
                teamId: null,
            };
        },
        [types.SET_OPENED_CHALLENGE]: (state, action) => ({
            ...state,
            joined: setObjectLoading(state.joined, action.meta.item),
            challenge: action.meta.item,
        }),
        [types.SET_TEAM_ID]: (state, action) => ({
            ...state,
            teamId: action.meta.teamId,
        }),
        [types.LOAD_CHALLENGE_DETAIL_REQUEST]: state => ({
            ...state,
            challenge: setObjectLoading({}, true),
        }),
        [types.LOAD_CHALLENGE_DETAIL_SUCCESS]: (state, action) => ({
            ...state,
            challenge: setObjectLoading(action.payload, false),
        }),
        [types.LOAD_CHALLENGES_FAILURE]: state => ({
            ...state,
            challenge: setObjectLoading({}, false),
        }),
        [types.CLEAN_CHALLENGE_DETAIL_PAGE]: state => ({
            ...state,
            challenge: null,
        }),
    },
    initialState,
);
