import routes from '@constants/apiRoutes';
import { getItems, deleteItem } from 'src/common/api/basic';

export function getChallengeParticipants(params) {
    return getItems(routes.challengeParticipants, params);
}

export function getUserChallengeParticipant(params) {
    return getItems(routes.getUserCP, params);
}

export function deleteUserCP(id) {
    return deleteItem(routes.challengeParticipants, id);
}
