import { createAction } from '@utils/redux';
import * as types from './actionTypes';

export const loadMessagesRequest = createAction(types.LOAD_MESSAGES_REQUEST);
export const loadMessagesSuccess = createAction(types.LOAD_MESSAGES_SUCCESS);
export const loadMessagesFailure = createAction(types.LOAD_MESSAGES_FAILURE);

export const loadSocialMessagesRequest = createAction(types.LOAD_SOCIAL_MESSAGES_REQUEST);
export const loadSocialMessagesSuccess = createAction(types.LOAD_SOCIAL_MESSAGES_SUCCESS);
export const loadSocialMessagesFailure = createAction(types.LOAD_SOCIAL_MESSAGES_FAILURE);

export const loadCoachMessagesRequest = createAction(types.LOAD_COACH_MESSAGES_REQUEST);
export const loadCoachMessagesSuccess = createAction(types.LOAD_COACH_MESSAGES_SUCCESS);
export const loadCoachMessagesFailure = createAction(types.LOAD_COACH_MESSAGES_FAILURE);

export const loadLastCoachMessageRequest = createAction(types.LOAD_LAST_COACH_MESSAGES_REQUEST);
export const loadLastCoachMessageSuccess = createAction(types.LOAD_LAST_COACH_MESSAGES_SUCCESS);
export const loadLastCoachMessageFailure = createAction(types.LOAD_LAST_COACH_MESSAGES_FAILURE);

export const skipMessagesRequest = createAction(types.SKIP_MESSAGES_REQUEST);
export const skipMessagesSuccess = createAction(types.SKIP_MESSAGES_SUCCESS);
export const skipMessagesFailure = createAction(types.SKIP_MESSAGES_FAILURE);

export const answerCoachMessageRequest = createAction(types.ANSWER_COACH_MESSAGES_REQUEST);
export const answerCoachMessageSuccess = createAction(types.ANSWER_COACH_MESSAGES_SUCCESS);
export const answerCoachMessageFailure = createAction(types.ANSWER_COACH_MESSAGES_FAILURE);

export const loadCoachCompletedMessagesRequest = createAction(types.LOAD_COACH_COMPLETED_MESSAGES_REQUEST);
export const loadCoachCompletedMessagesSuccess = createAction(types.LOAD_COACH_COMPLETED_MESSAGES_SUCCESS);
export const loadCoachCompletedMessagesFailure = createAction(types.LOAD_COACH_COMPLETED_MESSAGES_FAILURE);

export const skipCoachMessageRequest = createAction(types.SKIP_COACH_MESSAGE_REQUEST);
export const skipCoachMessageSuccess = createAction(types.SKIP_COACH_MESSAGE_SUCCESS);
export const skipCoachMessageFailure = createAction(types.SKIP_COACH_MESSAGE_FAILURE);

export const clearUnseenNotificationsRequest = createAction(types.CLEAR_UNSEEN_NOTIFICATIONS_REQUEST);
export const clearUnseenNotificationsSuccess = createAction(types.CLEAR_UNSEEN_NOTIFICATIONS_SUCCESS);
export const clearUnseenNotificationsFailure = createAction(types.CLEAR_UNSEEN_NOTIFICATIONS_FAILURE);

export const loadMessagesCountRequest = createAction(types.LOAD_MESSAGES_COUNT_REQUEST);
export const loadMessagesCountSuccess = createAction(types.LOAD_MESSAGES_COUNT_SUCCESS);
export const loadMessagesCountFailure = createAction(types.LOAD_MESSAGES_COUNT_FAILURE);

export const cancelCompleteMessageRequest = createAction(types.CANCEL_COMPLETE_MESSAGE_REQUEST);

export const proceedCoachMessagesRequest = createAction(types.PROCEED_COACH_MESSAGES_REQUEST);
export const proceedCoachMessagesSuccess = createAction(types.PROCEED_COACH_MESSAGES_SUCCESS);
export const proceedCoachMessagesFailure = createAction(types.PROCEED_COACH_MESSAGES_FAILURE);

export const loadSocialMessagesCountsRequest = createAction(types.LOAD_SOCIAL_MESSAGES_COUNTS_REQUEST);
export const loadSocialMessagesCountsSuccess = createAction(types.LOAD_SOCIAL_MESSAGES_COUNTS_SUCCESS);
export const loadSocialMessagesCountsFailure = createAction(types.LOAD_SOCIAL_MESSAGES_COUNTS_FAILURE);

export const markMessageSeenRequest = createAction(types.MARK_MESSAGE_SEEN_REQUEST);
export const markMessageSeenSuccess = createAction(types.MARK_MESSAGE_SEEN_SUCCESS);
export const markMessageSeenFailure = createAction(types.MARK_MESSAGE_SEEN_FAILURE);
