export default {
    // distance
    m: {
        ft: value => value * 3.2808,
        in: value => value * 39.37,
    },
    km: {
        mi: value => value * 0.6213,
    },
    cm: {
        in: value => value * 0.3937,
    },
    // speed
    'km/h': {
        mph: value => value * 0.6213,
    },
    'min/km': {
        'min/mi': value => value * 1.609528,
    },
    // weight
    kg: {
        lb: value => value * 2.2046,
        st: value => value * 0.157473,
    },
    g: {
        oz: value => value * 0.035274,
    },
    // volume
    l: {
        'US fl oz': value => value * 33.814,
        'UK fl oz': value => value * 35.195,
    },
    dl: {
        'US fl oz': value => value * 3.3814,
        'UK fl oz': value => value * 3.5195,
    },
    cl: {
        'US fl oz': value => value * 0.33814,
        'UK fl oz': value => value * 0.35195,
    },
    ml: {
        'US fl oz': value => value * 0.033814,
        'UK fl oz': value => value * 0.035195,
    },
    // temperature
    C: {
        F: value => value * 1.8 + 32,
    },
};
