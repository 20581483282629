import { handleActions } from 'redux-actions';
import { handleListLoad } from '@utils/redux';
import * as types from './actionTypes';

export const initialState = { list: [] };

export default handleActions(
    {
        [types.LOAD_ARTICLES_SUCCESS]: handleListLoad,
    },
    initialState,
);
