import { createAction } from '@utils/redux';
import * as types from '@services/search/actionTypes';

export const loadSearchItemsRequest = createAction(types.LOAD_SEARCH_ITEMS_REQUEST);
export const loadSearchItemsSuccess = createAction(types.LOAD_SEARCH_ITEMS_SUCCESS);
export const loadSearchItemsFailure = createAction(types.LOAD_SEARCH_ITEMS_FAILURE);

export const clearSearchItemsRequest = createAction(types.CLEAR_SEARCH_ITEMS_REQUEST);

export const loadCheckEmailRequest = createAction(types.LOAD_CHECK_EMAIL_REQUEST);
export const loadCheckEmailSuccess = createAction(types.LOAD_CHECK_EMAIL_SUCCESS);
export const loadCheckEmailFailure = createAction(types.LOAD_CHECK_EMAIL_FAILURE);

export const storeSearchValue = createAction(types.STORE_SEARCH_VALUE);
