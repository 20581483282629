import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '@services/uris/actions';
import { getSupportUris } from '@services/uris/api';

export function* loadSupportUrisWorker(action) {
    try {
        const { data } = yield call(getSupportUris, action.payload);
        yield put(actions.loadSupportUrisSuccess(data));
    } catch (e) {
        yield put(actions.loadSupportUrisFailure(e.message));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadSupportUrisRequest, loadSupportUrisWorker);
}
