import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { toFormatMoment } from './date';

export const formatMessage = (id, defaultMessage) => {
    const intl = useIntl();
    return intl.formatMessage({ id, defaultMessage });
};

export const formatUnitValue = (value, unitId) => (
    <FormattedMessage id="UNIT_VALUE" values={{ value: value || '-', unit: <FormattedMessage id={unitId} /> }} />
);

export const formatDurationValue = value => {
    const intl = useIntl();
    const valueDuration = toFormatMoment(value, 'hh:mm:ss');
    const minutesUnit = intl.formatMessage({ id: 'UNIT_MINUTES' });
    const hoursUnit = intl.formatMessage({ id: 'UNIT_HOURS' });
    const minutesLabel = intl.formatMessage(
        { id: 'UNIT_VALUE' },
        { value: valueDuration.minutes() || '-', unit: minutesUnit },
    );

    if (!valueDuration.hours()) {
        return minutesLabel;
    }

    return `${valueDuration.hours()}${hoursUnit} ${valueDuration.minutes()}${minutesUnit}`;
};

formatMessage.propTypes = {
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
};
