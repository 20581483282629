import partner from './partner';

const colors = {
    primary: '#FF4D1D',
    primarySelect: '#CF6947',
    disabled: '#F9C0B0',
    secondary: '#F37C53',
    secondarySelect: '#CF6947',
    tertiary: '#F6D5D7',
};

export const orange = {
    colors,
    mapping: {},
    partner,
};
