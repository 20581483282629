export const ampmOptions = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
];

const DAY_IN_SECONDS = 86400;
const YEAR_IN_SECONDS = DAY_IN_SECONDS * 365;
export const TEN_YEARS_IN_SECONDS = YEAR_IN_SECONDS * 10;

export const MOMENT_FORMATS = {
    dateByLocale: 'L',
    timeByLocale: 'LT',
    dateTimeByLocale: 'L LT',
    fullDate: 'LL',
    extendedDate: 'LLL',
    apiLongDate: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
    apiDate: 'YYYY-MM-DD',
    onboardingFormat: 'DD/MM/YYYY',
    hoursMinutes: 'HH:mm',
    shortMonth: 'MMM',
    dateDayNumber: 'D',
};

const ZH_FORMAT = {
    'D MMM': 'MMM D日',
    'D+ MMM': 'MMM D+日',
    'DD/M': 'M/DD',
};

export const CUSTOM_FORMATS = {
    'ja-JP': {
        'D MMM': 'MMM D日',
        'D+ MMM': 'MMM D日',
        'DD/M': 'M/DD',
    },
    'zh-CN': ZH_FORMAT,
    'zh-HK': ZH_FORMAT,
    'ko-KR': {
        'D MMM': 'MMM D일',
        'D+ MMM': 'MMM D+일',
        'DD/M': 'M/DD',
    },
    'en-US': {
        'D MMM': 'MMM D',
        'D+ MMM': 'MMM D+',
        'DD/M': 'M/DD',
    },
};
