import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getFinancialScore } from './api';

export function* financialScoreWorker(action) {
    try {
        const { data } = yield call(getFinancialScore, action.payload);
        yield put(actions.financialScoreSuccess(data));
    } catch (e) {
        yield put(actions.financialScoreFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.financialScoreRequest, financialScoreWorker);
}
