import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SUCCESS, ERROR } from '@constants';
import { generateRandomString } from '@utils';
import * as types from './actionTypes';

export const initialState = {
    items: [],
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_SUCCESS_NOTIFICATION: {
            const key = generateRandomString();
            const defaultMessage = <FormattedMessage id="SUCCESSFULLY_UPDATED" />;

            const message = (action.payload && action.payload.message) || defaultMessage;
            const notification = {
                message,
                type: SUCCESS,
                key,
            };

            return {
                ...state,
                items: [notification, ...state.items],
            };
        }
        case types.ADD_ERROR_NOTIFICATION: {
            let message;
            let status;
            const defaultServerErr = action.payload.message || action.payload.error;
            const key = generateRandomString();

            if (action.payload.response) {
                message =
                    action.payload.response.status === 500
                        ? action.payload.response.statusText
                        : action.payload.response.data.error;
                status = action.payload.response.status;
            }

            const notification = {
                message: message || defaultServerErr,
                type: ERROR,
                status,
            };

            return {
                ...state,
                items: [{ ...notification, key }, ...state.items],
            };
        }

        case types.REMOVE_NOTIFICATION: {
            return {
                ...state,
                items: state.items.filter(notification => notification.key !== action.payload.key),
            };
        }

        case types.CLEAR_NOTIFICATIONS: {
            return {
                ...state,
                items: [],
            };
        }

        default:
            return state;
    }
};

export default notificationReducer;
