export const LOAD_SIGNUP_REQUEST = 'LOAD_SIGNUP_REQUEST';
export const LOAD_SIGNUP_SUCCESS = 'LOAD_SIGNUP_SUCCESS';
export const LOAD_SIGNUP_FAILURE = 'LOAD_SIGNUP_FAILURE';

export const UPDATE_ONBOARDING_REQUEST = 'UPDATE_ONBOARDING_REQUEST';
export const UPDATE_ONBOARDING_SUCCESS = 'UPDATE_ONBOARDING_SUCCESS';
export const UPDATE_ONBOARDING_FAILURE = 'UPDATE_ONBOARDING_FAILURE';

export const UPDATE_USER_PICTURE_REQUEST = 'UPDATE_USER_PICTURE_REQUEST';
export const UPDATE_USER_PICTURE_SUCCESS = 'UPDATE_USER_PICTURE_SUCCESS';
export const UPDATE_USER_PICTURE_FAILURE = 'UPDATE_USER_PICTURE_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const LOAD_USER_FRIENDS_REQUEST = 'LOAD_USER_FRIENDS_REQUEST';
export const LOAD_USER_FRIENDS_SUCCESS = 'LOAD_USER_FRIENDS_SUCCESS';
export const LOAD_USER_FRIENDS_FAILURE = 'LOAD_USER_FRIENDS_FAILURE';

export const LOAD_PENDING_FRIENDS_REQUEST = 'LOAD_PENDING_FRIENDS_REQUEST';
export const LOAD_PENDING_FRIENDS_SUCCESS = 'LOAD_PENDING_FRIENDS_SUCCESS';
export const LOAD_PENDING_FRIENDS_FAILURE = 'LOAD_PENDING_FRIENDS_FAILURE';

export const DELETE_USER_AVATAR_REQUEST = 'DELETE_USER_AVATAR_REQUEST';
export const DELETE_USER_AVATAR_SUCCESS = 'DELETE_USER_AVATAR_SUCCESS';
export const DELETE_USER_AVATAR_FAILURE = 'DELETE_USER_AVATAR_FAILURE';

export const UPDATE_USER_TERMS_REQUEST = 'UPDATE_USER_TERMS_REQUEST';
export const UPDATE_USER_TERMS_SUCCESS = 'UPDATE_USER_TERMS_SUCCESS';
export const UPDATE_USER_TERMS_FAILURE = 'UPDATE_USER_TERMS_FAILURE';

export const UPDATE_USER_SUBSCRIPTION_REQUEST = 'UPDATE_USER_SUBSCRIPTION_REQUEST';
export const UPDATE_USER_SUBSCRIPTION_SUCCESS = 'UPDATE_USER_SUBSCRIPTION_SUCCESS';
export const UPDATE_USER_SUBSCRIPTION_FAILURE = 'UPDATE_USER_SUBSCRIPTION_FAILURE';

export const CLEAR_ERROR_FIELD = 'CLEAR_ERROR_FIELD';

export const UPDATE_USER_PHONE_NUMBER_REQUEST = 'UPDATE_USER_PHONE_NUMBER_REQUEST';
export const UPDATE_USER_PHONE_NUMBER_SUCCESS = 'UPDATE_USER_PHONE_NUMBER_SUCCESS';
export const UPDATE_USER_PHONE_NUMBER_FAILURE = 'UPDATE_USER_PHONE_NUMBER_FAILURE';

export const ENABLE_SMS_CODE_RESEND = 'ENABLE_SMS_CODE_RESEND';

export const UPDATE_USER_PHONE_VALIDATION_REQUEST = 'UPDATE_USER_PHONE_VALIDATION_REQUEST';
export const UPDATE_USER_PHONE_VALIDATION_SUCCESS = 'UPDATE_USER_PHONE_VALIDATION_SUCCESS';
export const UPDATE_USER_PHONE_VALIDATION_FAILURE = 'UPDATE_USER_PHONE_VALIDATION_FAILURE';

export const LOAD_USER_EMAIL_CONFIRMATION_REQUEST = 'LOAD_USER_EMAIL_CONFIRMATION_REQUEST';
export const LOAD_USER_EMAIL_CONFIRMATION_SUCCESS = 'LOAD_USER_EMAIL_CONFIRMATION_SUCCESS';
export const LOAD_USER_EMAIL_CONFIRMATION_FAILURE = 'LOAD_USER_EMAIL_CONFIRMATION_FAILURE';

export const UPDATE_USER_LANGUAGE_REQUEST = 'UPDATE_USER_LANGUAGE_REQUEST';
export const UPDATE_USER_LANGUAGE_SUCCESS = 'UPDATE_USER_LANGUAGE_SUCCESS';
export const UPDATE_USER_LANGUAGE_FAILURE = 'UPDATE_USER_LANGUAGE_FAILURE';
