import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

const initialState = {};

export default handleActions(
    {
        [types.TOGGLE_MODAL_REQUEST]: (state, action) => ({
            ...state,
            [action.payload.name]: !state[action.payload.name],
            meta: action.meta || {},
        }),
        [types.OPEN_MODAL_REQUEST]: (state, action) => ({
            ...state,
            [action.payload.name]: true,
            meta: action.meta || {},
        }),
        [types.CLOSE_MODAL_REQUEST]: (state, action) => ({
            ...state,
            [action.payload.name]: false,
            meta: {},
        }),
    },
    initialState,
);
