import routes from '@constants/apiRoutes';
import { getItems, newItem } from 'common/api/basic';

export function startSurvey(key) {
    return newItem(routes.startSurvey, {}, { key });
}

export function saveAnswers(data) {
    return newItem(routes.saveAnswers, data);
}

export function getMedia(keys) {
    return getItems(routes.assets, { keys });
}
