import { call, put, takeLatest } from 'redux-saga/effects';
import { addErrorNotification } from '@services/notifications/actions';
import * as actions from './actions';
import { getAgreements, updateAgreement } from './api';

export function* loadUserAgreementsWorker({ meta, payload }) {
    try {
        const { data } = yield call(getAgreements, payload);
        yield put(actions.loadUserAgreementsSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.loadUserAgreementsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export function* updateUserAgreementWorker({ meta, payload }) {
    try {
        const { data } = yield call(updateAgreement, payload);
        yield put(actions.updateUserAgreementsSuccess({ ...data, meta }));
    } catch (e) {
        yield put(actions.updateUserAgreementsFailure(e.message));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loadUserAgreementsRequest, loadUserAgreementsWorker);
    yield takeLatest(actions.updateUserAgreementsRequest, updateUserAgreementWorker);
}
