import partner from './partner';

const colors = {
    primary: '#7ACB00',
    primarySelect: '#6DB600',
    disabled: '#CAEA99',
    secondary: '#90D560',
    secondarySelect: '#90D560',
    tertiary: '#D7EFB2',
};

export const lightGreen = {
    colors,
    mapping: {},
    partner,
};
