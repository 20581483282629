import { defaultTheme } from './default';
import { red } from './red';
import { green } from './green';
import { lightGreen } from './lightGreen';
import { darkBlue } from './darkBlue';
import { orange } from './orange';

const mergeTheme = function(theme) {
    const { colors, mapping, partner } = theme;

    return {
        colors: { ...colors, ...(partner.colors || {}) },
        mapping: { ...mapping, ...(partner.mapping || {}) },
    };
};

const createVars = function(colors, mapping) {
    const result = {};
    Object.keys(mapping).forEach(key => {
        const value = mapping[key];
        if (typeof value === 'function') {
            result[key] = value(colors);
            return;
        }

        const color = colors[value];
        if (color) {
            result[key] = color;
        }
    });

    return result;
};

const createTheme = function(theme) {
    const themeValues = mergeTheme(theme);
    const defaultValues = mergeTheme(defaultTheme);

    const colors = { ...defaultValues.colors, ...themeValues.colors };
    const mapping = { ...defaultValues.mapping, ...themeValues.mapping };
    const vars = createVars(colors, mapping);

    return {
        ...colors,
        ...vars,
    };
};

const createDefaultTheme = function(theme) {
    const themeValues = mergeTheme(theme);
    const vars = createVars(themeValues.colors, themeValues.mapping);

    return {
        ...themeValues.colors,
        ...vars,
    };
};

export const themes = {
    default: createDefaultTheme(defaultTheme),
    green: createTheme(green),
    red: createTheme(red),
    lightGreen: createTheme(lightGreen),
    darkBlue: createTheme(darkBlue),
    orange: createTheme(orange),
};
