import imperialUnits from '@utils/units/imperialUnits';
import metricUnits from '@utils/units/metricUnits';

export default {
    ...imperialUnits,
    // volume
    'UK fl oz': {
        l: value => value / 35.195,
        dl: value => value / 3.5195,
        cl: value => value / 0.35195,
        ml: value => value / 0.035195,
    },
    // temperature
    C: metricUnits.C,
};
