import styled from 'styled-components';

const Content = styled.div(
    ({ theme, fullWidth }) => `
    max-height: 100%;
    overflow-y: auto;
    margin: ${fullWidth ? 0 : '10px'};
    padding: ${fullWidth ? 0 : '0 20px'};
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
		background: ${theme.color.lightGrey};
		width: 8px;
		border-radius: 4px;
	}
	::-webkit-scrollbar-track {
		border-radius: 4px;
	}
	::-webkit-scrollbar-thumb {
		background: ${theme.color.primary};
		border-radius: 4px;
	}
`,
);

export default Content;
