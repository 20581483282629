export const LOAD_ENTITIES_REQUEST = 'LOAD_ENTITIES_REQUEST';
export const LOAD_ENTITIES_SUCCESS = 'LOAD_ENTITIES_SUCCESS';
export const LOAD_ENTITIES_FAILURE = 'LOAD_ENTITIES_FAILURE';

export const LOAD_ENTITY_COUNTRY_REQUEST = 'LOAD_ENTITY_COUNTRY_REQUEST';
export const LOAD_ENTITY_COUNTRY_SUCCESS = 'LOAD_ENTITY_COUNTRY_SUCCESS';
export const LOAD_ENTITY_COUNTRY_FAILURE = 'LOAD_ENTITY_COUNTRY_FAILURE';

export const LOAD_ENTITY_DEPARTMENT_REQUEST = 'LOAD_ENTITY_DEPARTMENT_REQUEST';
export const LOAD_ENTITY_DEPARTMENT_SUCCESS = 'LOAD_ENTITY_DEPARTMENT_SUCCESS';
export const LOAD_ENTITY_DEPARTMENT_FAILURE = 'LOAD_ENTITY_DEPARTMENT_FAILURE';
