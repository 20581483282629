export const LOAD_DASHBOARD_CELLS_REQUEST = 'LOAD_DASHBOARD_CELLS_REQUEST';
export const LOAD_DASHBOARD_CELLS_SUCCESS = 'LOAD_DASHBOARD_CELLS_SUCCESS';
export const LOAD_DASHBOARD_CELLS_FAILURE = 'LOAD_DASHBOARD_CELLS_FAILURE';

export const UPDATE_DASHBOARD_CELLS_REQUEST = 'UPDATE_DASHBOARD_CELLS_REQUEST';
export const UPDATE_DASHBOARD_CELLS_SUCCESS = 'UPDATE_DASHBOARD_CELLS_SUCCESS';
export const UPDATE_DASHBOARD_CELLS_FAILURE = 'UPDATE_DASHBOARD_CELLS_FAILURE';

export const DELETE_DASHBOARD_CELLS_REQUEST = 'DELETE_DASHBOARD_CELLS_REQUEST';
export const DELETE_DASHBOARD_CELLS_SUCCESS = 'DELETE_DASHBOARD_CELLS_SUCCESS';
export const DELETE_DASHBOARD_CELLS_FAILURE = 'DELETE_DASHBOARD_CELLS_FAILURE';

export const ADD_DASHBOARD_CELLS_REQUEST = 'ADD_DASHBOARD_CELLS_REQUEST';
export const ADD_DASHBOARD_CELLS_SUCCESS = 'ADD_DASHBOARD_CELLS_SUCCESS';
export const ADD_DASHBOARD_CELLS_FAILURE = 'ADD_DASHBOARD_CELLS_FAILURE';
