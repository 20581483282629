import { createAction } from '@utils/redux';
import * as types from '@services/friends/actionTypes';

export const loadUserFriendsRequest = createAction(types.LOAD_USER_FRIENDS_REQUEST);
export const loadUserFriendsSuccess = createAction(types.LOAD_USER_FRIENDS_SUCCESS);
export const loadUserFriendsFailure = createAction(types.LOAD_USER_FRIENDS_FAILURE);

export const loadPendingFriendsRequest = createAction(types.LOAD_PENDING_FRIENDS_REQUEST);
export const loadPendingFriendsSuccess = createAction(types.LOAD_PENDING_FRIENDS_SUCCESS);
export const loadPendingFriendsFailure = createAction(types.LOAD_PENDING_FRIENDS_FAILURE);

export const createUserRelationRequest = createAction(types.CREATE_USER_RELATION_REQUEST);
export const createUserRelationSuccess = createAction(types.CREATE_USER_RELATION_SUCCESS);
export const createUserRelationFailure = createAction(types.CREATE_USER_RELATION_FAILURE);

export const updateUserRelationRequest = createAction(types.UPDATE_USER_RELATION_REQUEST);
export const updateUserRelationSuccess = createAction(types.UPDATE_USER_RELATION_SUCCESS);
export const updateUserRelationFailure = createAction(types.UPDATE_USER_RELATION_FAILURE);

export const loadUserFriendsCountsRequest = createAction(types.LOAD_USER_FRIENDS_COUNTS_REQUEST);
export const loadUserFriendsCountsSuccess = createAction(types.LOAD_USER_FRIENDS_COUNTS_SUCCESS);
export const loadUserFriendsCountsFailure = createAction(types.LOAD_USER_FRIENDS_COUNTS_FAILURE);

export const setLoadingItemRequest = createAction(types.SET_LOADING_ITEM_ID_REQUEST);
