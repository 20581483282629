import styled from 'styled-components';

const Backdrop = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: visibility 0.1s linear;
`;

export default Backdrop;
