import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Icon, IconButton, Button } from '@components/ui';
import Modal from '@components/ui/Modal';
import { head, last } from 'ramda';
import { formatMessage } from '@utils/intl';
import { useIntl } from 'react-intl';
import FocusTrap from 'focus-trap-react';

const modalNode = document.getElementById('root-modal');

export const CloseButton = styled(IconButton)`
    width: auto;
    height: auto;
    position: absolute;
    right: 25px;
    top: 15px;
`;

const CloseIcon = styled(Icon)`
    width: 29px;
    height: 29px;
    path {
        fill: ${({ theme }) => theme.color.primary};
    }
`;

const ACTION_BUTTON_WIDTH = 114;
const COMMON_MODAL_WIDTH = 730;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

const ActionButton = styled(Button)`
    width: ${ACTION_BUTTON_WIDTH}px;
    margin: 0 30px;
    p {
        margin: 0;
    }
`;

const escapeCode = 27;
const tabCode = 9;

export function ModalComponent(props) {
    const { className, children, fullWidth, containerStyle, wrapperStyle } = props;
    const wrapperRef = useRef(null);

    const handleTabKey = e => {
        const focusableModalElements = wrapperRef.current.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        );

        const firstElement = head(focusableModalElements);
        const lastElement = last(focusableModalElements);
        if (!Array.from(focusableModalElements).includes(document.activeElement)) {
            firstElement.focus();
        }
        if (!e.shiftKey && document.activeElement === lastElement) {
            firstElement.focus();
            return e.preventDefault();
        }

        if (e.shiftKey && document.activeElement === firstElement) {
            lastElement.focus();
            return e.preventDefault();
        }
        return false;
    };

    const handleClickOutside = event => {
        const datePopup = document.querySelector('.date_popup');
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !datePopup && props.onClose) {
            props.onClose();
        }
    };

    const handleKeyPressMap = new Map([
        [escapeCode, props.onClose],
        [tabCode, handleTabKey],
    ]);

    useEffect(() => {
        function handleKeyPress(e) {
            const listener = handleKeyPressMap.get(e.keyCode);
            return listener && listener(e);
        }
        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <FocusTrap>
            <Modal.Container aria-modal="true" style={containerStyle}>
                <Modal.Dialog
                    className={`${className}, "hidden"`}
                    style={wrapperStyle}
                    ref={wrapperRef}
                    width={props.width}
                    height={props.height}
                    role="dialog"
                    aria-labelledby="title"
                    fullWidth={fullWidth}
                >
                    {children}
                </Modal.Dialog>
            </Modal.Container>
        </FocusTrap>
    );
}

const ModalDialog = props => {
    const intl = useIntl();
    const {
        actionButtons,
        children,
        title,
        label,
        onClose,
        head,
        notClosed,
        fullWidth,
        styles = {},
        big,
        ...rest
    } = props;
    const { Backdrop, Title, Content } = Modal;
    const preparedActions = onClose && { onClick: onClose };
    const modalTitle = label
        ? intl.formatMessage({ id: label })
        : (title?.props.id && intl.formatMessage({ id: title?.props.id })) || '';
    const modalLabel = intl.formatMessage({ id: 'MODAL_LABEL' }, { name: modalTitle });

    return (
        <Backdrop>
            <ModalComponent onClose={onClose} fullWidth={fullWidth} {...rest}>
                {!notClosed && (
                    <CloseButton {...preparedActions} aria-label={formatMessage('common_close')}>
                        <CloseIcon id="ic_closecircle_m" />
                    </CloseButton>
                )}
                {head ||
                    (title && (
                        <Title
                            variant="h2"
                            id="title"
                            style={styles.title}
                            tabIndex="0"
                            role="heading"
                            aria-level="1"
                            aria-label={modalLabel}
                            size={big ? 26 : 22}
                        >
                            {title}
                        </Title>
                    ))}
                <Content fullWidth={fullWidth}>{children}</Content>
                {actionButtons && (
                    <ActionsContainer>
                        {actionButtons.map((button, i) => {
                            const { label, ...props } = button;
                            return (
                                <ActionButton {...props} key={i}>
                                    {button.label}
                                </ActionButton>
                            );
                        })}
                    </ActionsContainer>
                )}
            </ModalComponent>
        </Backdrop>
    );
};

export default props =>
    ReactDOM.createPortal(<ModalDialog {...props} />, modalNode || document.getElementById('root-modal'));
