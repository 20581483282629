import bg1 from 'assets/img/1.png';
import bg2 from 'assets/img/2.png';
import bg3 from 'assets/img/3.png';
import bg4 from 'assets/img/4.png';
import bg5 from 'assets/img/5.png';
import { Illustration, MainIllustration } from 'src/routes/Welcome/styled';

const headerHeight = 72;
const headerHeightSm = 64;

export const mainFooterHeight = {
    lg: 200,
    md: 200,
    sm: 200,
    xs: 200,
};
export const secondaryFooterHeight = {
    lg: 110,
    md: 110,
    sm: 131,
    xs: 207,
};
export const cookieBannerHeight = {
    lg: 72,
    md: 72,
    sm: 92,
    xs: 120,
    xxs: 140,
};

const breadcrumbHeight = 70;
const pageTopPadding = 70;
const pageBottomPadding = 25;

export const page = {
    padding: {
        top: pageTopPadding,
        bottom: pageBottomPadding,
    },
    breadcrumbs: {
        height: breadcrumbHeight,
    },
    footer: {
        cookieBannerHeight,
        mainFooterHeight,
        secondaryFooterHeight,
    },
    header: {
        height: headerHeight,
        heightSm: headerHeightSm,
    },
    welcome: {
        navOpacity: 1,
        backgroundOpacity: 1,
        illustrationMarginBottom: 54,
        desktopAreaPaddingTop: 65,
        bulletOpacity: 0.7,
        pages: [
            {
                background: bg1,
                title: 'welcome_screen_title_welcome',
                body: 'welcome_screen_text_welcome',
                illustration: <MainIllustration id="il_welcome_logo" title={'welcome_screen_title_welcome'} />,
                skipCircles: true,
            },
            {
                background: bg2,
                title: 'SLIDE_TITLE_1',
                body: 'SLIDE_BODY_1',
                illustration: <Illustration id="il_welcome_hs" title={'SLIDE_TITLE_1'} />,
            },
            {
                background: bg3,
                title: 'welcome_screen_title_tracking',
                body: 'SLIDE_BODY_2',
                illustration: <Illustration id="il_welcome_navigator" title={'welcome_screen_title_tracking'} />,
            },
            {
                background: bg4,
                title: 'SLIDE_TITLE_3',
                body: 'SLIDE_BODY_3',
                illustration: <Illustration id="il_welcome_trophy" title={'SLIDE_TITLE_3'} />,
            },
            {
                background: bg5,
                title: 'welcome_screen_title_privacy',
                body: 'welcome_screen_text_privacy',
                illustration: <Illustration id="il_welcome_privacy" title={'welcome_screen_title_privacy'} />,
            },
        ],
    },
};

export const getSegments = theme => [
    {
        id: 'nutrition',
        name: 'wheel_nutrition_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.nutritionWheel,
        icon: 'il_hs_nutrition_wheel',
    },
    {
        id: 'indulgences',
        name: 'wheel_indulgences_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.indulgencesWheel,
        icon: 'il_hs_indulgences_wheel',
    },
    {
        id: 'movement',
        name: 'wheel_activity_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.movementWheel,
        icon: 'il_hs_activity_wheel',
    },
    {
        id: 'mindfulness',
        name: 'wheel_mindfulness_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.mindfulnessWheel,
        icon: 'il_mindfulness_wheel',
    },
    {
        id: 'sleep',
        name: 'wheel_sleep_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.sleepWheel,
        icon: 'il_sleep_wheel',
    },
    {
        id: 'feelings',
        name: 'wheel_mental_wellbeing_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.feelingsWheel,
        icon: 'il_mind_wheel_cropped',
        customs: {
            desktopX: '-48',
            desktopY: '-37',
            desktopMeasures: '110',
            mobileX: '-30',
            mobileY: '-25',
            mobileMeasues: '70',
        },
    },
    {
        id: 'body',
        name: 'wheel_physical_health_title',
        width: 1,
        color: theme.color.wheelBg,
        fillColor: theme.color.bodyWheel,
        icon: 'il_body_wheel_cropped',
        customs: {
            desktopX: '-52',
            desktopY: '-30',
            desktopMeasures: '105',
            mobileX: '-33',
            mobileY: '-19',
            mobileMeasues: '66',
        },
    },
];
