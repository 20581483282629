import { all } from 'redux-saga/effects';
import * as coach from './messages/coachSaga';
import achievements from './achievements/saga';
import agreements from './agreements/saga';
import activities from './activities/saga';
import articles from './articles/saga';
import auth from './auth/saga';
import branding from './branding/saga';
import challenges from './challenges/saga';
import challengeParticipants from './challengeParticipants/saga';
import config from './config/saga';
import entities from './entities/saga';
import financialscores from './financialscores/saga';
import friends from './friends/saga';
import global from './global/saga';
import goals from './goals/saga';
import healthscore from './healthscore/saga';
import messages from './messages/saga';
import posts from './posts/saga';
import programs from './programs/saga';
import search from './search/saga';
import settings from './settings/saga';
import stagings from './stagings/saga';
import stipulations from './stipulations/saga';
import summaries from './summaries/saga';
import tags from './tags/saga';
import uris from './uris/saga';
import user from './user/saga';
import trackers from './trackers/saga';
import surveys from './surveys/saga';

export default function* Saga() {
    yield all([
        achievements(),
        agreements(),
        activities(),
        articles(),
        auth(),
        branding(),
        challenges(),
        challengeParticipants(),
        config(),
        entities(),
        financialscores(),
        friends(),
        global(),
        goals(),
        healthscore(),
        messages(),
        posts(),
        programs(),
        search(),
        settings(),
        stagings(),
        stipulations(),
        summaries(),
        tags(),
        uris(),
        user(),
        trackers(),
        surveys(),
        coach.loadLastMessage(),
        coach.loadMessages(),
        coach.answerMessage(),
        coach.completeMessage(),
        coach.main(),
    ]);
}
