import 'regenerator-runtime/runtime';

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import sagas from '@services/sagas';
import reducers from './rootReducer';

export const history = createBrowserHistory({ basename: '/' });
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware(sagas);

const initialState = {};

const middleware = [sagaMiddleware, appRouterMiddleware];
const composeEnhancers =
    (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const makeStore = (history, state = initialState) =>
    createStore(reducers(history), state, composeEnhancers(applyMiddleware(...middleware)));

const store = makeStore(history);

export const { dispatch } = store;

sagaMiddleware.run(sagas);

export default store;
