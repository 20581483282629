const DEFAULT_SPACING = 8;

const spacing = {
    xxs: DEFAULT_SPACING / 2,
    xs: DEFAULT_SPACING,
    sm: DEFAULT_SPACING * 2,
    md: DEFAULT_SPACING * 3,
    lg: DEFAULT_SPACING * 4,
    xl: DEFAULT_SPACING * 5,
};

const fontSize = {
    main: '14px',
    label: '12px',
    label2: '16px',
    input: '16px',
    title: '32px',
    title2: '46px',
};

const iconSize = {
    xs: 8,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40,
};

export const sizes = {
    lg: 1200,
    md: 992,
    sm: 768,
    xs: 576,
    xxs: 450,
};

export const sizeVars = {
    spacing,
    sizes,
    fontSize,
    iconSize,
};
