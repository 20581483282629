import { handleActions } from 'redux-actions';
import * as types from './actionTypes';

export const initialState = {
    details: null,
    medias: [],
};

export default handleActions(
    {
        [types.CREATE_START_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.CREATE_SAVE_ANSWER_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.SILENT_SAVE_ANSWER_SURVEY_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload,
        }),
        [types.INIT_SURVEY_QUESTION_MEDIA]: state => ({
            ...state,
            medias: [],
        }),
        [types.LOAD_SURVEY_QUESTION_MEDIA_SUCCESS]: (state, action) => ({
            ...state,
            medias: action.payload,
        }),
        [types.RESET_SURVEY]: () => initialState,
    },
    initialState,
);
