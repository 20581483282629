import { createAction } from '@utils/redux';
import * as types from './actionTypes';

export const loginRequest = createAction(types.LOAD_LOGIN_REQUEST);
export const loginSuccess = createAction(types.LOAD_LOGIN_SUCCESS);
export const loginFailure = createAction(types.LOAD_LOGIN_FAILURE);

export const loginSSORequest = createAction(types.LOAD_LOGIN_SSO_REQUEST);
export const checkSSORequest = createAction(types.CHECK_LOGIN_SSO_REQUEST);

export const checkCodeRequest = createAction(types.LOAD_CODE_REQUEST);
export const checkCodeSuccess = createAction(types.LOAD_CODE_SUCCESS);
export const checkCodeFailure = createAction(types.LOAD_CODE_FAILURE);

export const checkCouponCodeRequest = createAction(types.LOAD_COUPON_CODE_REQUEST);
export const checkCouponCodeSuccess = createAction(types.LOAD_COUPON_CODE_SUCCESS);
export const checkCouponCodeFailure = createAction(types.LOAD_COUPON_CODE_FAILURE);

export const resetPasswordRequest = createAction(types.LOAD_RESET_PASSWORD_REQUEST);
export const resetPasswordSuccess = createAction(types.LOAD_RESET_PASSWORD_SUCCESS);
export const resetPasswordFailure = createAction(types.LOAD_RESET_PASSWORD_FAILURE);

export const clearAccessCode = createAction(types.CLEAR_ACCESS_CODE);
export const clearError = createAction(types.CLEAR_ERROR_FIELD);

export const logOut = createAction(types.LOGOUT);
export const logOutSuccess = createAction(types.LOGOUT_SUCCESS);

export const updatePasswordRequest = createAction(types.UPDATE_PASSWORD_REQUEST);
export const updatePasswordSuccess = createAction(types.UPDATE_PASSWORD_SUCCESS);
export const updatePasswordFailure = createAction(types.UPDATE_PASSWORD_FAILURE);

export const update2FAPhoneNumberRequest = createAction(types.UPDATE_2FA_PHONENUMBER_REQUEST);
export const update2FAPhoneNumberSuccess = createAction(types.UPDATE_2FA_PHONENUMBER_SUCCESS);
export const update2FAPhoneNumberFailure = createAction(types.UPDATE_2FA_PHONENUMBER_FAILURE);

export const enable2FACodeResend = createAction(types.ENABLE_2FA_CODE_RESEND);
export const disable2FACodeResend = createAction(types.DISABLE_2FA_CODE_RESEND);

export const resend2FACodeRequest = createAction(types.LOAD_2FA_CODE_REQUEST);
export const resend2FACodeSuccess = createAction(types.LOAD_2FA_CODE_SUCCESS);
export const resend2FACodeFailure = createAction(types.LOAD_2FA_CODE_FAILURE);

export const submit2FACodeRequest = createAction(types.UPDATE_2FA_CODE_REQUEST);
export const submit2FACodeSuccess = createAction(types.UPDATE_2FA_CODE_SUCCESS);
export const submit2FACodeFailure = createAction(types.UPDATE_2FA_CODE_FAILURE);

export const load2FACodeStatusRequest = createAction(types.LOAD_2FA_CODE_STATUS_REQUEST);
export const load2FACodeStatusSuccess = createAction(types.LOAD_2FA_CODE_STATUS_SUCCESS);
export const load2FACodeStatusFailure = createAction(types.LOAD_2FA_CODE_STATUS_FAILURE);

export const loadClientLoginRequest = createAction(types.LOAD_CLIENT_LOGIN_REQUEST);
export const loadClientLoginSuccess = createAction(types.LOAD_CLIENT_LOGIN_SUCCESS);
export const loadClientLoginFailure = createAction(types.LOAD_CLIENT_LOGIN_FAILURE);
