import { createAction } from '@utils/redux';
import * as types from './actionTypes';

export const loadGoalsRequest = createAction(types.LOAD_GOALS_REQUEST);
export const loadGoalsSuccess = createAction(types.LOAD_GOALS_SUCCESS);
export const loadGoalsFailure = createAction(types.LOAD_GOALS_FAILURE);

export const loadOldGoalsRequest = createAction(types.LOAD_OLD_GOALS_REQUEST);
export const loadOldGoalsSuccess = createAction(types.LOAD_OLD_GOALS_SUCCESS);
export const loadOldGoalsFailure = createAction(types.LOAD_OLD_GOALS_FAILURE);
