export const LOAD_SEARCH_ITEMS_REQUEST = 'LOAD_SEARCH_ITEMS_REQUEST';
export const LOAD_SEARCH_ITEMS_SUCCESS = 'LOAD_SEARCH_ITEMS_SUCCESS';
export const LOAD_SEARCH_ITEMS_FAILURE = 'LOAD_SEARCH_ITEMS_FAILURE';

export const CLEAR_SEARCH_ITEMS_REQUEST = 'CLEAR_SEARCH_ITEMS_REQUEST';
export const CLEAR_SEARCH_ITEMS_SUCCESS = 'CLEAR_SEARCH_ITEMS_SUCCESS';
export const CLEAR_SEARCH_ITEMS_FAILURE = 'CLEAR_SEARCH_ITEMS_FAILURE';

export const LOAD_CHECK_EMAIL_REQUEST = 'LOAD_CHECK_EMAIL_REQUEST';
export const LOAD_CHECK_EMAIL_SUCCESS = 'LOAD_CHECK_EMAIL_SUCCESS';
export const LOAD_CHECK_EMAIL_FAILURE = 'LOAD_CHECK_EMAIL_FAILURE';

export const STORE_SEARCH_VALUE = 'STORE_SEARCH_VALUE';
