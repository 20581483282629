import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { clientRoutes } from '@constants';
import { configSelector } from '@services/auth/selectors';
import { selectLanguage } from '@services/language/selectors';

const DEEP_LINK_PATH = '/resources/me';
const FALLBACK_TO_STORE = 'fallbackToStore';
const RESTRICTED_ROUTES = [
    clientRoutes.root(),
    clientRoutes.login(),
    clientRoutes.signup(),
    clientRoutes.me(),
    DEEP_LINK_PATH,
];

const useSteadyRedirect = () => {
    const { push, replace } = useHistory();
    const { search, pathname } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const config = useSelector(configSelector);
    const locale = useSelector(selectLanguage);

    const { isEnabled, webRedirectUrl } = config.features?.steadyRedirect || {};
    const APPLE_STORE_APP = config.brand?.appleId;
    const GOOGLE_PLAY_APP = config.brand?.googleId;
    const { webAppUrl } = config;
    const appDeepLink = useMemo(() => `${webAppUrl}${DEEP_LINK_PATH}`, [webAppUrl]);

    const GOOGLE_URL = useMemo(() => `https://play.google.com/store/apps/details?id=${GOOGLE_PLAY_APP}&hl=${locale}`, [
        GOOGLE_PLAY_APP,
        locale,
    ]);
    const APPLE_URL = useMemo(() => `https://itunes.apple.com/app/id${APPLE_STORE_APP}?l=${locale}`, [
        APPLE_STORE_APP,
        locale,
    ]);

    const openApp = useCallback(() => {
        window.location.replace(`${appDeepLink}?${FALLBACK_TO_STORE}=true`);
    }, [appDeepLink]);

    const openStore = useCallback(() => {
        push(clientRoutes.redirect());

        if (navigator.userAgent.match(/Android/i)) {
            window.location.replace(GOOGLE_URL);
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.replace(APPLE_URL);
        } else {
            window.location.replace(webRedirectUrl);
        }
    }, [GOOGLE_URL, APPLE_URL]);

    useEffect(() => {
        if (!isEnabled || !RESTRICTED_ROUTES.includes(pathname) || !webRedirectUrl) {
            return;
        }

        if (query.get(FALLBACK_TO_STORE)) {
            replace({ pathname, search: '' });
            openStore();
            return;
        }

        openApp();
    }, [isEnabled, query, openStore, pathname, openApp, webRedirectUrl]);
};

export default useSteadyRedirect;
