import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Radio, { Card, Label } from '@components/ui/Form/Radio';
import { useField } from 'formik';

const RadioSet = ({ variants, intl, label, ...props }) => {
    const [field] = useField(props);
    const { name, onChange, value } = field;
    const radioSetId = `${name}-set`;
    return (
        <>
            {label && <label htmlFor={radioSetId}>{label}</label>}
            <Card id="radioSetId">
                {variants.map(variant => (
                    <Fragment key={variant.value}>
                        <Radio
                            id={variant.value + name}
                            name={name}
                            variant={variant.value}
                            value={value}
                            onChange={onChange}
                        />
                        <Label htmlFor={variant.value + name}>
                            {intl.formatMessage({
                                id: variant.label,
                                defaultMessage: variant.label,
                            })}
                        </Label>
                    </Fragment>
                ))}
            </Card>
        </>
    );
};

RadioSet.propTypes = {
    name: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
};

export default injectIntl(RadioSet);
