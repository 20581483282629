import { Input } from '@components/ui';
import FindLocation from '@containers/Location/FindLocation';
import CustomSelect, { noneOption } from '@components/ui/Form/Select/Custom';
import CountySelect from '@components/ui/Form/Select/Custom/CountySelect';
import React from 'react';
import { fieldValidation } from 'src/validation/userProfile';
import CountrySelect from '@components/ui/Form/Select/Custom/CountrySelect';

export const MAX_AGE = 86;
export const MIN_AGE = 17;

export const privacyOptions = [
    { label: 'GROUP', value: 'group' },
    { label: 'FRIENDS', value: 'user' },
    { label: 'PUBLIC', value: 'public' },
    noneOption,
];
export const unitsOptions = [
    { label: 'METRIC', value: 'metric' },
    { label: 'IMPERIAL', value: 'imperial' },
];
export const bloodOptions = [
    { label: 'unit_short_name_mgdl', value: 'mg/dl' },
    { label: 'unit_short_name_mmol_l', value: 'mmol/l' },
];

export const genderOptions = [
    {
        value: 'male',
        label: 'MALE',
    },
    {
        value: 'female',
        label: 'FEMALE',
    },
];

export const weightOptions = [
    {
        value: 'metric',
        label: 'unit_short_name_kilogram',
        max: 175.5,
        min: 28,
        factor: 0.45359237,
        type: 'unitOfMeasure',
    },
    {
        value: 'imperial',
        label: 'unit_short_name_pound',
        max: 386,
        min: 62,
        factor: 0.45359237,
        type: 'unitOfMeasure',
    },
];

export const heightOptions = [
    {
        value: 'metric',
        label: 'unit_short_name_centimeter',
        max: 279.5,
        min: 59.5,
        factor: 2.54,
        type: 'unitOfMeasure',
    },
    {
        value: 'imperial',
        label: 'UNIT_FT_IN',
        max: 110,
        min: 28,
        factor: 2.54,
        type: 'unitOfMeasure',
    },
];

export const userRequiredFields = [
    {
        name: 'name.firstName',
        label: 'FIRST_NAME',
        required: true,
        defaultValue: '',
        component: Input,
        validation: fieldValidation.firstName,
    },
    {
        name: 'name.lastName',
        label: 'LAST_NAME',
        required: true,
        defaultValue: '',
        component: Input,
        validation: fieldValidation.lastName,
    },
    {
        name: 'body.dateOfBirth',
        label: 'DATE_OF_BIRTH',
        required: true,
        defaultValue: '',
        hint: 'DATE_FORMAT',
        component: Input,
        validation: fieldValidation.dateOfBirth,
    },
    {
        name: 'body.height',
        label: 'body_value_height_name',
        required: true,
        type: 'number',
        defaultValue: '',
        endSelect: true,
        options: heightOptions,
        component: Input,
        validation: fieldValidation.height,
    },
    {
        name: 'weight.mass',
        label: 'body_value_weight_name',
        required: true,
        type: 'number',
        defaultValue: '',
        endSelect: true,
        options: weightOptions,
        component: Input,
        validation: fieldValidation.mass,
    },
    {
        name: 'body.sex',
        label: 'GENDER',
        type: 'select',
        options: genderOptions,
        required: true,
        component: CustomSelect,
        inputStyle: true,
        validation: fieldValidation.gender,
    },
    {
        name: 'custom.country',
        label: 'COUNTRY',
        required: true,
        defaultValue: '',
        component: CountrySelect,
        skipTranslate: true,
        inputStyle: true,
        isSearchable: true,
        validation: fieldValidation.country,
    },
    {
        name: 'custom.city',
        label: 'CITY',
        required: true,
        defaultValue: '',
        component: Input,
        validation: fieldValidation.city,
        button: (values, setFieldValue) => <FindLocation {...values} setFieldValue={setFieldValue} />,
    },
];

export const countyField = {
    name: 'custom.county',
    label: 'COUNTY',
    required: true,
    defaultValue: '',
    component: CountySelect,
    skipTranslate: true,
    inputStyle: true,
    isSearchable: true,
    validation: fieldValidation.county,
};

export const biometricFields = {
    tsc: {
        key: 'tsc',
        title: 'blood_value_total_cholesterol_name',
        collection: 'blood',
        unit: 'unit_short_name_mmol_l',
        min: 1.5,
        max: 18.0,
    },
    hdl: {
        key: 'hdl',
        title: 'biometric_HDL',
        collection: 'blood',
        unit: 'unit_short_name_mmol_l',
        min: 0.2,
        max: 5.1,
    },
    tgl: {
        key: 'tgl',
        title: 'biometric_triglyceride',
        collection: 'blood',
        unit: 'unit_short_name_mmol_l',
        min: 0.2,
        max: 40.5,
    },
    ldl: {
        key: 'ldl',
        title: 'biometric_LDL',
        collection: 'blood',
        unit: 'unit_short_name_mmol_l',
        min: 0.5,
        max: 10.0,
    },
    fbg: {
        key: 'fbg',
        title: 'biometric_fasting_glucose',
        collection: 'blood',
        unit: 'unit_short_name_mmol_l',
        min: 4,
        max: 10,
    },
    cbg: {
        key: 'cbg',
        title: 'biometric_continous_glucose',
        collection: 'blood',
        unit: 'unit_short_name_mmol_l',
        min: 2.8,
        max: 13.9,
    },
    systolic: {
        key: 'systolic',
        title: 'biometric_systolic_blood_pressure',
        collection: 'heart',
        unit: 'unit_short_name_pressure',
        min: 80,
        max: 210,
    },
    diastolic: {
        key: 'diastolic',
        title: 'biometric_diastolic_blood_pressure',
        collection: 'heart',
        unit: 'unit_short_name_pressure',
    },
    resting: {
        key: 'resting',
        title: 'biometric_resting_heart_rate',
        collection: 'heart',
        unit: 'unit_short_name_heart_rate',
    },
    maximum: {
        key: 'maximum',
        title: 'biometric_maximum_heart_rate',
        collection: 'heart',
        unit: 'unit_short_name_heart_rate',
    },
    height: {
        key: 'height',
        title: 'body_value_height_name',
        unit: 'unit_short_name_meter',
        collection: 'body',
    },
    mass: {
        key: 'mass',
        title: 'body_value_weight_name',
        values: [],
        unit: 'unit_short_name_kilogram',
        collection: 'body',
    },
    fatMass: {
        key: 'fatMass',
        title: 'body_value_fat_name',
        values: [],
        unit: 'unit_short_name_kilogram',
        collection: 'body',
        required: ['mass'],
    },
    waist: {
        key: 'waist',
        title: 'body_value_waist_circumference_name',
        values: [],
        unit: 'unit_short_name_meter',
        collection: 'body',
    },
    bmi: {
        key: 'bmi',
        title: 'BMI',
        collection: 'body',
    },
};

export const sharingInitialValues = {
    public: false,
    group: false,
    user: false,
};

export const EMPLOYEE_FIELDS_TO_DISABLE = ['name.lastName'];
