import { getItems } from 'src/common/api/basic';
import routes from '@constants/apiRoutes';

export function getGoals(params) {
    return getItems(routes.goals, { ...params });
}

export function getCurrentGoals(params) {
    return getItems(routes.goalsMessages, { ...params, subCategories: 'goal', completed: false, withMedia: true });
}
