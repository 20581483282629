export const getPasswordStrength = password => {
    if (!password) {
        return 0;
    }

    const rules = [/[A-Z]/g, /[a-z]/g, /[0-9]/g, /[ !@#$%^&*)(+=._-]/g];
    const { length } = password;
    const minLength = 8;
    const step = 1;
    const rulesPoints = rules.reduce((acc, exp) => {
        const match = password.match(new RegExp(exp));
        if (!match) return acc;
        const matchPoints = match ? step : 0;

        const hasDifferentLetters = match.filter(l => l !== match[0]).length;
        const differentPoints = hasDifferentLetters ? step : 0;

        return acc + matchPoints + differentPoints;
    }, 0);

    const lengthPoints = length >= minLength ? step * 2 : 0;
    return rulesPoints + lengthPoints;
};

export const PASSWORD_POLICY_MAXLENGTH = 'PASSWORD_POLICY_MAXLENGTH';
export const PASSWORD_POLICY_MINLENGTH = 'PASSWORD_POLICY_MINLENGTH';
export const PASSWORD_POLICY_MINLOWER = 'PASSWORD_POLICY_MINLOWER';
export const PASSWORD_POLICY_MINNUMERICAL = 'PASSWORD_POLICY_MINNUMERICAL';
export const PASSWORD_POLICY_MINSPECIAL = 'PASSWORD_POLICY_MINSPECIAL';
export const PASSWORD_POLICY_MINUPPER = 'PASSWORD_POLICY_MINUPPER';

export const SUPPORTED_POLICIES = [
    PASSWORD_POLICY_MINLENGTH,
    PASSWORD_POLICY_MAXLENGTH,
    PASSWORD_POLICY_MINLOWER,
    PASSWORD_POLICY_MINUPPER,
    PASSWORD_POLICY_MINNUMERICAL,
    PASSWORD_POLICY_MINSPECIAL,
];

const evaluateCondition = (conditionId, conditionValue, value = '') => {
    const absConditionValue = Math.abs(conditionValue);
    switch (conditionId) {
        case 'PASSWORD_POLICY_MAXLENGTH':
            return value.length <= absConditionValue;
        case 'PASSWORD_POLICY_MINLENGTH':
            return value.length >= absConditionValue;
        case 'PASSWORD_POLICY_MINLOWER':
            return (value.match(/[a-z]/g) || []).length >= absConditionValue;
        case 'PASSWORD_POLICY_MINUPPER':
            return (value.match(/[A-Z]/g) || []).length >= absConditionValue;
        case 'PASSWORD_POLICY_MINNUMERICAL':
            return (value.match(/[0-9]/g) || []).length >= absConditionValue;
        case 'PASSWORD_POLICY_MINSPECIAL':
            return (value.match(/[^a-zA-Z0-9]/g) || []).length >= absConditionValue;
        default:
            return false;
    }
};

export const validatePassword = (password, policy = {}) => {
    const primaryConditions = Object.keys(policy)
        .filter(key => SUPPORTED_POLICIES.includes(key))
        .filter(key => policy[key] > 0)
        .reduce((result, key) => ({ ...result, [key]: policy[key] }), {});

    const secondaryConditions = Object.keys(policy)
        .filter(key => SUPPORTED_POLICIES.includes(key))
        .filter(key => policy[key] < 0)
        .reduce((result, key) => ({ ...result, [key]: policy[key] }), {});

    const evaluatedSecondaryConditions = Object.entries(secondaryConditions).map(field =>
        evaluateCondition(...field, password),
    );

    const secondaryResult =
        !evaluatedSecondaryConditions.length || evaluatedSecondaryConditions.some(condition => condition === true);

    return [
        ...Object.entries(primaryConditions).map(field => evaluateCondition(...field, password)),
        secondaryResult,
    ].every(condition => condition === true);
};
